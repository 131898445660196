export const formatFlightClass = (flightClass: string): string => {
  switch (flightClass) {
    case "economy":
      return "E";
    case "premium economy":
      return "PE";
    case "business":
      return "B";
    case "first class":
      return "F";
    default:
      return "";
  }
};

export enum refundStatus {
  notApplicable = 0,
  pending = 1,
  processing = 2,
  inProgress = 3,
  refundInitiated = 4,
  refundFailed = 5,
  partiallyRefunded = 6,
  cancelPenaltiesCompleted = 7,
}

export const valuesAndMeanings = [
  { code: "BO0", text: "Booking Onward Success" },
  { code: "BO1", text: "Booking Onward Failed" },
  { code: "BO0,BR0", text: "Booking Success" },
  { code: "B01,BR1", text: "Booking Failed" },
  { code: "BO1,BR0", text: "Booking Onward Failed, Booking Return Success" },
  { code: "BO0,BR1", text: "Booking Onward Success, Booking Return Failed" },
  { code: "TO0", text: "Ticketing Onward Success" },
  { code: "TO1", text: "Ticketing Onward Failed" },
  {
    code: "TO0,TR0",
    text: "Ticketing Success",
  },
  { code: "TO1,TR1", text: "Ticketing Failed" },
  {
    code: "TO1,TR0",
    text: "Ticketing Onward Failed, Ticketing Return Success",
  },
  {
    code: "TO0,TR1",
    text: "Ticketing Onward Success, Ticketing Return Failed",
  },
  {
    code: "HO0,HR0",
    text: "Hold Booking Success",
  },
  {
    code: "HO1,HR1",
    text: "Hold Booking Failed",
  },
  {
    code: "HO1,HR0",
    text: "Hold Booking Onward Failed, Hold Booking Return Success",
  },
  {
    code: "HO0,HR1",
    text: "Hold Booking Onward Success, Hold Booking Return Failed",
  },
  {
    code: "CR",
    text: "Canceled",
  },
];

// formats the currency and amount displayed in fare Rules (flight list -> flight details -> fare rules)
export const formatAmountAndCurrency = (
  amount: string,
  currencyCode?: string
): string => {
  const numericAmount = parseFloat(amount.trim()); // Trim any spaces and attempt to convert to number

  // Check if the amount is a valid number
  if (isNaN(numericAmount)) {
    return amount; // If not a number, return the original string (e.g., "Non-refundable")
  }

  if (!currencyCode) {
    return numericAmount.toLocaleString(); // No currency symbol if no code is provided
  }

  return numericAmount.toLocaleString("en-IN", {
    style: "currency",
    currency: currencyCode, // Use the provided currency code
  });
};

// trimming special flights and keep the normal flight code for users to be visible (S6E -> 6E)
export const processMAC = (mac: string): string => {
  return mac?.length > 2 ? mac.slice(1) : mac;
};

// filtering ssrDetails to keep only fields FUID, SSID, PaxID, flatten the nested objects
export const filterSSRDetails = (ssrDetails: any[]) => {
  const filteredDetails: any[] = [];

  ssrDetails.forEach((detail) => {
    // Loop through each nested object ("0", "1", etc.) inside the outer object
    Object.keys(detail).forEach((key) => {
      const nestedObject = detail[key]; // Access the inner object
      if (nestedObject) {
        // Extract only the necessary fields
        const { FUID, SSID, PaxID } = nestedObject;

        // Store only the fields we need, if they are present
        if (FUID && SSID && PaxID) {
          filteredDetails.push({ FUID, SSID, PaxID });
        }
      }
    });
  });

  return filteredDetails;
};

export const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timer: NodeJS.Timeout; // Store the timer ID here

  return (...args: Parameters<T>) => {
    clearTimeout(timer); // Clear the previous timeout
    timer = setTimeout(() => fn(...args), delay); // Set a new timeout
  };
};
