import { Fragment, Key, useState } from "react";
import eyeVectorImage from "../../../assets/images/Vector.png";
import moment from "moment";
import {
  getFlightBaggage,
  getFlightFare,
  getFlightInfo,
} from "../../../api/FlightAPI";
import LazyImage from "../../../pages/LazyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { formatAmountAndCurrency, processMAC } from "../../../utils/helpers";
import BaggageTable from "./flight-list/BaggageTable";
import { Chip } from "@mui/material";
import LuggageIcon from "@mui/icons-material/Luggage";
import Notes from "./Notes";
import DoneIcon from "@mui/icons-material/Done";
import { SelectNowButton, StyledChip } from "./flight-list/Buttons";

interface CheapestFlightsListProps {
  flightList: any;
  removeFilters: any;
  handleSelect: any;
  slabsListData: any;
  tag?: string;
}

export const RoundTripFlightsList = ({
  flightList,
  removeFilters,
  handleSelect,
  slabsListData,
  tag,
}: CheapestFlightsListProps) => {
  const [storeSegments, setStoreSegments] = useState<any>([]);
  const [storeReturnSegments, setReturnStoreSegments] = useState<any>([]);
  const [toggleOverViewFlight, setToggleOverViewFlight] = useState("onward");
  const [storeFareDetails, setStoreFareDetails] = useState<any>([]);
  const [markedPrice, setMarkedPrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [storedAddonPercent, setStoredAddonPercent] = useState(0);
  const [baggageDetails, setBaggageDetails] = useState<any>([]);
  const [baggageDetailsReturn, setBaggageDetailsReturn] = useState<any>([]);

  const [storeNotices, setStoreNotices] = useState<any>();
  const [baseFareDetails, setBaseFareDetails] = useState<any>({
    ptcObj: "",
    baseFare: "",
    tax: "",
    ptcFare: "",
    discount: "",
    netAmount: "",
  });
  const [openFlightIndex, setOpenFlightIndex] = useState(null);
  const [toggleFare, setToggleFare] = useState("");

  const [toggleTabs, setToggleTabs] = useState("overview");

  const flightSearchDetailsJSON = localStorage.getItem("userFlightSearchData");
  const flightSearchDetails = JSON.parse(String(flightSearchDetailsJSON));

  const generateConnectionMessage = (
    flightConnection: any[] | null | undefined
  ) => {
    if (flightList.length !== 0) {
      let connectionsMessage = "";
      if (flightConnection && flightConnection?.length === 1) {
        connectionsMessage = `1 stop via ${
          flightConnection[0]?.ArrAirportName.split("|")[1]
        }`;
      } else if (flightConnection && flightConnection.length > 1) {
        const connectionNames = flightConnection?.map(
          (connection) => connection?.ArrAirportName?.split("|")[1]
        );
        connectionsMessage = `${
          flightConnection?.length
        } stops via ${connectionNames.join(", ")}`;
      }

      return connectionsMessage;
    }
  };

  const triggerFLightInfo = async (flights: any, tripType: string) => {
    const flightDetailsArray = [];

    flightDetailsArray.push({
      TUI: flights.OnwardFlight.TUI,
      Amount: flights.OnwardFlight.NetFare,
      OrderID: 1,
      ChannelCode: null,
      Index: flights.OnwardFlight.Index,
    });

    if (flights.ReturnFlight) {
      flightDetailsArray.push({
        TUI: flights.ReturnFlight.TUI,
        Amount: flights.ReturnFlight.NetFare,
        OrderID: 2,
        ChannelCode: null,
        Index: flights.ReturnFlight.Index,
      });
    }

    await getFlightInfo({
      Trips: flightDetailsArray,
      TripType: tripType,
      ClientID: "API19201",
    })
      .then((response) => {
        if (response?.data.Trips !== null) {
          setStoreNotices(response?.data.Trips[0]?.Journey[0]?.Notices);
          setReturnStoreSegments([
            response?.data.Trips[1]?.Journey[0]?.Segments,
          ]);
          setStoreSegments([response?.data.Trips[0]?.Journey[0]?.Segments]);

          const ptcObj1: any =
            response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares.PTCFare;
          const ptcObj2: any =
            response?.data.Trips[1]?.Journey[0]?.Segments[0].Fares.PTCFare;

          const addNumbers = (a: any, b: any) => {
            a = parseFloat(a) || 0;
            b = parseFloat(b) || 0;
            return a + b;
          };

          const combinedPTCObjects = [];

          for (let i = 0; i < ptcObj1.length; i++) {
            const mergedString = !ptcObj1[i]?.OT?.endsWith(",")
              ? ptcObj1[i]?.OT + "," + ptcObj2[i]?.OT
              : ptcObj1[i]?.OT + ptcObj2[i]?.OT;

            const combinedPTCObject = {
              PTC: ptcObj1[i].PTC,
              API: addNumbers(ptcObj1[i].API, ptcObj2[i].API),
              ATOAddonMarkup: addNumbers(
                ptcObj1[i].ATOAddonMarkup,
                ptcObj2[i].ATOAddonMarkup
              ),
              AddonDiscount: addNumbers(
                ptcObj1[i].AddonDiscount,
                ptcObj2[i].AddonDiscount
              ),
              AddonMarkup: addNumbers(
                ptcObj1[i].AddonMarkup,
                ptcObj2[i].AddonMarkup
              ),
              AgentMarkUp: addNumbers(
                ptcObj1[i].AgentMarkUp,
                ptcObj2[i].AgentMarkUp
              ),
              Ammendment: addNumbers(
                ptcObj1[i].Ammendment,
                ptcObj2[i].Ammendment
              ),
              AtoCharge: addNumbers(ptcObj1[i].AtoCharge, ptcObj2[i].AtoCharge),
              CUTE: addNumbers(ptcObj1[i].CUTE, ptcObj2[i].CUTE),
              GrossFare: addNumbers(ptcObj1[i].GrossFare, ptcObj2[i].GrossFare),
              Fare: addNumbers(ptcObj1[i].Fare, ptcObj2[i].Fare),
              OTT: "",
              YQ: addNumbers(ptcObj1[i].YQ, ptcObj2[i].YQ),
              PSF: addNumbers(ptcObj1[i].PSF, ptcObj2[i].PSF),
              YR: addNumbers(ptcObj1[i].YR, ptcObj2[i].YR),
              UD: addNumbers(ptcObj1[i].UD, ptcObj2[i].UD),
              K3: addNumbers(ptcObj1[i].K3, ptcObj2[i].K3),
              RCF: addNumbers(ptcObj1[i].RCF, ptcObj2[i].RCF),
              RCS: addNumbers(ptcObj1[i].RCS, ptcObj2[i].RCS),
              PHF: addNumbers(ptcObj1[i].PHF, ptcObj2[i].PHF),
              Tax: addNumbers(ptcObj1[i].Tax, ptcObj2[i].Tax),
              NetFare: addNumbers(ptcObj1[i].NetFare, ptcObj2[i].NetFare),
              ST: addNumbers(ptcObj1[i].ST, ptcObj2[i].ST),
              TransactionFee: addNumbers(
                ptcObj1[i].TransactionFee,
                ptcObj2[i].TransactionFee
              ),
              VATonServiceCharge: addNumbers(
                ptcObj1[i].VATonServiceCharge,
                ptcObj2[i].VATonServiceCharge
              ),
              VATonTransactionFee: addNumbers(
                ptcObj1[i].VATonTransactionFee,
                ptcObj2[i].VATonTransactionFee
              ),
              ReissueCharge: addNumbers(
                ptcObj1[i].ReissueCharge,
                ptcObj2[i].ReissueCharge
              ),
              OldSSRAmount: addNumbers(
                ptcObj1[i].OldSSRAmount,
                ptcObj2[i].OldSSRAmount
              ),
              OT: mergedString,
            };

            combinedPTCObjects.push(combinedPTCObject);
          }

          setBaseFareDetails({
            ...baseFareDetails,
            ptcObj: ptcObj1,
            baseFare:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                .TotalBaseFare +
              response?.data.Trips[1]?.Journey[0]?.Segments[0].Fares
                .TotalBaseFare,
            tax:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares.TotalTax +
              response?.data.Trips[1]?.Journey[0]?.Segments[0].Fares.TotalTax,
            ptcFare: combinedPTCObjects,
            discount:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                .TotalCommission +
              response?.data.Trips[1]?.Journey[0]?.Segments[0].Fares
                .TotalCommission,
            netAmount:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares.NetFare +
              response?.data.Trips[1]?.Journey[0]?.Segments[0].Fares.NetFare,
          });
        } else {
          setStoreSegments([]);
          setReturnStoreSegments([]);
          setBaseFareDetails({
            ptcObj: [],
            baseFare: 0,
            tax: 0,
            ptcFare: null,
            discount: 0,
            netAmount: 0,
          });
        }
      })
      .catch((error) => console.log(error));

    //fare
    await getFlightFare({
      Trips: flightDetailsArray,
      ClientID: "API19201",
      Mode: "AS",
      Options: "",
      Source: "CF",
      TripType: tripType,
    })
      .then((response) => {
        if (response?.data.Trips !== null) {
          setStoreFareDetails([response?.data.Trips]);
        } else {
          setStoreFareDetails([]);
        }
      })
      .catch((error) => console.log(error));

    await getFlightBaggage({
      PaidSSR: false,
      ClientID: "API19201",
      Source: "LV",
      Trips: flightDetailsArray,
    })
      .then((response) => {
        setBaggageDetails(response?.data?.Trips[0]);
        setBaggageDetailsReturn(response?.data?.Trips[1]);
      })
      .catch((error) => console.log(error));
  };

  function calculateTimeDifference(startTime: any, endTime: any) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const timeDifferenceInMilliseconds = Math.abs(
      endDate.getTime() - startDate.getTime()
    );
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(
      2,
      "0"
    )}min`;
  }

  const sortFlightsByFare = (flights: any) => {
    return flights?.slice().sort(
      (
        a: {
          OnwardFlight: { NetFare: number };
          ReturnFlight: { NetFare: number };
        },
        b: {
          OnwardFlight: { NetFare: number };
          ReturnFlight: { NetFare: number };
        }
      ) => {
        return (
          a.OnwardFlight.NetFare +
          a.ReturnFlight.NetFare -
          (b.OnwardFlight.NetFare + b.ReturnFlight.NetFare)
        );
      }
    );
  };

  const matchingFlights = flightList?.filter(
    (flight: { OnwardFlight: { Duration: string } }) => {
      return flight?.OnwardFlight.Duration;
    }
  );

  const matchingDepartureFlight = flightList
    ?.slice()
    .sort(
      (
        a: { OnwardFlight: { DepartureTime: string | number | Date } },
        b: { OnwardFlight: { DepartureTime: string | number | Date } }
      ) => {
        const departureTimeA: any = new Date(a.OnwardFlight.DepartureTime);
        const departureTimeB: any = new Date(b.OnwardFlight.DepartureTime);
        return departureTimeA - departureTimeB;
      }
    );

  const matchingArrivalFlight = flightList
    ?.slice()
    .sort(
      (
        a: { OnwardFlight: { ArrivalTime: string | number | Date } },
        b: { OnwardFlight: { ArrivalTime: string | number | Date } }
      ) => {
        const arrivalTimeA: any = new Date(a.OnwardFlight.ArrivalTime);
        const arrivalTimeB: any = new Date(b.OnwardFlight.ArrivalTime);
        return arrivalTimeA - arrivalTimeB;
      }
    );

  const sortedFlights =
    tag === "departure"
      ? matchingDepartureFlight
      : tag === "arrival"
      ? matchingArrivalFlight
      : sortFlightsByFare(matchingFlights);

  const toggleFlightDetails = (flightIndex: any) => {
    if (openFlightIndex === flightIndex) {
      setOpenFlightIndex(null);
    } else {
      setOpenFlightIndex(flightIndex);
    }
  };

  const ptcFares = baseFareDetails.ptcFare || [];

  const adultCount = flightSearchDetails.ADT || 0;
  const childCount = flightSearchDetails.CHD || 0;
  const infantCount = flightSearchDetails.INF || 0;

  // Initialize tax totals for each passenger type
  const adultTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const childTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };
  const infantTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  ptcFares?.forEach(
    (ptc: {
      PTC: any;
      OT: string;
      ST: string;
      YQ: string;
      YR: string;
      UD: string;
      API: string;
      PSF: string;
      K3: string;
      VATonServiceCharge: string;
    }) => {
      const ptcType = ptc.PTC;

      switch (ptcType) {
        case "ADT":
          const otValues =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",")?.map(
                  (value) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSum = otValues.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );

          adultTaxTotals.AirlineMisc +=
            (otSum + parseFloat(ptc.ST)) * adultCount;
          adultTaxTotals.FuelTax += parseFloat(ptc.YQ) * adultCount;
          adultTaxTotals.CongestionFee += parseFloat(ptc.YR) * adultCount;
          adultTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * adultCount;
          adultTaxTotals.APITax += parseFloat(ptc.API) * adultCount;
          adultTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * adultCount;
          adultTaxTotals.GST += parseFloat(ptc.K3) * adultCount;
          adultTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * adultCount;
          break;
        case "CHD":
          const otValuesChild =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",")?.map(
                  (value) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumChild = otValuesChild.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          childTaxTotals.AirlineMisc +=
            (otSumChild + parseFloat(ptc.ST)) * childCount;
          childTaxTotals.FuelTax += parseFloat(ptc.YQ) * childCount;
          childTaxTotals.CongestionFee += parseFloat(ptc.YR) * childCount;
          childTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * childCount;
          childTaxTotals.APITax += parseFloat(ptc.API) * childCount;
          childTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * childCount;
          childTaxTotals.GST += parseFloat(ptc.K3) * childCount;
          childTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * childCount;
          break;
        case "INF":
          const otValuesInfant =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",")?.map(
                  (value) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumInfant = otValuesInfant.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          infantTaxTotals.AirlineMisc +=
            (otSumInfant + parseFloat(ptc.ST)) * infantCount;
          infantTaxTotals.FuelTax += parseFloat(ptc.YQ) * infantCount;
          infantTaxTotals.CongestionFee += parseFloat(ptc.YR) * infantCount;
          infantTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * infantCount;
          infantTaxTotals.APITax += parseFloat(ptc.API) * infantCount;
          infantTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * infantCount;
          infantTaxTotals.GST += parseFloat(ptc.K3) * infantCount;
          infantTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * infantCount;
          break;
        default:
      }
    }
  );

  const taxTotals: any = {
    AirlineMisc: 0 + storedAddonPercent ? Math.round(storedAddonPercent) : 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  function calculateFare(tax: any, passengerCount: number) {
    const otValues =
      typeof tax?.OT === "string"
        ? tax?.OT?.split(",")?.map(
            (value: string) => parseFloat(value.trim()) || 0
          )
        : [];

    const otSum = otValues.reduce(
      (acc: number, currentValue: number) => acc + currentValue,
      0
    );

    return {
      AirlineMisc: Math.round(otSum + tax.ST) * passengerCount,
      FuelTax: tax.YQ * passengerCount || 0,
      CongestionFee: parseFloat(tax.YR) * passengerCount || 0,
      UserDevelopmentFee: parseFloat(tax.UD) * passengerCount || 0,
      APITax: parseFloat(tax.API) * passengerCount || 0,
      PassengerServiceTax: parseFloat(tax.PSF) * passengerCount || 0,
      GST: parseFloat(tax.K3) * passengerCount || 0,
      VATonServiceCharge:
        parseFloat(tax.VATonServiceCharge) * passengerCount || 0,
    };
  }

  ptcFares?.forEach((ptc: any) => {
    const ptcType = ptc.PTC;
    const passengerCount =
      ptcType === "ADT"
        ? adultCount
        : ptcType === "CHD"
        ? childCount
        : infantCount;

    const fareDetails: any = calculateFare(ptc, passengerCount);

    for (const key in taxTotals) {
      taxTotals[key] += fareDetails[key];
    }
  });

  const totalTaxAmount = Object.values(taxTotals).reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  const discountCalc = markedPrice - sellingPrice;

  const discountRate = markedPrice
    ? Math.round(discountCalc + baseFareDetails?.discount * 0.95)
    : Math.round(baseFareDetails?.discount * 0.95);

  const totalFareAmmount =
    baseFareDetails?.ptcFare &&
    baseFareDetails.baseFare + totalTaxAmount - discountRate;

  const flightSegments = storeSegments
    .flat()
    .map((d: { Flight: any }) => d.Flight);

  const removeDuplicateSSR = (ssrItems: any[]) => {
    if (ssrItems) {
      const uniqueItems = new Map<string, any>();

      ssrItems?.forEach((item) => {
        const uniqueKey = `${item.PTC}-${item.Code}`;
        if (!uniqueItems.has(uniqueKey)) {
          uniqueItems.set(uniqueKey, item);
        }
      });

      return Array.from(uniqueItems.values());
    }
  };

  const combinedBaggage =
    baggageDetails?.length !== 0 &&
    flightSegments.map((flight: any, index: number) => {
      if (index < baggageDetails?.Journey[0]?.Segments?.length) {
        return {
          ...flight,
          SSR: removeDuplicateSSR(
            baggageDetails?.Journey[0]?.Segments[index]?.SSR
          ),
        };
      }
      return flight;
    });

  const combinedBaggageReturn =
    baggageDetailsReturn?.length !== 0 &&
    flightSegments.map((flight: any, index: number) => {
      if (index < baggageDetailsReturn?.Journey[0]?.Segments?.length) {
        return {
          ...flight,
          SSR: removeDuplicateSSR(
            baggageDetailsReturn?.Journey[0]?.Segments[index]?.SSR
          ),
        };
      }
      return flight;
    });

  // Slab calculation for discount - start
  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  const updateFlight = (flight: {
    OnwardFlight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      DiscountAmount: any;
    };
    ReturnFlight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      DiscountAmount: any;
    };
  }) => {
    const netFare = flight.OnwardFlight.NetFare + flight.ReturnFlight.NetFare;
    const slab: any = findSlab(netFare);

    const markedPriceRate = netFare + netFare * (slab?.addonRate / 100);
    const sellingPriceRate = netFare + netFare * (slab?.commissionRate / 100);

    if (slab) {
      flight.OnwardFlight.CommissionRate = slab?.commissionRate;
      flight.OnwardFlight.AddonRate = slab?.addonRate;
      flight.OnwardFlight.MarkedPrice = Math.round(markedPriceRate);
      flight.OnwardFlight.SellingPrice = Math.round(sellingPriceRate + 1);

      flight.OnwardFlight.Discount = Math.round(
        ((flight.OnwardFlight.MarkedPrice - flight.OnwardFlight.SellingPrice) /
          flight.OnwardFlight.MarkedPrice) *
          100
      );
      flight.OnwardFlight.DiscountAmount = Math.round(
        flight.OnwardFlight.MarkedPrice - flight.OnwardFlight.SellingPrice
      );
    }

    return flight;
  };

  const updatedFlights = sortedFlights
    .map(
      (flight: {
        OnwardFlight: {
          NetFare: any;
          CommissionRate: any;
          AddonRate: any;
          SellingPrice: any;
          MarkedPrice: any;
          Discount: any;
          DiscountAmount: any;
        };
        ReturnFlight: {
          NetFare: any;
          CommissionRate: any;
          AddonRate: any;
          SellingPrice: any;
          MarkedPrice: any;
          Discount: any;
          DiscountAmount: any;
        };
      }) => {
        if (
          flight.OnwardFlight.NetFare !== 0 ||
          flight.ReturnFlight.NetFare !== 0
        ) {
          return updateFlight(flight);
        } else {
          return null;
        }
      }
    )
    .filter((flight: any) => flight !== null);

  const newCalculateTotalDuration = (segments: any[], currentIndex: number) => {
    let totalDuration = 0;
    let layoverDurations: string[] = [];

    if (Array.isArray(segments)) {
      segments.slice(0, currentIndex + 1).forEach(({ Flight }, index) => {
        if (Flight && index > 0) {
          const layover = moment(Flight.DepartureTime).diff(
            moment(segments[index - 1].Flight?.ArrivalTime)
          );
          totalDuration += layover;

          const individualDuration = TimecalculateTimeDifference(
            segments[index - 1].Flight?.ArrivalTime,
            Flight?.DepartureTime
          );
          if (index === currentIndex) {
            // Only push the duration for the current segment
            layoverDurations.push(individualDuration);
          }
        }
      });
    }

    console.log("Total Layover Duration in milliseconds: ", totalDuration);
    console.log("Individual Layover Durations: ", layoverDurations);
    let resultString = layoverDurations.join(" ");
    console.log("All Layover Durations: ", resultString);
    return resultString;
  };

  const TimecalculateTimeDifference = (
    startTime: string,
    endTime: string
  ): string => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const duration = moment.duration(endMoment.diff(startMoment));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    return `${hours}h ${minutes}m`;
  };

  console.log(storeFareDetails, "flight details");

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Notes />
      </div>

      {updatedFlights?.map((flight: any, index: number) => (
        <section
          className="cheapest_section"
          id="cheapest_section"
          key={flight.OnwardFlight.Index + index + "cheapest flight"}
        >
          {/* ONWARD FLIGHTS   */}
          {flight.OnwardFlight && (
            <div className="card card_section">
              {flight.OnwardFlight.flightType ? (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      border: "1px solid #ccc",
                      width: "fit-content ",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      backgroundColor: "#e77300",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    Return Special
                  </span>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      border: "1px solid #ccc",
                      width: "fit-content ",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      backgroundColor: "rgb(222 57 8)",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    Separate Flights
                  </span>
                </div>
              )}

              <span
                style={{
                  fontSize: "small",
                  paddingLeft: "12px",
                  color: "#a45050",
                }}
              >
                {flight.OnwardFlight.MAC === flight.OnwardFlight.OAC
                  ? ""
                  : `Operated By : ${
                      flight?.OnwardFlight.AirlineName?.split("|")[2]
                    }`}
              </span>

              <div className="card-body" style={{ paddingTop: "0px" }}>
                <div className="main_content res_containt">
                  <div className="block">
                    <div className="card-img">
                      {" "}
                      <LazyImage
                        className="img-fluid"
                        src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight.OnwardFlight.MAC}.jpg`}
                        alt="flight.OnwardFlight.MAC"
                      />{" "}
                    </div>
                    <div className="card-content">
                      <h3 className="h3-bold mb-0 res-font">
                        {flight.OnwardFlight.AirlineName.split("|")[1]}
                      </h3>
                      <p className="card-text ">
                        {processMAC(flight.OnwardFlight.MAC)} {"-"}{" "}
                        {flight.OnwardFlight.FlightNo}
                      </p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2">Departure</p>
                      <h3 className="h3-bold res-font">
                        {moment(flight.OnwardFlight.DepartureTime).format(
                          "HH:mm"
                        )}
                      </h3>
                      <span>
                        {" "}
                        {moment(flight.OnwardFlight.DepartureTime).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      <p
                        title={flight?.OnwardFlight.FromName?.split("|")[1]}
                        className="card-text text_overflow"
                        style={{ display: "grid" }}
                      >
                        {flight.OnwardFlight.FromName.split("|")[1]}
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          [{flight?.OnwardFlight.From}]
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="card-content content_body">
                    <p className="card-text mb-2">
                      {flight.OnwardFlight.Duration}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="left-image">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                          data-testid="originIcon"
                        >
                          <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                        </svg>
                      </div>
                      <div className="dot-arrow">
                        {flight.OnwardFlight.Connections &&
                          flight.OnwardFlight.Connections.length !== 0 && (
                            <span className="dot-span"> </span>
                          )}
                      </div>
                      <div className="left-image">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                          data-testid="destinationIcon"
                        >
                          <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                        </svg>
                      </div>
                    </div>
                    {flight.OnwardFlight.Connections &&
                    flight.OnwardFlight.Connections.length === 0 ? (
                      <>
                        <p className="card-text"> Non stop </p>
                        <p
                          className="card-text"
                          style={{ visibility: "hidden", height: "0px" }}
                        >
                          some some some te{" "}
                        </p>
                      </>
                    ) : (
                      <p className="card-text">
                        {" "}
                        {generateConnectionMessage(
                          flight.OnwardFlight.Connections
                        )}
                      </p>
                    )}
                  </div>

                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2">Arrival</p>
                      <h3 className="h3-bold res-font">
                        {" "}
                        {moment(flight.OnwardFlight.ArrivalTime).format(
                          "HH:mm"
                        )}
                      </h3>
                      <span>
                        {" "}
                        {moment(flight.OnwardFlight.ArrivalTime).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      <p
                        title={flight?.OnwardFlight.ToName?.split("|")[1]}
                        className="card-text text_overflow"
                        style={{ display: "grid" }}
                      >
                        {flight.OnwardFlight.ToName.split("|")[1]}
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          <div>[{flight?.OnwardFlight.To}]</div>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="block">
                    <div
                      className="card-content"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        style={{
                          marginBottom: "0.5rem",
                          color: "#ff7300",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {flight?.OnwardFlight?.FCType === "SME FARE" &&
                        flight?.ReturnFlight?.FCType === "SME FARE" ? (
                          "Corporate Fare"
                        ) : flight?.OnwardFlight?.FCType === "STUDENT FARE" &&
                          flight?.ReturnFlight?.FCType === "STUDENT FARE" ? (
                          "Student Fare"
                        ) : flight?.OnwardFlight?.FCType === "SENIOR CITIZEN" &&
                          flight?.ReturnFlight?.FCType === "SENIOR CITIZEN" ? (
                          "Sr. Citizen Fare"
                        ) : flight?.OnwardFlight?.FCType === "SUPER FARE" &&
                          flight?.ReturnFlight?.FCType === "SUPER FARE" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Free Addons ( Meal | Seat )
                          </div>
                        ) : null}
                      </div>

                      {flight?.OnwardFlight?.MarkedPrice && (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "orangered",
                            fontSize: "13px",
                            marginLeft: "14px",
                          }}
                        >
                          ₹{flight.OnwardFlight.MarkedPrice}
                        </span>
                      )}

                      {flight?.OnwardFlight?.SellingPrice ? (
                        <h3 className="h3-bold res-font">
                          ₹
                          {flight?.OnwardFlight.SellingPrice?.toLocaleString(
                            "en-IN"
                          )}
                        </h3>
                      ) : (
                        <h3 className="h3-bold res-font">
                          ₹
                          {(
                            flight?.OnwardFlight.NetFare +
                            flight?.ReturnFlight.NetFare
                          )?.toLocaleString("en-IN")}
                        </h3>
                      )}

                      {flight.OnwardFlight?.Discount > 0 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "white",
                            backgroundColor: "rgb(0 180 0)",
                            padding: "2px 5px",
                            borderRadius: "5px",
                          }}
                        >
                          ₹
                          {flight.OnwardFlight.MarkedPrice -
                            flight?.OnwardFlight.SellingPrice}{" "}
                          Savings
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer-section" style={{ padding: "0" }}>
                  {flight.OnwardFlight.Inclusions &&
                  flight.OnwardFlight.Inclusions !== null &&
                  flight.OnwardFlight.Inclusions.Baggage !== null ? (
                    <Chip
                      style={{
                        fontSize: "11px",
                        borderRadius: "6px",
                      }}
                      icon={
                        <LuggageIcon
                          fontSize="small"
                          color="secondary"
                          style={{ marginRight: "-10px", fontSize: "18px" }}
                        />
                      }
                      label={flight.OnwardFlight.Inclusions.Baggage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          )}

          {/* RETURN FLIGHTS */}
          {flight.ReturnFlight && (
            <div
              className="card card_section"
              style={{
                marginTop: "-25px",
                borderTop: "none",
                boxShadow: "none",
              }}
            >
              <span
                style={{
                  fontSize: "small",
                  paddingLeft: "12px",
                  color: "#a45050",
                }}
              >
                {flight.ReturnFlight.MAC === flight.ReturnFlight.OAC
                  ? ""
                  : `Operated By : ${
                      flight?.ReturnFlight.AirlineName?.split("|")[2]
                    }`}
              </span>
              <div className="card-body" style={{ paddingTop: "0px" }}>
                <div className="main_content res_containt">
                  <div className="block">
                    <div className="card-img">
                      {" "}
                      <LazyImage
                        className="img-fluid"
                        src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight.ReturnFlight.MAC}.jpg`}
                        alt="flight.ReturnFlight.MAC"
                      />{" "}
                    </div>
                    <div className="card-content">
                      <h3 className="h3-bold mb-0 res-font">
                        {flight.ReturnFlight.AirlineName.split("|")[1]}
                      </h3>
                      <p className="card-text ">
                        {processMAC(flight.ReturnFlight.MAC)} {"-"}{" "}
                        {flight.ReturnFlight.FlightNo}
                      </p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2">Departure</p>
                      <h3 className="h3-bold res-font">
                        {moment(flight.ReturnFlight.DepartureTime).format(
                          "HH:mm"
                        )}
                      </h3>
                      <span>
                        {" "}
                        {moment(flight.ReturnFlight.DepartureTime).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      <p
                        title={flight?.ReturnFlight.FromName?.split("|")[1]}
                        className="card-text text_overflow"
                        style={{ display: "grid" }}
                      >
                        {flight.ReturnFlight.FromName.split("|")[1]}
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          <div>[{flight?.ReturnFlight.From}]</div>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="card-content content_body">
                    <p className="card-text mb-2">
                      {flight.ReturnFlight.Duration}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="left-image">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                          data-testid="originIcon"
                        >
                          <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                        </svg>
                      </div>
                      <div className="dot-arrow">
                        {flight.ReturnFlight.Connections &&
                          flight.ReturnFlight.Connections.length !== 0 && (
                            <span className="dot-span"> </span>
                          )}
                      </div>
                      <div className="left-image">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                          data-testid="destinationIcon"
                        >
                          <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                        </svg>
                      </div>
                    </div>
                    {flight.ReturnFlight.Connections &&
                    flight.ReturnFlight.Connections.length === 0 ? (
                      <>
                        <p className="card-text"> Non stop </p>
                        <p
                          className="card-text"
                          style={{ visibility: "hidden", height: "0px" }}
                        >
                          some some some te{" "}
                        </p>
                      </>
                    ) : (
                      <p className="card-text">
                        {" "}
                        {generateConnectionMessage(
                          flight.ReturnFlight.Connections
                        )}
                      </p>
                    )}
                  </div>

                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2">Arrival</p>
                      <h3 className="h3-bold res-font">
                        {" "}
                        {moment(flight.ReturnFlight.ArrivalTime).format(
                          "HH:mm"
                        )}
                      </h3>
                      <span>
                        {" "}
                        {moment(flight.ReturnFlight.ArrivalTime).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      <p
                        title={flight?.ReturnFlight.ToName?.split("|")[1]}
                        className="card-text text_overflow"
                        style={{ display: "grid" }}
                      >
                        {flight.ReturnFlight.ToName.split("|")[1]}
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          <div>[{flight?.ReturnFlight.To}]</div>
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* Select now button -> redirect to checkout page  */}
                  {/* <SelectNowButton handleSelect={handleSelect(flight)} /> */}
                  <div className="block">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1.5rem",
                      }}
                    >
                      <div className="card-content">
                        <button
                          className="btn btn-select"
                          type="button"
                          onClick={() => {
                            handleSelect(flight);
                          }}
                        >
                          Select
                        </button>
                      </div>
                      <StyledChip label="No hidden fees" icon={<DoneIcon />} />
                    </div>
                  </div>
                </div>

                <div>
                  {flight.ReturnFlight.Inclusions &&
                  flight.ReturnFlight.Inclusions !== null &&
                  flight.ReturnFlight.Inclusions.Baggage !== null ? (
                    <Chip
                      style={{
                        fontSize: "11px",
                        borderRadius: "6px",
                        marginLeft: "4px",
                      }}
                      icon={
                        <LuggageIcon
                          fontSize="small"
                          color="secondary"
                          style={{ marginRight: "-10px", fontSize: "18px" }}
                        />
                      }
                      label={flight.ReturnFlight.Inclusions.Baggage}
                    />
                  ) : null}
                  <div className="card-footer-section">
                    {flight.OnwardFlight.Refundable === "Y" ? (
                      <span className="card-left"> Partially Refundable</span>
                    ) : flight.OnwardFlight.Refundable === "R" ? (
                      <span className="card-left">Refundable</span>
                    ) : (
                      <span className="card-left" style={{ color: "red" }}>
                        Non Refundable
                      </span>
                    )}

                    <span
                      onClick={() => {
                        toggleFlightDetails(
                          flight.ReturnFlight.Index + flight.OnwardFlight.Index
                        );
                        const tripType = flight.OnwardFlight.flightType
                          ? "RS"
                          : "RT";
                        triggerFLightInfo(flight, tripType);

                        setToggleTabs("overview");
                        setToggleFare("");
                        if (
                          flight?.OnwardFlight?.SellingPrice ||
                          flight?.OnwardFlight?.MarkedPrice
                        ) {
                          setMarkedPrice(flight?.OnwardFlight?.MarkedPrice);
                          setSellingPrice(flight?.OnwardFlight?.SellingPrice);
                          setStoredAddonPercent(
                            Math.round(
                              (flight.OnwardFlight.NetFare +
                                flight.ReturnFlight.NetFare) *
                                (flight?.OnwardFlight?.AddonRate / 100)
                            )
                          );
                        } else {
                          setMarkedPrice(0);
                          setSellingPrice(0);
                          setStoredAddonPercent(0);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className={`card-right first_card ${
                        openFlightIndex ===
                        flight.ReturnFlight.Index + flight.OnwardFlight.Index
                          ? ""
                          : "collapsed"
                      }`}
                    >
                      <span>
                        <LazyImage
                          className="img-fluid"
                          src={eyeVectorImage}
                          alt="view-icon"
                        />
                      </span>
                      View Flight Details
                    </span>
                  </div>
                </div>

                <div
                  className="collapse"
                  key={flight.ReturnFlight.Index + Math.random() + 1}
                  id={`collapseExample-${flight.ReturnFlight.Index}`}
                  style={{
                    display:
                      openFlightIndex ===
                      flight.ReturnFlight.Index + flight.OnwardFlight.Index
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="d-flex justify-content-start gap-3 select-tab">
                    <div
                      className={
                        toggleTabs === "overview"
                          ? "card_overview active"
                          : "card_overview"
                      }
                      onClick={() => setToggleTabs("overview")}
                    >
                      <div className="card-body px-2 py-1">
                        <h5 className="card-title tab_heading mb-0">
                          Overview
                        </h5>
                      </div>
                    </div>

                    <div
                      className={
                        toggleTabs === "Fare summary"
                          ? "card_summary active"
                          : "card_summary"
                      }
                      onClick={() => setToggleTabs("Fare summary")}
                    >
                      <div className="card-body px-2 py-1">
                        <h5 className="card-title tab_heading mb-0">
                          Fare summary rules
                        </h5>
                      </div>
                    </div>
                    <div
                      className={
                        toggleTabs === "Free Baggage"
                          ? "card_baggge active"
                          : "card_baggge"
                      }
                      onClick={() => setToggleTabs("Free Baggage")}
                    >
                      <div className="card-body px-2 py-1">
                        <h5 className="card-title tab_heading mb-0">
                          Free Baggage
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* // Overview of connecting airports  */}
                </div>

                {openFlightIndex ===
                  flight.ReturnFlight.Index + flight.OnwardFlight.Index &&
                  toggleTabs === "overview" && (
                    <>
                      <div
                        style={{
                          display:
                            openFlightIndex ===
                            flight.ReturnFlight.Index +
                              flight.OnwardFlight.Index
                              ? "flex"
                              : "none",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "35px",
                            cursor: "pointer",
                            marginLeft: "8px",
                            color:
                              toggleOverViewFlight === "onward" ? "red" : "",
                            borderBottom: "1px solid",
                            marginTop: "14px",
                          }}
                          onClick={() => setToggleOverViewFlight("onward")}
                        >
                          {flight.ReturnFlight.To} - {flight.ReturnFlight.From}
                        </span>
                        <span
                          style={{
                            marginRight: "35px",
                            cursor: "pointer",
                            marginLeft: "8px",
                            color:
                              toggleOverViewFlight === "return" ? "red" : "",
                            borderBottom: "1px solid",
                            marginTop: "14px",
                          }}
                          onClick={() => setToggleOverViewFlight("return")}
                        >
                          {flight.ReturnFlight.From} - {flight.ReturnFlight.To}
                        </span>
                      </div>

                      {toggleOverViewFlight === "onward" && (
                        <div className="mt-3 overview">
                          {storeSegments[0]?.map(
                            (
                              { Flight }: any,
                              index: number,
                              flightDetails: any,
                              indexFlight: number
                            ) => (
                              <>
                                {indexFlight !==
                                  storeSegments[0].length - 1 && (
                                  <>
                                    {index !== 0 && (
                                      <div className="change-plan">
                                        <h3 className="h3-bold" key={index}>
                                          Change of planes・
                                          <span>
                                            {newCalculateTotalDuration(
                                              storeSegments[0],
                                              index
                                            )}{" "}
                                            Layover in{" "}
                                            {
                                              Flight?.DepAirportName?.split(
                                                "|"
                                              )[0]
                                            }{" "}
                                          </span>
                                        </h3>
                                      </div>
                                    )}

                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                      <div className="block-view-details gap-2 py-0">
                                        <LazyImage
                                          className="img-fluid"
                                          src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${Flight.MAC}.jpg`}
                                          alt="Flight.MAC"
                                        />
                                        <h3 className="h3-bold res-font mb-0">
                                          {Flight?.Airline?.split("|")[1]}
                                        </h3>
                                        <p className="card-text res-font">
                                          {processMAC(Flight.MAC)} {"-"}{" "}
                                          {Flight.FlightNo}
                                        </p>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="city-name d-flex justify-content-end gap-2">
                                          <p className="card-text mb-0">
                                            Aircraft
                                          </p>
                                          <p className="card-text mb-0">
                                            {
                                              storeSegments[0]?.map(
                                                (
                                                  { Flight }: any,
                                                  index: number
                                                ) => Flight.AirCraft
                                              )[0]
                                            }
                                          </p>
                                        </div>
                                        <div className="city-name d-flex justify-content-end gap-2">
                                          <p className="card-text mb-0">
                                            Travel Class
                                          </p>
                                          <p
                                            className="card-text mb-0"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {flightSearchDetails?.Cabin}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div
                                  className="main_content hops_position"
                                  key={`segments-${index}`}
                                >
                                  <div className="block justify-content-end">
                                    <div className="card-content">
                                      <h3 className="h3-bold text-end res-font">
                                        {moment(Flight.DepartureTime).format(
                                          "HH:mm"
                                        )}
                                      </h3>
                                      <p className="card-text mb-1 text-end">
                                        {moment(Flight.DepartureTime).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p className="card-text mb-0 text-end">
                                        {Flight.DepartureTerminal !== "" &&
                                          `Terminal ${Flight.DepartureTerminal}`}
                                      </p>
                                      <p className="card-text text-end">
                                        {Flight.DepAirportName?.split("|")[0]}
                                        <div>
                                          {Flight.DepAirportName?.split("|")[1]}
                                        </div>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="block justify-content-center">
                                    <div className="card-content content_body">
                                      <p className="card-text mb-2">
                                        {/* {Flight.Duration} */}
                                        {Flight?.Hops ? (
                                          <>
                                            {storeSegments[0]?.map(
                                              (
                                                segment: any,
                                                segmentIndex: any
                                              ) => (
                                                <div key={segmentIndex}>
                                                  {segment?.Flight?.Hops?.map(
                                                    (
                                                      hop: any,
                                                      hopIndex: any
                                                    ) => (
                                                      <div key={hopIndex}>
                                                        {hop?.ArrivalDuration}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {Flight && (
                                              <>
                                                {calculateTimeDifference(
                                                  Flight.DepartureTime,
                                                  Flight.ArrivalTime
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </p>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="left-image">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="currentColor"
                                            viewBox="0 0 256 256"
                                            data-testid="originIcon"
                                          >
                                            <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                          </svg>
                                        </div>
                                        <div className="dot-arrow"></div>
                                        <div className="left-image">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="currentColor"
                                            viewBox="0 0 256 256"
                                            data-testid="destinationIcon"
                                          >
                                            <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <p
                                        className="card-text"
                                        style={{
                                          visibility: "hidden",
                                          height: "0px",
                                        }}
                                      >
                                        some some some te
                                      </p>
                                    </div>
                                  </div>

                                  {Flight?.Hops !== null ? (
                                    <>
                                      {Flight?.Hops.map((hops: any) => (
                                        <>
                                          <div className="block">
                                            <div className="card-content">
                                              <h3 className="h3-bold res-font">
                                                {moment(
                                                  hops?.ArrivalTime
                                                ).format("HH:mm")}
                                              </h3>
                                              <p className="card-text mb-2">
                                                {" "}
                                                {moment(
                                                  hops?.ArrivalTime
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              <p className="card-text mb-0">
                                                {" "}
                                                {hops?.ArrivalTerminal &&
                                                  `Terminal ${hops?.ArrivalTerminal}`}
                                              </p>
                                              <p className="card-text">
                                                {
                                                  hops?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }
                                                <div>
                                                  {
                                                    hops?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }
                                                </div>
                                              </p>
                                            </div>
                                          </div>

                                          <div className="change-plan hops-changes-plan">
                                            {Flight?.Hops?.map(
                                              (hop: any, hopIndex: number) => (
                                                <h5
                                                  className="hops text plan"
                                                  key={hopIndex}
                                                >
                                                  Connecting Time :
                                                  <span>
                                                    {calculateTimeDifference(
                                                      hop.DepartureTime,
                                                      hop.ArrivalTime
                                                    )}{" "}
                                                  </span>
                                                </h5>
                                              )
                                            )}
                                          </div>

                                          <div className="block justify-content-end">
                                            <div className="card-content">
                                              <h3 className="h3-bold text-end res-font">
                                                {/* {moment(
                                                  hops?.ArrivalTime
                                                ).format("LT")} */}
                                                {moment(
                                                  hops?.DepartureTime
                                                ).format("HH:mm")}
                                              </h3>
                                              <p className="card-text mb-1 text-end">
                                                {/* {moment(
                                                  hops?.ArrivalTime
                                                ).format("DD MMM YYYY")}
                                                 */}
                                                {moment(
                                                  hops?.DepartureTime
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              <p className="card-text mb-0 text-end">
                                                {hops?.ArrivalTerminal &&
                                                  `Terminal ${hops?.ArrivalTerminal}`}
                                              </p>
                                              <p className="card-text text-end">
                                                {
                                                  hops?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }
                                                <div>
                                                  {
                                                    hops?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }
                                                </div>
                                              </p>
                                            </div>
                                          </div>

                                          <div className="block justify-content-center">
                                            <div className="card-content content_body">
                                              <p className="card-text mb-2">
                                                {/* {Flight.Duration} */}
                                                {storeSegments[0]?.map(
                                                  (
                                                    segment: any,
                                                    segmentIndex: any
                                                  ) => (
                                                    <div key={segmentIndex}>
                                                      {segment?.Flight?.Hops?.map(
                                                        (
                                                          hop: any,
                                                          hopIndex: any
                                                        ) => (
                                                          <div key={hopIndex}>
                                                            {
                                                              hop?.DepartureDuration
                                                            }
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </p>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="left-image">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    data-testid="originIcon"
                                                  >
                                                    <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                                  </svg>
                                                </div>
                                                <div className="dot-arrow"></div>
                                                <div className="left-image">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    data-testid="destinationIcon"
                                                  >
                                                    <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                                  </svg>
                                                </div>
                                              </div>
                                              <p
                                                className="card-text"
                                                style={{
                                                  visibility: "hidden",
                                                  height: "0px",
                                                }}
                                              >
                                                some some some te
                                              </p>
                                            </div>
                                          </div>
                                          <div className="block">
                                            <div className="card-content">
                                              <h3 className="h3-bold res-font">
                                                {moment(
                                                  Flight?.ArrivalTime
                                                ).format("HH:mm")}
                                              </h3>
                                              <p className="card-text mb-2">
                                                {" "}
                                                {moment(
                                                  Flight?.ArrivalTime
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              <p className="card-text mb-0">
                                                {" "}
                                                {Flight.ArrivalTerminal !==
                                                  "" &&
                                                  `Terminal ${Flight.ArrivalTerminal}`}
                                              </p>
                                              <p className="card-text">
                                                {
                                                  Flight?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }
                                                <div>
                                                  {
                                                    Flight?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }
                                                </div>
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font">
                                            {moment(Flight?.ArrivalTime).format(
                                              "HH:mm"
                                            )}
                                          </h3>
                                          <p className="card-text mb-2">
                                            {" "}
                                            {moment(Flight?.ArrivalTime).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p className="card-text mb-0">
                                            {" "}
                                            {Flight.ArrivalTerminal !== "" &&
                                              `Terminal ${Flight.ArrivalTerminal}`}
                                          </p>
                                          <p className="card-text">
                                            {
                                              Flight?.ArrAirportName?.split(
                                                "|"
                                              )[0]
                                            }
                                            <div>
                                              {
                                                Flight?.ArrAirportName?.split(
                                                  "|"
                                                )[1]
                                              }
                                            </div>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )
                          )}
                          {storeNotices &&
                            storeNotices.length > 0 &&
                            storeNotices
                              .flat()
                              ?.map((notice: any, index: any) => (
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "7px",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    style={{
                                      color: "#ffb22e",
                                      marginRight: "6px",
                                    }}
                                  />
                                  <span>
                                    {notice?.Notice?.replace(/\n/g, " ")}
                                  </span>
                                </div>
                              ))}
                        </div>
                      )}

                      {toggleOverViewFlight === "return" && (
                        <div className="mt-3 overview">
                          {storeReturnSegments[0]?.map(
                            (
                              { Flight }: any,
                              index: number,
                              flightDetails: any,
                              indexFlight: number
                            ) => (
                              <>
                                {indexFlight !==
                                  storeReturnSegments[0].length - 1 && (
                                  <>
                                    {index !== 0 && (
                                      <div className="change-plan">
                                        <h3 className="h3-bold" key={index}>
                                          Change of planes・
                                          <span>
                                            {newCalculateTotalDuration(
                                              storeSegments[0],
                                              index
                                            )}{" "}
                                            Layover in{" "}
                                            {
                                              Flight?.DepAirportName?.split(
                                                "|"
                                              )[0]
                                            }{" "}
                                          </span>
                                        </h3>
                                      </div>
                                    )}

                                    <div className="d-flex justify-content-between align-items-center pb-3">
                                      <div className="block-view-details gap-2 py-0">
                                        <LazyImage
                                          className="img-fluid"
                                          src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${Flight.MAC}.jpg`}
                                          alt="Flight.MAC"
                                        />
                                        <h3 className="h3-bold res-font mb-0">
                                          {Flight?.Airline?.split("|")[2]}
                                        </h3>
                                        <p className="card-text res-font">
                                          {processMAC(Flight.MAC)} {"-"}{" "}
                                          {Flight.FlightNo}
                                        </p>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="city-name d-flex justify-content-end gap-2">
                                          <p className="card-text mb-0">
                                            Aircraft
                                          </p>
                                          <p className="card-text mb-0">
                                            {
                                              storeReturnSegments[0]?.map(
                                                (
                                                  { Flight }: any,
                                                  index: number
                                                ) => Flight.AirCraft
                                              )[0]
                                            }
                                          </p>
                                        </div>
                                        <div className="city-name d-flex justify-content-end gap-2">
                                          <p className="card-text mb-0">
                                            Travel Class
                                          </p>
                                          <p
                                            className="card-text mb-0"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {flightSearchDetails?.Cabin}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div
                                  className="main_content"
                                  key={`segments-${index}`}
                                >
                                  <div className="block justify-content-end">
                                    <div className="card-content">
                                      <h3 className="h3-bold text-end res-font">
                                        {moment(Flight.DepartureTime).format(
                                          "HH:mm"
                                        )}
                                      </h3>
                                      <p className="card-text mb-1 text-end">
                                        {moment(Flight.DepartureTime).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p className="card-text mb-0 text-end">
                                        {Flight.DepartureTerminal !== "" &&
                                          `Terminal ${Flight.DepartureTerminal}`}
                                      </p>
                                      <p className="card-text text-end">
                                        {Flight.DepAirportName?.split("|")[0]}
                                        <div>
                                          {Flight.DepAirportName?.split("|")[1]}
                                        </div>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="block justify-content-center">
                                    <div className="card-content content_body">
                                      <p className="card-text mb-2">
                                        {Flight.Duration}
                                      </p>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="left-image">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="currentColor"
                                            viewBox="0 0 256 256"
                                            data-testid="originIcon"
                                          >
                                            <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                          </svg>
                                        </div>
                                        <div className="dot-arrow"></div>
                                        <div className="left-image">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="currentColor"
                                            viewBox="0 0 256 256"
                                            data-testid="destinationIcon"
                                          >
                                            <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <p
                                        className="card-text"
                                        style={{
                                          visibility: "hidden",
                                          height: "0px",
                                        }}
                                      >
                                        some some some te
                                      </p>
                                    </div>
                                  </div>

                                  {Flight?.Hops !== null ? (
                                    <>
                                      {Flight?.Hops.map((hops: any) => (
                                        <>
                                          <div className="block">
                                            <div className="card-content">
                                              <h3 className="h3-bold res-font">
                                                {moment(
                                                  hops?.ArrivalTime
                                                ).format("LT")}
                                              </h3>
                                              <p className="card-text mb-2">
                                                {" "}
                                                {moment(
                                                  hops?.ArrivalTime
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              <p className="card-text mb-0">
                                                {" "}
                                                {hops?.ArrivalTerminal &&
                                                  `Terminal ${hops?.ArrivalTerminal}`}
                                              </p>
                                              <p className="card-text">
                                                {
                                                  hops?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }
                                                <div>
                                                  {
                                                    hops?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }
                                                </div>
                                              </p>
                                            </div>
                                          </div>

                                          <div className="block justify-content-end">
                                            <div className="card-content">
                                              <h3 className="h3-bold text-end res-font">
                                                {moment(
                                                  hops?.ArrivalTime
                                                ).format("LT")}
                                              </h3>
                                              <p className="card-text mb-1 text-end">
                                                {moment(
                                                  hops?.ArrivalTime
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              <p className="card-text mb-0 text-end">
                                                {hops?.ArrivalTerminal &&
                                                  `Terminal ${hops?.ArrivalTerminal}`}
                                              </p>
                                              <p className="card-text text-end">
                                                {
                                                  hops?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }
                                                <div>
                                                  {
                                                    hops?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }
                                                </div>
                                              </p>
                                            </div>
                                          </div>

                                          <div className="block justify-content-center">
                                            <div className="card-content content_body">
                                              <p className="card-text mb-2">
                                                {Flight.Duration}
                                              </p>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="left-image">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    data-testid="originIcon"
                                                  >
                                                    <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                                  </svg>
                                                </div>
                                                <div className="dot-arrow"></div>
                                                <div className="left-image">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    fill="currentColor"
                                                    viewBox="0 0 256 256"
                                                    data-testid="destinationIcon"
                                                  >
                                                    <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                                  </svg>
                                                </div>
                                              </div>
                                              <p
                                                className="card-text"
                                                style={{
                                                  visibility: "hidden",
                                                  height: "0px",
                                                }}
                                              >
                                                some some some te
                                              </p>
                                            </div>
                                          </div>
                                          <div className="block">
                                            <div className="card-content">
                                              <h3 className="h3-bold res-font">
                                                {moment(
                                                  Flight?.ArrivalTime
                                                ).format("LT")}
                                              </h3>
                                              <p className="card-text mb-2">
                                                {" "}
                                                {moment(
                                                  Flight?.ArrivalTime
                                                ).format("DD MMM YYYY")}
                                              </p>
                                              <p className="card-text mb-0">
                                                {" "}
                                                {Flight.ArrivalTerminal !==
                                                  "" &&
                                                  `Terminal ${Flight.ArrivalTerminal}`}
                                              </p>
                                              <p className="card-text">
                                                {
                                                  Flight?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }
                                                <div>
                                                  {
                                                    Flight?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }
                                                </div>
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font">
                                            {moment(Flight?.ArrivalTime).format(
                                              "HH:mm"
                                            )}
                                          </h3>
                                          <p className="card-text mb-2">
                                            {" "}
                                            {moment(Flight?.ArrivalTime).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p className="card-text mb-0">
                                            {" "}
                                            {Flight.ArrivalTerminal !== "" &&
                                              `Terminal ${Flight.ArrivalTerminal}`}
                                          </p>
                                          <p className="card-text">
                                            {
                                              Flight?.ArrAirportName?.split(
                                                "|"
                                              )[0]
                                            }
                                            <div>
                                              {
                                                Flight?.ArrAirportName?.split(
                                                  "|"
                                                )[1]
                                              }
                                            </div>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )
                          )}
                          {storeNotices &&
                            storeNotices.length > 0 &&
                            storeNotices
                              .flat()
                              ?.map((notice: any, index: any) => (
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "7px",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    style={{
                                      color: "#ffb22e",
                                      marginRight: "6px",
                                    }}
                                  />
                                  <span>
                                    {notice?.Notice?.replace(/\n/g, " ")}
                                  </span>
                                </div>
                              ))}
                        </div>
                      )}
                    </>
                  )}

                {openFlightIndex ===
                  flight.ReturnFlight.Index + flight.OnwardFlight.Index &&
                  toggleTabs === "Fare summary" && (
                    <div className="mt-3 summary">
                      <div className="row">
                        <div className="col-sm-4">
                          <h3 className="h3-bold">Fare Details</h3>
                          <div className="confirmation-box px-1">
                            <div className="confirm-box">
                              <div className="main-box">
                                {toggleFare === "base" ? (
                                  <LazyImage
                                    className="image-arrow active"
                                    width={18}
                                    height={18}
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                ) : (
                                  <LazyImage
                                    className="image-arrow"
                                    id="image_first"
                                    width={18}
                                    height={18}
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                )}

                                <div
                                  className="box-header accordion"
                                  id="acc_image_first"
                                  onClick={() =>
                                    setToggleFare(
                                      toggleFare === "base" ? "" : "base"
                                    )
                                  }
                                >
                                  <h3 className="box-heading">Base Fare</h3>
                                  <h3 className="box-heading">
                                    {" "}
                                    ₹
                                    {baseFareDetails?.ptcFare &&
                                      baseFareDetails?.baseFare?.toLocaleString(
                                        "en-IN"
                                      )}
                                  </h3>
                                </div>
                                <ul
                                  className="box-dropdown w3-animate-left"
                                  id="box_first"
                                  style={{
                                    display:
                                      toggleFare === "base" ? "block" : "none",
                                  }}
                                >
                                  <li className="box-content">
                                    {baseFareDetails?.ptcObj &&
                                      baseFareDetails?.ptcObj?.map(
                                        (fare: any, index: number) => (
                                          <>
                                            <div
                                              className="box-subcontent"
                                              key={`index-flight-${index}`}
                                            >
                                              <p className="box-list">
                                                {" "}
                                                {fare?.PTC === "ADT"
                                                  ? "Adult"
                                                  : fare?.PTC === "CHD"
                                                  ? "Child"
                                                  : "Infant"}{" "}
                                                (
                                                {fare.PTC === "ADT"
                                                  ? flightSearchDetails?.ADT
                                                  : fare?.PTC === "CHD"
                                                  ? flightSearchDetails?.CHD
                                                  : flightSearchDetails?.INF}{" "}
                                                X ₹ {fare?.Fare})
                                              </p>{" "}
                                              <p className="box-list">
                                                ₹{" "}
                                                {fare.PTC === "ADT"
                                                  ? flightSearchDetails?.ADT *
                                                    fare?.Fare
                                                  : fare?.PTC === "CHD"
                                                  ? flightSearchDetails?.CHD *
                                                    fare?.Fare
                                                  : flightSearchDetails?.INF *
                                                    fare?.Fare}
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )}
                                  </li>
                                </ul>
                              </div>

                              <hr className="mb-0" />
                              <div className="main-box">
                                {toggleFare === "tax" ? (
                                  <LazyImage
                                    className="image-arrow active"
                                    width={18}
                                    height={18}
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                ) : (
                                  <LazyImage
                                    className="image-arrow"
                                    id="image_first"
                                    width={18}
                                    height={18}
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                )}
                                <div
                                  className="box-header accordion"
                                  id="acc_image_third"
                                >
                                  <h3
                                    className="box-heading"
                                    onClick={() =>
                                      setToggleFare(
                                        toggleFare === "tax" ? "" : "tax"
                                      )
                                    }
                                  >
                                    Tax and charges
                                  </h3>
                                  <h3 className="box-heading">
                                    ₹{" "}
                                    {Number(totalTaxAmount)?.toLocaleString(
                                      "en-IN"
                                    )}
                                  </h3>
                                </div>
                                <ul
                                  className="box-dropdown w3-animate-left"
                                  style={{
                                    display:
                                      toggleFare === "tax" ? "block" : "none",
                                  }}
                                >
                                  {Object.keys(taxTotals)?.map(
                                    (taxType: any) => (
                                      <>
                                        {taxTotals[taxType] > 0 && (
                                          <li
                                            className="box-content"
                                            key={taxType}
                                          >
                                            <div className="box-subcontent">
                                              <p className="box-list">
                                                {taxType}
                                              </p>
                                              <p className="box-list">
                                                ₹ {taxTotals[taxType]}
                                              </p>
                                            </div>
                                          </li>
                                        )}
                                      </>
                                    )
                                  )}
                                </ul>
                              </div>

                              {discountRate > 0 && (
                                <div className="main-box">
                                  {toggleFare === "discount" ? (
                                    <img
                                      loading="lazy"
                                      className="image-arrow active"
                                      width="18"
                                      height="18"
                                      src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                      alt="collapse-arrow"
                                    />
                                  ) : (
                                    <img
                                      loading="lazy"
                                      className="image-arrow"
                                      id="image_first"
                                      width="18"
                                      height="18"
                                      src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                      alt="collapse-arrow"
                                    />
                                  )}
                                  <div
                                    className="box-header accordion"
                                    id="acc_image_third"
                                  >
                                    <h3
                                      className="box-heading"
                                      onClick={() =>
                                        setToggleFare(
                                          toggleFare === "discount"
                                            ? ""
                                            : "discount"
                                        )
                                      }
                                    >
                                      Discount
                                    </h3>
                                    <h3 className="box-heading">
                                      ₹ {discountRate}
                                    </h3>
                                  </div>
                                  <ul
                                    className="box-dropdown w3-animate-left"
                                    style={{
                                      display:
                                        toggleFare === "discount"
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <li className="box-content">
                                      <div className="box-subcontent">
                                        <p className="box-list">
                                          Coupon Discount
                                        </p>
                                        <p className="box-list">
                                          ₹{" "}
                                          {discountRate?.toLocaleString(
                                            "en-IN"
                                          )}
                                        </p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              )}
                              <hr className="mb-0" />
                              <div className="main-box">
                                <div
                                  className="box-header accordion"
                                  id="acc_image_five"
                                >
                                  <h3 className="box-heading">Total Amount:</h3>
                                  <h3 className="box-heading">
                                    ₹{" "}
                                    {totalFareAmmount?.toLocaleString("en-IN")}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8">
                          <h3 className="h3-bold">Fare Rules</h3>
                          {storeFareDetails &&
                            storeFareDetails[0]?.map(
                              (trip: { Journey: any }, index: number) => (
                                <>
                                  {trip?.Journey[0]?.Segments[0]?.Rules?.map(
                                    (fareRules: any) => (
                                      <>
                                        {fareRules?.Rule?.length > 0 && (
                                          <table className="table mb-2 table-hover table-bordered">
                                            <thead
                                              style={{
                                                backgroundColor: "#fff",
                                              }}
                                            >
                                              <tr>
                                                <th scope="col">
                                                  {index === 0
                                                    ? fareRules.OrginDestination
                                                    : flight.OnwardFlight
                                                        .flightType
                                                    ? fareRules.OrginDestination.split(
                                                        "-"
                                                      )
                                                        .reverse()
                                                        .join("-")
                                                    : fareRules.OrginDestination}
                                                </th>
                                                <th scope="col">ADULT</th>
                                              </tr>
                                            </thead>
                                            {fareRules?.Rule?.map(
                                              (rule: any) => (
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className="active"
                                                      colSpan={2}
                                                    >
                                                      {rule.Head !==
                                                        "ATO Service Fee(Per Pax/ Per Journey)" &&
                                                        rule.Head.replace(
                                                          /\([^)]*\)/g,
                                                          ""
                                                        )}
                                                    </td>
                                                  </tr>
                                                  {rule.Head !==
                                                    "ATO Service Fee(Per Pax/ Per Journey)" &&
                                                    rule?.Info?.map(
                                                      (infoRule: any) => (
                                                        <tr>
                                                          <td data-label="">
                                                            {
                                                              infoRule.Description
                                                            }
                                                          </td>
                                                          <td data-label="">
                                                            {formatAmountAndCurrency(
                                                              infoRule?.AdultAmount,
                                                              infoRule?.CurrencyCode
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                </tbody>
                                              )
                                            )}
                                          </table>
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              )
                            )}

                          {/* add fareruletext */}
                          {storeFareDetails &&
                            storeFareDetails[0]?.map(
                              (trip: { Journey: any }, index: number) => (
                                <>
                                  {trip?.Journey[0]?.Segments[0]?.Rules?.map(
                                    (infoRule: any, ruleIndex: number) => (
                                      <>
                                        {infoRule.FareRuleText !== null && (
                                          <div
                                            className=""
                                            key={`fare-rule-row-${ruleIndex}`}
                                          >
                                            <p className="fare-rule-text px-3 py-2">
                                              {infoRule.FareRuleText}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              )
                            )}

                          <ul className="list-group">
                            <li className="list-itme">
                              The above data is indicative; fare rules are
                              subject to changes by the Airline from time to
                              time depending upon Fare class, and
                              change/cancellation fee amount may also vary based
                              on fluctuations in currency conversion rates.
                            </li>
                            <li className="list-itme">
                              Although we will try to keep this section updated
                              regularly.
                            </li>
                            <li className="list-itme">
                              Feel free to call our Contact ‘Centre for the
                              exact cancellation/change fee.
                            </li>
                            <li className="list-itme">
                              Cancellation/Date change requests will be accepted
                              30 hours prior to departure.
                            </li>
                            <li className="list-itme">
                              GST and RAF charges applicable on the cancellation
                              penalty.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                {openFlightIndex ===
                  flight.ReturnFlight.Index + flight.OnwardFlight.Index &&
                  toggleTabs === "Free Baggage" && (
                    <>
                      {baggageDetailsReturn?.length !== 0 &&
                      baggageDetails?.length !== 0 &&
                      combinedBaggage.length !== 0 &&
                      combinedBaggageReturn !== 0 ? (
                        <BaggageTable
                          baggageDetails={baggageDetails}
                          baggageDetailsReturn={baggageDetailsReturn}
                          combinedBaggage={combinedBaggage}
                          combinedBaggageReturn={combinedBaggageReturn}
                        />
                      ) : null}
                    </>
                  )}
              </div>
            </div>
          )}
        </section>
      ))}
    </>
  );
};
