import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import { SearchHotel } from "../../components/forms/hotel/SearchHotel";
import { SideBarHotelFilter } from "../../components/forms/hotel/SideBarFilterHotel";
import hotelImage from "../../assets/images/image 58.png";
import itemImage from "../../assets/images/image 59.png";
import starImg from "../../assets/images/star.png";
import filterImg from "../../assets/images/filter.png";
import sortingArrow from "../../assets/images/sorting-arrows.png";
import coffeeImg from "../../assets/images/Coffee.png";
import { useEffect, useState } from "react";
import LazyImage from "../../pages/LazyImage";
import { hotelSearch } from "../../api/HotelAPI";
import { toast } from "react-toastify";
import moment from "moment";
import HotelLoader from "../../components/loaders/HotelLoader";

export const FilterHotels = () => {
  const [selectedFilter, setSelectedFilter] = useState("opinions");
  const [searchHotel, setSearchHotel] = useState("");
  const [hotelListOptions, setHotelListOptions] = useState<any[]>([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [hotelLoader, setHotelLoader] = useState(true);

  const [upToFlights, setUpToFlights] = useState(maxPrice);

  const hotelJSON = localStorage.getItem("hotel-search");
  const hotelStorage = hotelJSON ? JSON.parse(hotelJSON) : null;

  useEffect(() => {
    const hotelSearchJSON = localStorage.getItem("hotel-search");
    const hotelSearchStorage = hotelSearchJSON
      ? JSON.parse(hotelSearchJSON)
      : null;

    const hotelAdminJSON = localStorage.getItem("hotel-admin");
    const hotelAdmin = JSON.parse(String(hotelAdminJSON));

    const occupancies = hotelSearchStorage.rooms.map((room: any) => {
      const adultsPaxes = Array(room.adults).fill({ age: 20 });

      const childrenPaxes = Array(room.children).fill({ age: 3 });

      return { paxes: [...adultsPaxes, ...childrenPaxes] };
    });

    const hotelListHandler = async () => {
      // {
      //   checkIn: moment(hotelSearchStorage.checkInDate).format("YYYY-MM-DD"),
      //   checkOut: moment(hotelSearchStorage.checkOutDate).format("YYYY-MM-DD"),
      //   occupancies: occupancies,

      //   hotelIds: ["228254"],
      //   currency: "USD",
      //   market: "ES",
      //   language: "es",
      //   nationality: "ES",
      //   client: "maashree",
      //   context: "DIDA",
      //   auditTransactions: false,
      //   testMode: false,
      //   timeout: 25000,
      //   accessIncludes: [hotelAdmin.supplierAccessCode],
      // }

      hotelSearch({
        checkIn: moment(hotelSearchStorage.checkInDate).format("YYYY-MM-DD"),
        checkOut: moment(hotelSearchStorage.checkOutDate).format("YYYY-MM-DD"),
        occupancies: occupancies,
        hotelIds: ["228254"],
        filterSearch: ["23209"],
        currency: "USD",
        market: "ES",
        language: "en",
        nationality: "ES",
        client: hotelAdmin.clientData.name,
        context: hotelAdmin.supplierData.code,
        auditTransactions: false,
        testMode: false,
        timeout: 15000,
      })
        .then((res) => {
          if (
            res.data.hotelX.search.options &&
            res.data.hotelX.search.options !== null
          ) {
            setHotelListOptions(res.data.hotelX.search.options);
          } else {
            toast.error("Something went wrong, Please try again!", {
              toastId: "hotel-search-err",
            });
            setHotelListOptions([]);
          }
          setHotelLoader(false);
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            toastId: "hotel-search-toast",
          })
        );
    };

    hotelListHandler();
  }, []);

  useEffect(() => {
    let minPriceAmount = Infinity;
    let maxPriceAmount = -Infinity;

    for (const item of hotelListOptions) {
      const price = item.price.gross;
      if (price < minPriceAmount) {
        minPriceAmount = price;
      }
      if (price > maxPriceAmount) {
        maxPriceAmount = price;
      }
    }

    minPriceAmount = Math.round(minPriceAmount);
    maxPriceAmount = Math.round(maxPriceAmount);

    setMaxPrice(maxPriceAmount);
    setMinPrice(minPriceAmount);

    setUpToFlights(maxPriceAmount);
  }, [hotelListOptions]);

  const getCurrencyPrice = (currencyData: any) => {
    const formattedGrossAmount =
      currencyData &&
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyData?.currency,
      }).format(currencyData.gross);

    return formattedGrossAmount;
  };

  const selectRoomHandler = (hotelCode: string, optionRef: string) => {
    const existingHotelAdminData = localStorage.getItem("hotel-admin");
    let parsedData;

    if (existingHotelAdminData !== null) {
      parsedData = JSON.parse(existingHotelAdminData);

      parsedData.selectedHotelCode = hotelCode;
      parsedData.selectedOptionRef = optionRef;
    } else {
      parsedData = {
        selectedHotelCode: hotelCode,
        selectedOptionRef: optionRef,
      };
    }

    localStorage.setItem("hotel-admin", JSON.stringify(parsedData));
    localStorage.setItem("optionRef-hotel", JSON.stringify(parsedData));

    window.open("/hotel-room", "_blank");
  };

  // function combineObjectsByChargeType(inputArray: any, chargeType: string) {
  //   const combinedObject = {
  //     chargeType: chargeType,
  //     price: {
  //       currency: "",
  //       binding: false,
  //       net: 0,
  //       gross: 0,
  //       exchange: {
  //         currency: "",
  //         rate: 1,
  //       },
  //       markups: null,
  //     },
  //   };

  //   for (const item of inputArray) {
  //     if (item.chargeType === chargeType) {
  //       combinedObject.price.net += item.price.net;
  //       combinedObject.price.gross += item.price.gross;

  //       if (!combinedObject?.price?.currency) {
  //         combinedObject.price.currency = item?.price?.currency;
  //       }
  //     }
  //   }

  //   const currencyCode = combinedObject?.price?.currency;

  //   const formattedAmount =
  //     currencyCode &&
  //     new Intl.NumberFormat("en-US", {
  //       style: "currency",
  //       currency: currencyCode,
  //     }).format(combinedObject.price.gross);

  //   return formattedAmount;
  // }

  const filterHotels = (hotels: any) => {
    if (selectedFilter === "price") {
      return hotels
        .slice()
        .sort(
          (a: { price: { gross: number } }, b: { price: { gross: number } }) =>
            a.price.gross - b.price.gross
        );
    }
    return hotels;
  };

  // Usage example:
  const sortedHotelsList = filterHotels(hotelListOptions);

  const filterHotelsByPriceAndSearch = (
    hotels: any[],
    uptoPrice: number,
    searchHotel: string
  ) => {
    return hotels?.filter((hotel: any) => {
      const priceCondition = hotel.price.gross <= uptoPrice;

      const searchTextCondition = hotel.hotelName
        .toLowerCase()
        .includes(searchHotel.toLowerCase());
      return priceCondition && searchTextCondition;
    });
  };

  const filteredHotels = filterHotelsByPriceAndSearch(
    sortedHotelsList,
    upToFlights,
    searchHotel
  );

  return (
    <AppLayout>
      <main className="container mt-3">
        <div className="tabbing-bar res_tabbing">
          <div className="tab-content">
            <SearchHotel activeTab="hotel" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h1 className="h1-inner">
              Showing hotels in {hotelStorage.destinationName}
            </h1>
          </div>
        </div>

        <div className="search-sticky_1" style={{ top: "110px" }}>
          <div className="res-show-flight-tap">
            <div className="d-flex justify-content-between align-content-center">
              <div className="left-filter">
                <h3
                  className="h3-bold mb-0"
                  data-bs-toggle="modal"
                  data-bs-target="#hotel_sort_option"
                >
                  <span>
                    <LazyImage
                      src={sortingArrow}
                      alt=""
                      className="img-fluid"
                    />{" "}
                  </span>
                  Sort
                </h3>
              </div>
              <div className="right-filter">
                <h3
                  className="h3-bold mb-0"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal_hotel_search"
                >
                  <span>
                    <LazyImage className="img-fluid" alt="" src={filterImg} />{" "}
                  </span>
                  Filter
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {hotelListOptions?.length > 0 ? (
            <>
              <SideBarHotelFilter
                minPrice={minPrice}
                maxPrice={maxPrice}
                upToFlights={upToFlights}
                setUpToFlights={setUpToFlights}
                setSearchHotel={setSearchHotel}
              />

              <div className="col-sm-9">
                <div
                  className="select-tab-container gap-0 res_hide"
                  style={{ justifyContent: "end" }}
                >
                  <div className="select-tab">
                    <h3
                      className={
                        selectedFilter === "opinions"
                          ? "tab-bold tab-active mb-0"
                          : "tab-bold  mb-0"
                      }
                      style={{
                        color:
                          selectedFilter === "opinions" ? "white" : "#2f4858",
                      }}
                      id="Opinions"
                      onClick={() => setSelectedFilter("opinions")}
                    >
                      Opinions
                    </h3>
                  </div>
                  <div
                    className="select-tab"
                    onClick={() => setSelectedFilter("price")}
                  >
                    <h3
                      className={
                        selectedFilter === "price"
                          ? "tab-bold tab-active mb-0"
                          : "tab-bold  mb-0"
                      }
                      style={{
                        color: selectedFilter === "price" ? "white" : "#2f4858",
                      }}
                      id="Price"
                    >
                      Price
                    </h3>
                  </div>
                  <div
                    className="select-tab"
                    onClick={() => setSelectedFilter("category")}
                  >
                    <h3
                      className={
                        selectedFilter === "category"
                          ? "tab-bold tab-active mb-0"
                          : "tab-bold  mb-0"
                      }
                      style={{
                        color:
                          selectedFilter === "category" ? "white" : "#2f4858",
                      }}
                      id="Category"
                    >
                      Category
                    </h3>
                  </div>
                  <div
                    className="select-tab"
                    onClick={() => setSelectedFilter("suggested")}
                  >
                    <h3
                      className={
                        selectedFilter === "suggested"
                          ? "tab-bold tab-active mb-0"
                          : "tab-bold  mb-0"
                      }
                      style={{
                        color:
                          selectedFilter === "suggested" ? "white" : "#2f4858",
                      }}
                      id="Suggested"
                    >
                      Suggested
                    </h3>
                  </div>
                </div>

                {/* Listing  */}
                <section
                  className="Opinions-section res_hide"
                  id="Opinions_section"
                >
                  {filteredHotels?.map((hotel) => (
                    <div className="card card_section" key={hotel.id}>
                      <div className="card-body py-0 main_card">
                        <div className="row opinion-row">
                          <div className="col-sm-4 col-md-4 py-3">
                            <div className="card-img">
                              <LazyImage
                                className="img-fluid show-img"
                                src={hotelImage}
                                alt="Emirates"
                              />
                            </div>
                            <div className="gallery-container">
                              <div
                                id="carouselExampleControls"
                                className="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-inner">
                                  <div className="carousel-item active">
                                    <div className="item-list">
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="item-list">
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="item-list">
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                      <div className="item">
                                        <LazyImage
                                          src={itemImage}
                                          className="d-block w-100"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselExampleControls"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselExampleControls"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-5 col-md-5 py-3 content-section">
                            <div
                              className="image-content"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="block-content">
                                <span className="content-text content-rating">
                                  {" "}
                                  3.9{" "}
                                </span>
                                <span className="content-text">
                                  {" "}
                                  Very good{" "}
                                </span>
                                <span className="content-text">
                                  {" "}
                                  (4733) ratings{" "}
                                </span>
                              </div>
                              <div className="block-content">
                                <h3 className="h3-bold">{hotel.hotelName}</h3>
                                <span>
                                  <LazyImage
                                    className="img-fluid"
                                    src={starImg}
                                    alt=""
                                  />{" "}
                                </span>
                              </div>
                              <div className="block-content">
                                <span className="content-text-light">
                                  {/* {hotel.location.city}{" "} */}
                                  city name
                                </span>
                                <span className="content-text">
                                  {" "}
                                  |{/* {node.hotelData.location.address} */}
                                  address
                                </span>
                              </div>
                              <div className="block-content">
                                <button className="simple-btn">Wi-Fi</button>
                                <button className="simple-btn">Pool</button>
                                <button className="simple-btn">Gym</button>
                                <button className="simple-btn">Parking</button>
                              </div>
                              <div className="block-content">
                                <span className="simple-btn">
                                  <LazyImage
                                    className="img-fluid"
                                    src={coffeeImg}
                                    alt=""
                                  />
                                  &nbsp;Free breakfast{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 px-0 teaxes_col">
                            <div className="right-section">
                              <div className="image-content">
                                <div className="block-content-right">
                                  {/* <span className="content-text">+₹4,799</span> */}
                                </div>
                                <div className="block-content-right">
                                  <h3 className="h3-bold">
                                    {hotel.price &&
                                      getCurrencyPrice(hotel.price)}{" "}
                                  </h3>
                                </div>
                                <div className="block-content-right">
                                  <span className="content-text">
                                    {" "}
                                    +{" "}
                                    {/* {combineObjectsByChargeType(
                                      hotel.surcharges,
                                      "INCLUDE"
                                    )}{" "} */}
                                    taxes & fees
                                  </span>
                                </div>
                                <div className="block-content-right">
                                  <span className="content-text">
                                    {" "}
                                    Per Night
                                  </span>
                                </div>
                                <div className="block-content-right pt-3">
                                  <button
                                    className="btn btn-login"
                                    onClick={() =>
                                      selectRoomHandler(
                                        hotel.hotelCode,
                                        hotel.id
                                      )
                                    }
                                  >
                                    Select Room
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </section>

                {/* Responsive section  */}
                <section
                  className="Opinions-section res_show"
                  style={{ display: "block !important" }}
                >
                  {hotelListOptions?.map((hotel) => (
                    <div
                      className="card card_section"
                      key={`hotel1-${hotel.id}`}
                    >
                      <div className="card-body py-0 main_card">
                        <div className="row opinion-row">
                          <div className="col-sm-4 col-md-4 py-3 pb-0">
                            <div
                              className="card-img"
                              onClick={() =>
                                selectRoomHandler(hotel.hotelCode, hotel.id)
                              }
                            >
                              <LazyImage
                                className="img-fluid show-img"
                                src={hotelImage}
                                alt="Emirates"
                              />
                            </div>
                          </div>
                          <div className="col-sm-5 col-md-5 py-3 pt-1 content-section justify-content-between">
                            <div
                              onClick={() =>
                                selectRoomHandler(hotel.hotelCode, hotel.id)
                              }
                              className="image-content"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="block-content mb-1 gap-2">
                                <span className="content-text content-rating">
                                  3.9
                                </span>
                                <span className="content-text">Very good</span>
                                <span className="content-text">
                                  (4733) ratings
                                </span>
                              </div>
                              <div className="block-content mb-1 gap-2">
                                <h3 className="h3-bold mb-0">
                                  {" "}
                                  {hotel.hotelName}
                                </h3>
                                <span>
                                  <LazyImage
                                    className="img-fluid"
                                    src={starImg}
                                    alt="star"
                                  />
                                </span>
                              </div>
                              <div className="block-content mb-1 gap-0">
                                <span className="content-text-light">
                                  {" "}
                                  {/* {node.hotelData.location.city}{" "} */}
                                  city
                                </span>
                                <span className="content-text">
                                  {" "}
                                  |{/* {node.hotelData.location.address} */}
                                  address
                                </span>
                              </div>
                            </div>
                            <div className="right-section">
                              <div
                                className="image-content"
                                style={{ backgroundColor: "white" }}
                              >
                                <div className="block-content-right">
                                  {/* <span className="content-text">+₹4,799</span> */}
                                </div>
                                <div className="block-content-right">
                                  <h3 className="h3-bold mb-0">
                                    {" "}
                                    {hotel.price &&
                                      getCurrencyPrice(hotel.price)}{" "}
                                  </h3>
                                </div>
                                <div className="block-content-right">
                                  <p className="content-text mb-0 pt-1">
                                    {" "}
                                    +{" "}
                                    {/* {combineObjectsByChargeType(
                                      hotel.surcharges,
                                      "INCLUDE"
                                    )}{" "} */}
                                    taxes & fees
                                  </p>
                                </div>
                                <div className="block-content-right">
                                  <p className="content-text mb-0 pt-1">
                                    Per Night
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </section>
              </div>
            </>
          ) : (
            <h5 style={{ textAlign: "center", fontSize: "x-large" }}>
              No Hotels Found
            </h5>
          )}
        </div>
      </main>

      {/* // Sort modal   */}
      <div
        className="modal modal-bottom fade"
        id="hotel_sort_option"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel_hotel_sort_option"
        aria-hidden="true"
      >
        <div className="modal-dialog modal_bottom">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="h3 bold">Sort</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="opinion-check"
                />
                <label className="form-check-label" htmlFor="opinion-check">
                  Opinions
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="price-check"
                />
                <label className="form-check-label" htmlFor="price-check">
                  Price
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="category-check"
                />
                <label className="form-check-label" htmlFor="category-check">
                  Category
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="suggested-check"
                />
                <label className="form-check-label" htmlFor="suggested-check">
                  Suggested
                </label>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-login search_record">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <TalkToAgents />
      <Footer />
      {hotelLoader && <HotelLoader />}
    </AppLayout>
  );
};
