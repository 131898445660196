type Document = {
  city?: string;
  destinationCode?: string;
  country?: string;
  hotelName?: string;
};

type HotelDestinationItem = {
  document?: Document;
};

export type HotelDestinationList = {
  destinations?: HotelDestinationItem[];
  hotels?: HotelDestinationItem[];
};

type FormattedItem = {
  city: string;
  destinationCode: string;
  country: string;
  hotelName: string;
  type: "hotel" | "destination";
};

export const formatAndFilterHotelDestinationData = (
  hotelDestinationList: HotelDestinationList | undefined
): FormattedItem[] => {
  if (!hotelDestinationList) return [];

  const { destinations = [], hotels = [] } = hotelDestinationList;

  // Helper function to format the item into a structured FormattedItem
  const formatItem = (
    item: HotelDestinationItem,
    type: "hotel" | "destination"
  ): FormattedItem => ({
    city: item?.document?.city || "",
    destinationCode: item?.document?.destinationCode || "",
    country: item?.document?.country || "",
    hotelName: type === "hotel" ? item?.document?.hotelName || "" : "",
    type, // Assign type as 'hotel' or 'destination'
  });

  // Format destinations and hotels separately
  const formattedDestinations = destinations.map((item) =>
    formatItem(item, "destination")
  );
  const formattedHotels = hotels.map((item) => formatItem(item, "hotel"));

  // Combine the formatted destinations and hotels into a single array
  return [...formattedDestinations, ...formattedHotels];
};
