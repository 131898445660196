import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { grey } from "@mui/material/colors";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: "30px",
  padding: "6px",
  color: grey[900],
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

interface BaggageTableProps {
  baggageDetails: any;
  combinedBaggage: any[];
  baggageDetailsReturn?: any;
  combinedBaggageReturn?: any[];
}

const BaggageTable: React.FC<BaggageTableProps> = ({
  baggageDetails,
  combinedBaggage,
  baggageDetailsReturn,
  combinedBaggageReturn,
}) => {
  return (
    <div className="mt-3 baggage">
      <div className="bagger-section">
        {/* Outbound Baggage Table */}
        {combinedBaggage ? (
          <>
            <Table
              component={Paper}
              className="mb-2"
              sx={{
                width: "100%",
                tableLayout: "auto", // Allowing the table to automatically adjust column widths
              }}
              aria-label="Baggage Table"
            >
              <TableHead style={{ backgroundColor: "#fff" }}>
                <TableRow>
                  <TableCell
                    className="active"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  >
                    Sector/Flight
                  </TableCell>
                  <TableCell
                    className="active"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  >
                    Check in Baggage
                  </TableCell>
                  <TableCell
                    className="active"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  >
                    Cabin Baggage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {baggageDetails?.Journey &&
                  combinedBaggage?.map((bag, index) => (
                    <React.Fragment key={index}>
                      {bag?.SSR?.map(
                        (ssrBag: any, ssrIndex: number) =>
                          ssrBag?.Code === "BAG" && (
                            <TableRow key={ssrIndex}>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {bag.DepartureCode} - {bag.ArrivalCode}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  wordWrap: "break-word", // Ensures long text wraps
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {
                                  ssrBag?.Description?.replace(
                                    /\s/g,
                                    ""
                                  ).split(",")[0]
                                }{" "}
                                (
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}
                                )
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  wordWrap: "break-word", // Ensures long text wraps
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {
                                  ssrBag?.Description?.replace(
                                    /\s/g,
                                    ""
                                  ).split(",")[1]
                                }{" "}
                                (
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}
                                )
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
            {/* Inclusions Section */}
            {baggageDetails?.Journey &&
              combinedBaggage?.map((bag, index) => (
                <React.Fragment key={index}>
                  {bag?.SSR?.map(
                    (ssrBag: any, ssrIndex: number) =>
                      ssrBag?.Code === "BRAD" && (
                        <Box
                          key={ssrIndex}
                          sx={{
                            borderRadius: 2,
                            bgcolor: "background.default",
                            display: "block",
                            gap: 2,
                            margin: { xs: "10px 0", sm: "14px 0" }, // Responsive margin
                            padding: { xs: "12px", sm: "14px" }, // Responsive padding
                          }}
                        >
		<Typography
                            sx={{
                              fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
                              fontWeight: "bold",
                              marginBottom: 1,
                            }}
                          >
                            Inclusions ( {bag.DepartureCode} - {bag.ArrivalCode}
                            )
                          </Typography>
                          <Paper elevation={2}>
                            <Item
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                padding: { xs: "8px", sm: "12px" },
                              }}
                            >
                              <Typography
                                sx={{
                                  border: "2px solid rgb(255 97 0)",
                                  borderRadius: "8px",
                                  padding: "2px 12px",
                                  background: "rgb(255 97 0)",
                                  color: "white",
                                  fontSize: "14px",
                                }}
                              >
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}{" "}
                                :
                              </Typography>
                              {ssrBag?.Description?.split("|")?.map(
                                (item: string, index: number) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      fontSize: { xs: "12px", sm: "14px" }, // Responsive font size
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <DoneIcon color="disabled" fontSize="small" />
                                    <span>{item.trim()}</span>
                                  </Typography>
                                )
                              )}
                            </Item>
                          </Paper>
                        </Box>
                      )
                  )}
                </React.Fragment>
              ))}
          </>
        ) : null}
        {/* Inbound Baggage Table */}
        {combinedBaggageReturn ? (
          <>
            <Table
              component={Paper}
              className="mb-2"
              sx={{
                width: "100%",
                tableLayout: "auto", // Allowing the table to automatically adjust column widths
              }}
              aria-label="Baggage Table"
            >
              <TableHead style={{ backgroundColor: "#fff" }}>
                <TableRow>
                  <TableCell
                    className="active"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  >
                    Sector/Flight
                  </TableCell>
                  <TableCell
                    className="active"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  >
                    Check in Baggage
                  </TableCell>
                  <TableCell
                    className="active"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                  >
                    Cabin Baggage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {baggageDetailsReturn?.Journey &&
                  combinedBaggageReturn?.map((bag, index) => (
                    <React.Fragment key={index}>
                      {bag?.SSR?.map(
                        (ssrBag: any, ssrIndex: number) =>
                          ssrBag?.Code === "BAG" && (
                            <TableRow key={ssrIndex}>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {bag.ArrivalCode} - {bag.DepartureCode}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  wordWrap: "break-word", // Ensures long text wraps
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {
                                  ssrBag?.Description?.replace(
                                    /\s/g,
                                    ""
                                  ).split(",")[0]
                                }{" "}
                                (
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}
                                )
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  wordWrap: "break-word", // Ensures long text wraps
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {
                                  ssrBag?.Description?.replace(
                                    /\s/g,
                                    ""
                                  ).split(",")[1]
                                }{" "}
                                (
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}
                                )
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </>
        ) : null}

	 {/* Disclaimer Section */}
        <div className="short-bagger">
          <ul className="list-group">
            <li className="list-item">
              The information presented above is as obtained from the airline
              reservation system. Maashree.com does not guarantee the accuracy
              of this information.
            </li>
            <li className="list-item">
              The baggage allowance may vary according to stop-overs, connecting
              flights, and changes in airline rules.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BaggageTable;













