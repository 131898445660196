import { Calendar } from "react-date-range";
import { fullNameRegex, passportRegex } from "../../utils/validation";
import calendarSVG from "../../assets/images/calender.svg";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

interface CheckoutFormPassengerProps {
  countries: [{ defaultName: string; phoneCode: string; nameCode: string }];
  index: any;
  numberOfPassengers: any;
  passenger: any;
  update: any;
  setErrorForForms: any;
  errorForForms: boolean;
  type: string;
  userData: any;
  flightName?: any;
  flightMCA?: any;
  storeCheckDomestic?: any;
  checkNameChecklistSettings?: any;
}

export const CheckoutFormPassenger = ({
  countries,
  index,
  numberOfPassengers,
  passenger,
  update,
  setErrorForForms,
  errorForForms,
  type,
  userData,
  flightName,
  flightMCA,
  storeCheckDomestic,
  checkNameChecklistSettings,
}: CheckoutFormPassengerProps) => {
  const [isInputClicked, setIsInputClicked] = useState("");
  const [showCalenderDOB, setShowCalenderDOB] = useState(false);
  const [date, setDate] = useState<Date>();

  const [showPassportCalender, setShowPassportCalender] = useState(false);
  const [datePassport, setDatePassport] = useState<Date>();

  const calendarRef = useRef<HTMLSpanElement | null>(null);
  const calendarPassportRef = useRef<HTMLSpanElement | null>(null);

  const today = new Date();

  const deptDate = moment(userData).toDate();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalenderDOB(false);
      }

      if (
        calendarPassportRef.current &&
        !calendarPassportRef.current.contains(event.target)
      ) {
        setShowPassportCalender(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDateRangeMax =
    type === "child"
      ? new Date(today.getFullYear() - 2, today.getMonth(), today.getDate())
      : type === "infant"
      ? new Date(today.getFullYear(), today.getMonth(), today.getDate())
      : new Date(today.getFullYear() - 11, today.getMonth(), today.getDate());

  const getDateRangeMin =
    type === "child"
      ? new Date(today.getFullYear() - 12, today.getMonth(), today.getDate())
      : type === "infant"
      ? new Date(today.getFullYear() - 2, today.getMonth() + 1, today.getDate())
      : new Date(today.getFullYear() - 99, today.getMonth(), today.getDate());

  const checkPassengerErrorForTitle =
    errorForForms && passenger.title === "Title";

  return (
    <div
      className="row"
      style={{ marginTop: "3%", fontWeight: "600" }}
      key={`passenger-${index + passenger.id}`}
    >
      <div className="col-sm-12 col-md-12">
        <h3 className="h3-bold mb-3" style={{ textTransform: "capitalize" }}>
          {type === "adult" ? `Adult X ${index + 1}` : `${type} ${index + 1}`}
        </h3>
      </div>
      <div className="col-sm-3 col-md-3 pb-4">
        <div className="card-content">
          <div className="my-0 mb-4 text-left1 mobile-listing">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              First Name
            </label>
            <div className="mobile-select">
              <div
                className="mobile-data-list"
                style={{
                  border: checkPassengerErrorForTitle ? "2px solid red" : "",
                  borderRadius: checkPassengerErrorForTitle ? "6px" : "",
                }}
              >
                <div className="dropdown">
                  <span
                    className="dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="country-code">
                      {" "}
                      {passenger.title}{" "}
                      <img
                        loading="lazy"
                        src="assets/images/CaretDown.png"
                        className="caret"
                        height=""
                        alt=""
                      />{" "}
                    </span>
                  </span>
                  <ul className="dropdown-menu">
                    {type === "infant" || type === "child" ? (
                      <li>
                        <span
                          className={`dropdown-item ${
                            passenger.title === "Mstr" ? "active" : ""
                          }`}
                          onClick={() => {
                            const updatedPassenger = {
                              ...passenger,
                              title: "Mstr",
                            };
                            update(index, updatedPassenger);
                          }}
                        >
                          Mstr
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span
                          className={`dropdown-item ${
                            passenger.title === "Mr" ? "active" : ""
                          }`}
                          onClick={() => {
                            const updatedPassenger = {
                              ...passenger,
                              title: "Mr",
                            };
                            update(index, updatedPassenger);
                          }}
                        >
                          Mr
                        </span>
                      </li>
                    )}
                    {type !== "infant" && type !== "child" && (
                      <li>
                        <span
                          className={`dropdown-item ${
                            passenger.title === "Mrs" ? "active" : ""
                          }`}
                          onClick={() => {
                            const updatedPassenger = {
                              ...passenger,
                              title: "Mrs",
                            };
                            update(index, updatedPassenger);
                          }}
                        >
                          Mrs
                        </span>
                      </li>
                    )}
                    {type === "infant" || type === "child" ? (
                      <li>
                        <span
                          className={`dropdown-item ${
                            passenger.title === "Miss " ? "active" : ""
                          }`}
                          onClick={() => {
                            const updatedPassenger = {
                              ...passenger,
                              title: "Miss",
                            };
                            update(index, updatedPassenger);
                          }}
                        >
                          Miss
                        </span>
                      </li>
                    ) : (
                      <li>
                        <span
                          className={`dropdown-item ${
                            passenger.title === "Ms" ? "active" : ""
                          }`}
                          onClick={() => {
                            const updatedPassenger = {
                              ...passenger,
                              title: "Ms",
                            };
                            update(index, updatedPassenger);
                          }}
                        >
                          Ms
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                style={{ textTransform: "uppercase" }}
                id="exampleFormControlInput1"
                placeholder="First Name"
                value={passenger.firstName}
                onFocus={() => setIsInputClicked("firstName")}
                onBlur={() => setIsInputClicked("")}
                onChange={(e) => {
                  const test = fullNameRegex.test(e.target.value);

                  setErrorForForms(!test);
                  const updatedPassenger = {
                    ...passenger,
                    firstName: e.target.value,
                  };
                  update(index, updatedPassenger);
                }}
              />
            </div>
            {passenger.firstName !== "" &&
              !fullNameRegex.test(passenger.firstName) && (
                <p style={{ color: "red", margin: "4px", fontSize: "14px" }}>
                  {" "}
                  Please enter a valid First name
                </p>
              )}
            {errorForForms && passenger.firstName === "" && (
              <p style={{ color: "red", margin: "4px", fontSize: "14px" }}>
                {" "}
                Please enter a valid First name
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-3 col-md-3 pb-4">
        <div className="card-content">
          <div className="mb-4 text-left1">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              style={{ textTransform: "uppercase" }}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Last Name"
              value={passenger.lastName}
              onFocus={() => setIsInputClicked("lastName")}
              onBlur={() => setIsInputClicked("")}
              onChange={(e) => {
                const test = fullNameRegex.test(e.target.value);

                setErrorForForms(!test);
                const updatedPassenger = {
                  ...passenger,
                  lastName: e.target.value,
                };
                update(index, updatedPassenger);
              }}
            />
            {passenger.lastName !== "" &&
              !fullNameRegex.test(passenger.lastName) && (
                <p style={{ color: "red", margin: "4px" }}>
                  {" "}
                  Please enter a valid Last name
                </p>
              )}
            {errorForForms && passenger.lastName === "" && (
              <p style={{ color: "red", margin: "4px" }}>
                {" "}
                Please enter a valid Last name
              </p>
            )}
          </div>
        </div>
      </div>

      {(storeCheckDomestic !== null &&
        storeCheckDomestic?.DOB === 0 &&
        type === "adult") || (
        <div className="col-sm-3 col-md-3 pb-4">
          <div className="card-content ">
            <div className="mb-4 text-left1">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Date of Birth
              </label>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  id="exampleFormControlInput1"
                  placeholder="DOB"
                  value={passenger.dateOfBirth}
                  onChange={(e) => {}}
                  onClick={() => setShowCalenderDOB(!showCalenderDOB)}
                />{" "}
                <img
                  loading="lazy"
                  className="multi-icon"
                  src={calendarSVG}
                  alt=""
                  style={{ marginLeft: "-36px", display: "flex" }}
                  onClick={() => {
                    setShowCalenderDOB(!showCalenderDOB);
                  }}
                />
              </div>
              {showCalenderDOB && (
                <span
                  ref={calendarRef}
                  style={{ position: "absolute", zIndex: 9999 }}
                >
                  <Calendar
                    maxDate={getDateRangeMax}
                    minDate={getDateRangeMin}
                    onChange={(item) => {
                      setDate(item);

                      const updatedPassenger = {
                        ...passenger,
                        dateOfBirth: moment(item).format("DD MMM YYYY"),
                      };
                      update(index, updatedPassenger);

                      setShowCalenderDOB(!showCalenderDOB);
                    }}
                    date={date}
                  />
                </span>
              )}
              {errorForForms && passenger.dateOfBirth === "" && (
                <p style={{ color: "red", margin: "4px" }}>
                  {" "}
                  Please select a Date of Birth
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {storeCheckDomestic !== null && storeCheckDomestic?.PassportNo === 1 && (
        <>
          <div className="col-sm-3 col-md-3 pb-4">
            <div className="card-content">
              <div className="mb-4 text-left1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Passport No.
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Passport no."
                  value={passenger.passportNumber}
                  onChange={(e) => {
                    const test = passportRegex.test(e.target.value);
                    storeCheckDomestic?.PassportNo === 1 &&
                      setErrorForForms(!test);

                    const updatedPassenger = {
                      ...passenger,
                      passportNumber: e.target.value,
                    };
                    update(index, updatedPassenger);
                  }}
                />
                {passenger.passportNumber !== "" &&
                  !passportRegex.test(passenger.passportNumber) && (
                    <p style={{ color: "red", margin: "4px" }}>
                      {" "}
                      Please enter a valid passport number
                    </p>
                  )}
                {errorForForms && passenger.passportNumber === "" && (
                  <p style={{ color: "red", margin: "4px" }}>
                    {" "}
                    Please enter a passport number
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-3 pb-4">
            <div className="card-content text-left1">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Issuing Country
              </label>
              <select
                value={passenger.passportCountry}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  const selectedCountry = e.target.value.toUpperCase();
                  const updatedPassenger = {
                    ...passenger,
                    passportCountry: selectedCountry,
                  };
                  update(index, updatedPassenger);
                }}
              >
                <option value="">Select</option>
                {countries.map(({ defaultName, nameCode }) => (
                  <option
                    key={`name--${nameCode}`}
                    value={String(nameCode.toUpperCase())}
                  >
                    {defaultName}
                  </option>
                ))}
              </select>
              {errorForForms && passenger.passportCountry === "" && (
                <p style={{ color: "red", margin: "4px" }}>
                  {" "}
                  Please select a passport country
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-3 col-md-3 pb-4">
            <div className="card-content ">
              <div className="mb-4 text-left1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Passport Expiry
                </label>
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="DOP"
                    value={moment(passenger.passportExpiry).format(
                      "DD MMM YYYY"
                    )}
                    autoComplete="off"
                    onChange={() => {}}
                    onClick={() =>
                      setShowPassportCalender(!showPassportCalender)
                    }
                  />
                  <img
                    loading="lazy"
                    className="multi-icon"
                    src={calendarSVG}
                    alt=""
                    style={{ marginLeft: "-36px", display: "flex" }}
                    onClick={() => {
                      setShowPassportCalender(!showPassportCalender);
                    }}
                  />
                </div>
                {showPassportCalender && (
                  <span
                    ref={calendarPassportRef}
                    style={{ position: "absolute", zIndex: 9999 }}
                  >
                    <Calendar
                      minDate={
                        new Date(
                          deptDate.getFullYear(),
                          deptDate.getMonth() + 6,
                          deptDate.getDate()
                        )
                      }
                      onChange={(item) => {
                        setDatePassport(item);
                        const updatedPassenger = {
                          ...passenger,
                          passportExpiry: moment(item).format("YYYY-MM-DD"),
                        };
                        update(index, updatedPassenger);

                        setShowPassportCalender(!showPassportCalender);
                      }}
                      date={datePassport}
                    />
                  </span>
                )}
                {errorForForms && passenger.passportExpiry === "" && (
                  <p style={{ color: "red", margin: "4px" }}>
                    {" "}
                    Please select a passport Expiry
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {storeCheckDomestic !== null && storeCheckDomestic?.Nationality === 1 && (
        <div className="col-sm-3 col-md-3 pb-4">
          <div className="card-content text-left1">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Nationality
            </label>
            <select
              value={passenger.nationality}
              onChange={(e) => {
                const nationality = e.target.value;
                const updatedPassenger = {
                  ...passenger,
                  nationality: nationality,
                };
                update(index, updatedPassenger);
              }}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="">Select</option>
              {countries.map(({ defaultName, nameCode }) => (
                <option
                  key={`name-${nameCode + Math.random()}`}
                  value={String(nameCode.toUpperCase())}
                >
                  {defaultName}
                </option>
              ))}
            </select>
          </div>
          {errorForForms && passenger.nationality === "" && (
            <p style={{ color: "red", margin: "4px" }}>
              {" "}
              Please select a Nationality
            </p>
          )}
        </div>
      )}

      {/* Add Frequent Flyer Number */}
      {/* <div className="accordion" id="accordionExample">
        <div className="card" key={index}>
          <div className="card-header" id={`heading${index}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeIndex === index ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <FontAwesomeIcon
                  icon={activeIndex === index ? faMinus : faPlus}
                />
                Add Frequent Flyer Number
              </button>
            </h2>
          </div>
          <div
            id={`collapse${index}`}
            className={`collapse ${activeIndex === index ? "show" : ""}`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="d-flex align-items-center gap-3">
                <div className="flight-text">{flightName}</div>

                <div className="input-group input-group-sm mb-0">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      {flightMCA}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="flightMCA"
                    id="flightMCA"
                    value=""
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* {passenger.nationality === "India" && type === "adult" && (
        <div className="col-sm-6 col-md-6 pb-4 checkout-col">
          <div className="card-content">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label label_text tooltip"
            >
              Pan Card
              <span
                style={{
                  padding: "0 8px",
                  margin: "10px",
                  border: "1px solid #e53d30",
                  borderRadius: "50%",
                  fontWeight: "700",
                  color: "#e53d30",
                  fontSize: "14px",
                }}
              >
                i
              </span>
              <span className="tooltiptext">
                RBI mandates collection of PAN for bookings covered under the
                LRS which includes expenditure on foreign travel. PAN will be
                fetched from your user profile, previous bookings or external
                partners. T&C
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Pan Card"
              value={passenger.panCard}
              onChange={(e) => {
                if (passenger.nationality === "India") {
                  const test = panCardRegex.test(e.target.value);
                  setErrorForForms(!test);
                }

                const updatedPassenger = {
                  ...passenger,
                  panCard: e.target.value,
                };
                update(index, updatedPassenger);
              }}
            />
          </div>
        </div>
      )} */}

      {checkNameChecklistSettings && isInputClicked !== "" && (
        <div
          style={{
            border: "1px solid #ff742f",
            borderRadius: "4px",
            marginBottom: "4px",
            fontSize: "small",
            color: "#f07d00",
            fontWeight: "500",
            marginTop: "-25px",
          }}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{ color: "#f07d00" }}
          />{" "}
          {isInputClicked === "firstName"
            ? checkNameChecklistSettings.Fnumessage
            : checkNameChecklistSettings.Lnumessage}
        </div>
      )}

      {type === "adult" && index === 0 && (
        <div
          style={{
            border: "1px solid #ff742f",
            borderRadius: "4px",
            marginBottom: "4px",
            fontSize: "small",
            color: "#f07d00",
            fontWeight: "500",
          }}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{ color: "#f07d00" }}
          />{" "}
          Passenger age is calculated as per the travel date
        </div>
      )}

      {index !== numberOfPassengers.length - 1 && <hr />}
    </div>
  );
};
