import { useEffect, useState } from "react";
import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import { CheapestFlightsList } from "../../components/forms/flight/CheapestFlightsList";
import { FastestFlightsList } from "../../components/forms/flight/FastestFlightList";
import { SearchFlight } from "../../components/forms/flight/SearchFlight";
import { SidebarFilter } from "../../components/forms/flight/SidebarFilter";
import {
  expressSearch,
  getTUItokenAfterExpressSearch,
} from "../../api/FlightAPI";
import { useFlightLoader } from "../../components/forms/shared/useFlightLoader";
import filterImage from "../../assets/images/filter.png";
// import loadedFlightIndication from "../../assets/images/loaded-flights.gif";
import arrowImage from "../../assets/images/sorting-arrows.png";
// import AirplaneLoader from "../../components/loaders/AirplaneLoader";
import moment from "moment";
import { toast } from "react-toastify";
import { formatFlightClass } from "../../utils/helpers";
import LazyImage from "../../pages/LazyImage";
import { HotelPlusFlight } from "../../components/forms/hotel-and-flight/HotelPlusFlight";
import useFilterFlights from "../../utils/useFilterFlight";
import { slabsList } from "../../api/AdminAPI";

export const FilterFlights = () => {
  const [flightType, setFlightType] = useState("cheapest");
  const [flightListHistory, setFlightLisHistory] = useState<any>([]);
  const [slabsListData, setSlabsListData] = useState<any>([]);
  const [startPolling, setStartPolling] = useState(false);
  const [flightIndication, setFlightIndication] = useState(false);

  const [checkIfTripsNotEmpty, setCheckIfTripsNotEmpty] = useState(true);

  //filter flight states
  const [priceRange, setPriceRange] = useState<number>(0);
  const [getHightestPrice, setGetHightestPrice] = useState<number>(0);

  const [upToFlights, setUpToFlights] = useState(getHightestPrice);
  const [minPriceRange, setMinPriceRange] = useState<number>(priceRange);
  const [price, setPrice] = useState("");

  const [toggleFLightPrice, setToggleFlightPrice] = useState(false);
  const [refundable, setRefundable] = useState(false);
  const [selectedArrivalTimes, setSelectedArrivalTimes] = useState<string[]>(
    []
  );
  const [selectedDepartureTimes, setSelectedDepartureTimes] = useState<
    string[]
  >([]);

  const [callStorage, setCallStorage] = useState(false);

  const [selectedStops, setSelectedStops] = useState<any>([]);
  const [selectedFlights, setSelectedFlights] = useState<string[]>([]);
  const [selectedArrivalAirport, setSelectedArrivalAirport] = useState<any[]>(
    []
  );

  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();

  const flightDataJSON = localStorage.getItem("userFlightSearchData");

  const getCurrentSelectedTab = localStorage.getItem("tabSearchData");

  const flightData = JSON.parse(String(flightDataJSON));

  function removeDuplicatesByFlightNo(arr: any[]) {
    const uniqueFlightNos = new Set();
    return arr.filter((item) => {
      if (!uniqueFlightNos.has(item.Index)) {
        uniqueFlightNos.add(item.Index);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    slabsList()
      .then((res) => {
        setSlabsListData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const flightDataJSON = localStorage.getItem("userFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    if (flightData) {
      setIsFlightLoading(true);
      const getFlights = async () => {
        await expressSearch({
          ADT: flightData.ADT,
          CHD: flightData.CHD,
          INF: flightData.INF,
          Cabin: String(formatFlightClass(flightData.Cabin)),
          Source: "LV",
          Mode: "AS",
          TUI: "",
          FareType: flightData.flightWay === "one way" ? "ON" : "RT",
          ClientID: "API19201",
          IsMultipleCarrier: false,
          IsRefundable: false,
          SecType: "D",
          YTH: 0,
          Trips: [
            {
              From: flightData.FromCode,
              To: flightData.ToCode,
              ReturnDate:
                flightData.ReturnDate !== "" &&
                flightData.flightWay !== "one way"
                  ? moment(flightData.ReturnDate).format("YYYY-MM-DD")
                  : "",
              OnwardDate:
                flightData.OnwardDate !== ""
                  ? moment(flightData.OnwardDate).format("YYYY-MM-DD")
                  : "",
            },
          ],
          Parameters: {
            Airlines: "",
            GroupType: "",
            Refundable: "",
            IsDirect: flightData.IsDirect,
            IsStudentFare: flightData.IsStudentFare,
            IsNearbyAirport: flightData.IsNearbyAirport,
            IsSeniorCitizen: flightData.IsSeniorCitizen,
          },
        })
          .then((response) => {
            setStartPolling(true);
            localStorage.setItem("exp-token", response.data.TUI);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              toastId: "forGeoLocation",
            });
          });
      };

      getFlights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pollApiUntilCondition = async () => {
    const tuiJSON = localStorage.getItem("exp-token");

    try {
      const response = await getTUItokenAfterExpressSearch(String(tuiJSON));

      if (response.data.Trips !== null) {
        setIsFlightLoading(true);

        setTimeout(() => {
          setIsFlightLoading(false);
        }, 2000);

        setFlightLisHistory((prevHistory: any) => [
          ...prevHistory,
          ...response.data.Trips[0]?.Journey,
        ]);
        // setIsFlightLoading(false);
        localStorage.setItem(
          "flightJourney",
          JSON.stringify({
            tUI: response.data.TUI,
            trips: [...flightListHistory],
          })
        );
      }

      if (response.data.Completed === "True") {
        setCheckIfTripsNotEmpty(false);
        setIsFlightLoading(false);
        setCallStorage(true);
        setFlightIndication(true);

        setTimeout(() => {
          setFlightIndication(false);
        }, 2000);
      } else {
        // Continue polling if the conditions are not met
        setTimeout(pollApiUntilCondition, 100);
      }
    } catch (err) {
      console.log("Error while polling:", err);
    }
  };

  useEffect(() => {
    if (startPolling && checkIfTripsNotEmpty) {
      pollApiUntilCondition();

      const timeout = setTimeout(() => {
        setIsFlightLoading(false);
        setCallStorage(true);
      }, 30000);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfTripsNotEmpty, startPolling]);

  useEffect(() => {
    const getLocalStorageData = localStorage.getItem("flightJourney");
    if (callStorage) {
      flightListHistory.length !== 0 &&
        localStorage.setItem(
          "flightJourney",
          JSON.stringify({
            tUI: JSON.parse(String(getLocalStorageData)).tUI,
            trips: flightListHistory,
          })
        );
    }

    return () => {
      setCallStorage(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStorage]);

  const airlines = [...removeDuplicatesByFlightNo(flightListHistory)];

  const allConnectingAirports = airlines.reduce((airports, flight) => {
    const connectingAirports = flight.Connections.map(
      (connection: { ArrAirportName: any }) =>
        connection.ArrAirportName?.split("|")[1]
    );

    return [...airports, ...connectingAirports];
  }, []);

  const uniqueConnectingAirportsSet = new Set(allConnectingAirports);

  const uniqueConnectingAirports = Array.from(uniqueConnectingAirportsSet);

  useEffect(() => {
    const getPriceMax = () => {
      removeDuplicatesByFlightNo(flightListHistory)?.filter(() => {
        const highestPrice = Math.max(
          ...removeDuplicatesByFlightNo(flightListHistory).map(
            // (flightl: any) => flightl.GrossFare
            (flightl: any) => flightl.NetFare
          )
        );
        const lowestPrice = Math.min(
          ...removeDuplicatesByFlightNo(flightListHistory).map(
            (flightl: any) => flightl.NetFare
          )
        );
        const lowestPriceMin = Math.min(
          ...removeDuplicatesByFlightNo(flightListHistory).map(
            (flightl: any) => flightl.NetFare
          )
        );
        const priceRangeMax = highestPrice;
        setPriceRange(lowestPrice);
        // setMinPriceRange(lowestPriceMin);
        setUpToFlights(priceRangeMax);
        setGetHightestPrice(priceRangeMax);

        return priceRangeMax;
      });
    };
    getPriceMax();
  }, [flightListHistory]);

  const filteredFlightsList = useFilterFlights({
    flightList: flightListHistory,
    toggleFLightPrice,
    priceRange,
    getHightestPrice,
    selectedStops,
    selectedFlights,
    selectedDepartureTimes,
    selectedArrivalTimes,
    selectedArrivalAirport,
    refundable,
  });

  // const filteredFlightsList = filterFlights(flightListHistory);

  useEffect(() => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  }, [filteredFlightsList]);

  // Cheapest flight sorting :
  const sortFlightsByFare = (flights: any[]) => {
    const nonZeroFareFlights = flights.filter((flight) => flight.NetFare !== 0);

    // Sort the filtered flights by fare
    const sortedFlights = nonZeroFareFlights
      .slice()
      .sort((a: { NetFare: number }, b: { NetFare: number }) => {
        return a.NetFare - b.NetFare;
      });

    return sortedFlights;
  };

  const matchingFlights = filteredFlightsList?.filter(
    (flight: { Duration: string }) => {
      return flight?.Duration;
    }
  );

  const sortedFlightsCheapest = sortFlightsByFare(matchingFlights);

  console.log(sortedFlightsCheapest);

  // Fastest flights
  const sortFlightsByDuration = (flights: any[]) => {
    return flights
      .slice()
      .sort((a: { Duration: string }, b: { Duration: string }) => {
        const parseDuration = (duration: string) => {
          const parts = duration.split(" ");
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          return hours * 60 + minutes;
        };

        const durationA = parseDuration(a?.Duration);
        const durationB = parseDuration(b?.Duration);

        return durationA - durationB;
      });
  };

  const handleNoOfStopsChange = (stop: any) => {
    if (selectedStops.includes(stop)) {
      setSelectedStops(selectedStops.filter((s: any) => s !== stop));
    } else {
      setSelectedStops([...selectedStops, stop]);
    }
  };

  const handleArrivalTimeChange = (time: any) => {
    if (selectedArrivalTimes.includes(time)) {
      setSelectedArrivalTimes((prevTimes) =>
        prevTimes.filter((selectedTime) => selectedTime !== time)
      );
    } else {
      setSelectedArrivalTimes((prevTimes) => [...prevTimes, time]);
    }
  };

  const handleDepartureTimeChange = (value: string) => {
    if (selectedDepartureTimes.includes(value)) {
      setSelectedDepartureTimes(
        selectedDepartureTimes.filter((time) => time !== value)
      );
    } else {
      setSelectedDepartureTimes([...selectedDepartureTimes, value]);
    }
  };

  const fastestFlights = filteredFlightsList?.filter(
    (flight: { Duration: string }) => {
      return flight?.Duration;
    }
  );

  const sortedFlightsFastest = sortFlightsByDuration(fastestFlights);

  const sortedFlightByDeparture = filteredFlightsList
    .slice()
    .sort(
      (
        a: { DepartureTime: string | number | Date },
        b: { DepartureTime: string | number | Date }
      ) => {
        const departureTimeA: any = new Date(a.DepartureTime);
        const departureTimeB: any = new Date(b.DepartureTime);
        return departureTimeA - departureTimeB;
      }
    );

  const sortedFlightByArrival = filteredFlightsList
    .slice()
    .sort(
      (
        a: { ArrivalTime: string | number | Date },
        b: { ArrivalTime: string | number | Date }
      ) => {
        const arrivalTimeA: any = new Date(a.ArrivalTime);
        const arrivalTimeB: any = new Date(b.ArrivalTime);
        return arrivalTimeA - arrivalTimeB;
      }
    );

  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  const updateFlight = (flight: {
    NetFare: any;
    CommissionRate: any;
    AddonRate: any;
    SellingPrice: any;
    MarkedPrice: any;
    Duration: any;
    Discount: any;
    DiscountAmount: any;
  }) => {
    const netFare = flight.NetFare;
    const slab = findSlab(netFare);

    const markedPriceRate =
      flight.NetFare + flight.NetFare * (slab?.addonRate / 100);
    const sellingPriceRate =
      flight.NetFare + flight.NetFare * (slab?.commissionRate / 100);

    if (slab) {
      flight.CommissionRate = slab?.commissionRate;
      flight.AddonRate = slab?.addonRate;
      flight.MarkedPrice = Math.round(markedPriceRate);
      flight.SellingPrice = Math.round(sellingPriceRate + 1);

      flight.Discount = Math.round(
        ((flight.MarkedPrice - flight.SellingPrice) / flight.MarkedPrice) * 100
      );
      flight.DiscountAmount = Math.round(
        flight.MarkedPrice - flight.SellingPrice
      );
    }

    return flight;
  };

  const updatedFlightsCheapest = sortedFlightsCheapest.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  useEffect(() => {
    const cheapestFlight = updatedFlightsCheapest[0]?.SellingPrice
      ? updatedFlightsCheapest[0]
      : sortedFlightsCheapest[0];

    const formattedPrice = cheapestFlight?.SellingPrice
      ? cheapestFlight.SellingPrice.toLocaleString()
      : cheapestFlight?.NetFare.toLocaleString();

    setMinPriceRange(formattedPrice);
  }, [updatedFlightsCheapest, sortedFlightsCheapest]);

  const updatedFlightsFastest = sortedFlightsFastest.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  const updatedFlightsDeparture = sortedFlightByDeparture.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  const updatedFlightsArrival = sortedFlightByArrival.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  return (
    <AppLayout>
      <main className="container">
        {getCurrentSelectedTab === "hotelFlight" ? (
          <div className="tabbing-bar res_tabbing">
            <div className="tab-content">
              <HotelPlusFlight />
            </div>
          </div>
        ) : (
          <div className="tabbing-bar res_tabbing">
            <div className="tab-content">
              <SearchFlight />
            </div>
          </div>
        )}

        {flightListHistory.length !== 0 && (
          <>
            <div className="search-sticky">
              <div
                className="select-container res-hide-flight-tap"
                style={{ justifyContent: "flex-end" }}
              >
                <div
                  onClick={() => setFlightType("cheapest")}
                  className={
                    flightType === "cheapest"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Cheapest</h5>
                    <span className="card_text">
                      {updatedFlightsCheapest[0]?.SellingPrice
                        ? updatedFlightsCheapest[0]?.SellingPrice.toLocaleString()
                        : sortedFlightsCheapest[0]?.NetFare.toLocaleString()}{" "}
                      ・ Duration: {sortedFlightsCheapest[0]?.Duration}
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => setFlightType("fastest")}
                  className={
                    flightType === "fastest"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Fastest</h5>
                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsFastest[0]?.SellingPrice
                        ? updatedFlightsFastest[0]?.SellingPrice.toLocaleString()
                        : sortedFlightsFastest[0]?.NetFare.toLocaleString()}{" "}
                      ・ Duration: {sortedFlightsFastest[0]?.Duration}
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => setFlightType("departure")}
                  className={
                    flightType === "departure"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Departure</h5>
                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsDeparture[0]?.SellingPrice
                        ? updatedFlightsDeparture[0]?.SellingPrice.toLocaleString()
                        : sortedFlightByDeparture[0]?.NetFare.toLocaleString()}{" "}
                      ・ Duration: {sortedFlightByDeparture[0]?.Duration}
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => setFlightType("arrival")}
                  className={
                    flightType === "arrival"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Arrival</h5>
                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsArrival[0]?.SellingPrice
                        ? updatedFlightsArrival[0]?.SellingPrice.toLocaleString()
                        : sortedFlightByArrival[0]?.NetFare.toLocaleString()}{" "}
                      ・ Duration: {sortedFlightByArrival[0]?.Duration}
                    </span>
                  </div>
                </div>
              </div>

              <div className="res-show-flight-tap">
                <div className="d-flex justify-content-between align-content-center">
                  <div className="left-filter">
                    <h5
                      className="h3-bold mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <span>
                        <LazyImage
                          className="img-fluid"
                          src={arrowImage}
                          alt=""
                        />{" "}
                      </span>
                      Sort
                    </h5>
                  </div>
                  <div className="right-filter">
                    <h5
                      className="h3-bold mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#flight-exampleModal_flight_search"
                    >
                      <span>
                        <LazyImage
                          className="img-fluid"
                          src={filterImage}
                          alt=""
                        />{" "}
                      </span>
                      Filter
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="modal modal-bottom fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal_bottom">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="h3 bold">Sort</h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={flightType === "cheapest"}
                    onChange={() => setFlightType("cheapest")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Cheapest
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={flightType === "fastest"}
                    onChange={() => setFlightType("fastest")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Fastest
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault4"
                    checked={flightType === "departure"}
                    onChange={() => setFlightType("departure")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault4"
                  >
                    Departure
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                    checked={flightType === "arrival"}
                    onChange={() => setFlightType("arrival")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3"
                  >
                    Arrival
                  </label>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-login search_record"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-login search_record"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h1 className="h1-inner">
              Flights from {flightData?.fromPlace} to {flightData?.toPlace}
            </h1>
          </div>
        </div>

        <div className="row">
          {removeDuplicatesByFlightNo(flightListHistory).length === 0 &&
          isFlightLoading ? (
            <div style={{ display: "none" }}>{/* <AirplaneLoader /> */}</div>
          ) : removeDuplicatesByFlightNo(flightListHistory).length === 0 ? (
            <div style={{ textAlign: "center", fontSize: "27px" }}>
              No Data Found.
            </div>
          ) : (
            <>
              <SidebarFilter
                priceRange={priceRange}
                selectedFlights={selectedFlights}
                selectedArrivalAirport={selectedArrivalAirport}
                setPriceRange={setPriceRange}
                handleArrivalTimeChange={handleArrivalTimeChange}
                selectedArrivalTimes={selectedArrivalTimes}
                setSelectedFlights={setSelectedFlights}
                setSelectedArrivalAirport={setSelectedArrivalAirport}
                uniqueConnectingAirports={uniqueConnectingAirports}
                getHightestPrice={getHightestPrice as number}
                setToggleFlightPrice={setToggleFlightPrice}
                refundable={refundable}
                setRefundable={setRefundable}
                handleNoOfStopsChange={handleNoOfStopsChange}
                selectedStops={selectedStops}
                handleDepartureTimeChange={handleDepartureTimeChange}
                selectedDepartureTimes={selectedDepartureTimes}
                flightList={flightListHistory}
                upToFlights={upToFlights}
                setUpToFlights={setUpToFlights}
                minPriceRange={minPriceRange}
                oneWay={true}
              />

              {/* Flight list */}
              <div className="col-sm-9">
                {/* Cheapest Flights */}
                {flightType === "cheapest" &&
                  (filteredFlightsList.length === 0 ? (
                    <h1>No Data Found</h1>
                  ) : (
                    <>
                      {flightType === "cheapest" && (
                        <CheapestFlightsList
                          key={Math.random()}
                          cheapestFlightList={filteredFlightsList}
                          slabsListData={slabsListData}
                        />
                      )}
                    </>
                  ))}

                {/* Fastest Flights */}
                {flightType === "fastest" &&
                  (filteredFlightsList.length === 0 ? (
                    <h1>No Data Found</h1>
                  ) : (
                    <>
                      {flightType === "fastest" && (
                        <FastestFlightsList
                          fastestFlightList={filteredFlightsList}
                          slabsListData={slabsListData}
                        />
                      )}
                    </>
                  ))}

                {flightType === "departure" &&
                  (filteredFlightsList.length === 0 ? (
                    <h1>No Data Found</h1>
                  ) : (
                    <>
                      {flightType === "departure" && (
                        <CheapestFlightsList
                          key={Math.random()}
                          cheapestFlightList={filteredFlightsList}
                          tag="departure"
                          slabsListData={slabsListData}
                        />
                      )}
                    </>
                  ))}

                {flightType === "arrival" &&
                  (filteredFlightsList.length === 0 ? (
                    <h1>No Data Found</h1>
                  ) : (
                    <>
                      {flightType === "arrival" && (
                        <CheapestFlightsList
                          key={Math.random()}
                          cheapestFlightList={filteredFlightsList}
                          tag="arrival"
                          slabsListData={slabsListData}
                        />
                      )}
                    </>
                  ))}
              </div>
            </>
          )}
        </div>
      </main>
      {flightIndication && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 17%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <span style={{ fontSize: "26px", color: "red" }}>
            {filteredFlightsList.length + " results"}
          </span>
        </div>
      )}

      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
