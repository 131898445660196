import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
const Notes: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        padding: "0px 10px",
      }}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "orange",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "10px",
          borderRadius: "10px",
        }}
      >
        <FontAwesomeIcon
          icon={faBullhorn}
          style={{ color: "#000", fontSize: "24px" }}
        />
      </div>
      <p style={{ fontSize: "15px", marginTop: "14px" }}>
        NOTE: We may have included nearby airports as well in your search
        results. Kindly select your flight and airport as per preference.
      </p>
    </div>
  );
};
export default Notes;
