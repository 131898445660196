import { useEffect, useState } from "react";
import signOutImg from "../../../assets/images/SignOut.png";
import loginSVG from "../../../assets/images/signin.svg";
import LazyImage from "../../../pages/LazyImage";
import { AddonsProvider, useAddons } from "./addonccontext";

interface SideBarRoundTripCheckoutProps {
  getObjRules: any;
  flightSearchDetails: any;
  phoneValue: string;
  emailValue: string;
  slabsListData: any;
  flightData: any;
  sellingPrice: any;
  setSellingPrice: any;
  setGuestLoginIndication: (val: boolean) => void;
}

export const MultiCitySideBarCheckout = ({
  getObjRules,
  flightSearchDetails,
  phoneValue,
  emailValue,
  slabsListData,
  flightData,
  sellingPrice,
  setSellingPrice,
  setGuestLoginIndication,
}: SideBarRoundTripCheckoutProps) => {
  const [showHideBase, setShowHideBase] = useState("");
  const [markedPrice, setMarkedPrice] = useState(0);
  const [storedAddonPercent, setStoredAddonPercent] = useState(0);

  const storedToken = localStorage.getItem("token");

  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  useEffect(() => {
    const flight = flightData;

    if (flight) {
      let amount = 0;
      for (let i = 0; i <= flightData?.length; i++) {
        if (flightData[i] !== undefined) {
          amount += flightData[i]?.NetAmount;
        }
      }

      const netFare = amount;
      const slab = findSlab(netFare);

      const markedPriceRate = amount + amount * (slab?.addonRate / 100);
      const sellingPriceRate = amount + amount * (slab?.commissionRate / 100);

      if (slab) {
        flight.CommissionRate = slab?.commissionRate;
        flight.AddonRate = slab?.addonRate;
        flight.MarkedPrice = Math.round(markedPriceRate);
        flight.SellingPrice = Math.round(sellingPriceRate + 1);

        flight.Discount = Math.round(
          ((flight.MarkedPrice - flight.SellingPrice) / flight.MarkedPrice) *
            100
        );
        flight.DiscountAmount = Math.round(
          flight.MarkedPrice - flight.SellingPrice
        );
      }

      if (flight?.SellingPrice || flight?.MarkedPrice) {
        setMarkedPrice(flight?.MarkedPrice);
        setSellingPrice(flight?.SellingPrice);
        setStoredAddonPercent(amount * (flight?.AddonRate / 100));
      } else {
        setMarkedPrice(0);
        setSellingPrice(0);
        setStoredAddonPercent(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightData]);

  const flightObj =
    getObjRules &&
    getObjRules.map(
      (rules: any) => rules?.Trips[0]?.Journey[0]?.Segments[0]?.Fares?.PTCFare
    );

  const flightObjForCommission =
    getObjRules &&
    getObjRules
      .map((rules: any) => rules?.Trips[0]?.Journey[0]?.Segments[0]?.Fares)
      .flat();

  const combinedArray = flightObj.flat().reduce(
    (
      accumulator: any[],
      currentObj: {
        PTC: any;
        Fare: any;
        ST: any;
        YQ: any;
        UD: any;
        YR: any;
        API: any;
        PSF: any;
        K3: any;
        OT: any;
        VATonServiceCharge: any;
      }
    ) => {
      const index = accumulator.findIndex(
        (item) => item.PTC === currentObj.PTC
      );

      if (index !== -1) {
        accumulator[index].Fare += currentObj.Fare;
        accumulator[index].ST += currentObj.ST;
        accumulator[index].YQ += currentObj.YQ;
        accumulator[index].UD += currentObj.UD;
        accumulator[index].YR += currentObj.YR;
        accumulator[index].API += currentObj.API;
        accumulator[index].PSF += currentObj.PSF;
        accumulator[index].K3 += currentObj.K3;
        accumulator[index].VATonServiceCharge += currentObj.VATonServiceCharge;
        accumulator[index].OT += `, ${currentObj.OT}`;
      } else {
        accumulator.push({
          ...currentObj,
        });
      }
      return accumulator;
    },
    []
  );

  const ptcFares = combinedArray || [];

  const adultCount = flightSearchDetails.ADT || 0;
  const childCount = flightSearchDetails.CHD || 0;
  const infantCount = flightSearchDetails.INF || 0;

  // Initialize tax totals for each passenger type
  const adultTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const childTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };
  const infantTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  // Calculate taxes for each passenger type
  ptcFares?.forEach(
    (ptc: {
      PTC: any;
      OT: string;
      ST: string;
      YQ: string;
      YR: string;
      UD: string;
      API: string;
      PSF: string;
      K3: string;
      VATonServiceCharge: string;
    }) => {
      const ptcType = ptc.PTC;

      // Calculate taxes based on passenger type
      switch (ptcType) {
        case "ADT":
          const otValues =
            typeof ptc?.OT === "string"
              ? ptc?.OT.split(",").map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSum = otValues?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );

          adultTaxTotals.AirlineMisc +=
            (otSum + parseFloat(ptc.ST)) * adultCount;
          adultTaxTotals.FuelTax += parseFloat(ptc.YQ) * adultCount;
          adultTaxTotals.CongestionFee += parseFloat(ptc.YR) * adultCount;
          adultTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * adultCount;
          adultTaxTotals.APITax += parseFloat(ptc.API) * adultCount;
          adultTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * adultCount;
          adultTaxTotals.GST += parseFloat(ptc.K3) * adultCount;
          adultTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * adultCount;
          break;
        case "CHD":
          const otValuesChild =
            typeof ptc?.OT === "string"
              ? ptc?.OT.split(",").map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumChild = otValuesChild?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          childTaxTotals.AirlineMisc +=
            (otSumChild + parseFloat(ptc.ST)) * childCount;
          childTaxTotals.FuelTax += parseFloat(ptc.YQ) * childCount;
          childTaxTotals.CongestionFee += parseFloat(ptc.YR) * childCount;
          childTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * childCount;
          childTaxTotals.APITax += parseFloat(ptc.API) * childCount;
          childTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * childCount;
          childTaxTotals.GST += parseFloat(ptc.K3) * childCount;
          childTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * childCount;
          break;
        case "INF":
          const otValuesInfant =
            typeof ptc?.OT === "string"
              ? ptc?.OT.split(",").map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumInfant = otValuesInfant?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          infantTaxTotals.AirlineMisc +=
            (otSumInfant + parseFloat(ptc.ST)) * infantCount;
          infantTaxTotals.FuelTax += parseFloat(ptc.YQ) * infantCount;
          infantTaxTotals.CongestionFee += parseFloat(ptc.YR) * infantCount;
          infantTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * infantCount;
          infantTaxTotals.APITax += parseFloat(ptc.API) * infantCount;
          infantTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * infantCount;
          infantTaxTotals.GST += parseFloat(ptc.K3) * infantCount;
          infantTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * infantCount;
          break;
        default:
      }
    }
  );

  const taxTotals: any = {
    AirlineMisc: 0 + storedAddonPercent ? Math.round(storedAddonPercent) : 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  function calculateFare(tax: any, passengerCount: number) {
    const otValues =
      typeof tax.OT === "string"
        ? tax.OT.split(",").map(
            (value: string) => parseFloat(value.trim()) || 0
          )
        : [];

    const otSum = otValues?.reduce(
      (acc: number, currentValue: number) => acc + currentValue,
      0
    );

    return {
      AirlineMisc: Math.round(otSum + tax.ST) * passengerCount,
      FuelTax: tax.YQ * passengerCount || 0,
      CongestionFee: parseFloat(tax.YR) * passengerCount || 0,
      UserDevelopmentFee: parseFloat(tax.UD) * passengerCount || 0,
      APITax: parseFloat(tax.API) * passengerCount || 0,
      PassengerServiceTax: parseFloat(tax.PSF) * passengerCount || 0,
      GST: parseFloat(tax.K3) * passengerCount || 0,
      VATonServiceCharge:
        parseFloat(tax.VATonServiceCharge) * passengerCount || 0,
    };
  }

  ptcFares?.forEach((ptc: any) => {
    const ptcType = ptc.PTC;
    const passengerCount =
      ptcType === "ADT"
        ? adultCount
        : ptcType === "CHD"
        ? childCount
        : infantCount;

    const fareDetails: any = calculateFare(ptc, passengerCount);

    for (const key in taxTotals) {
      taxTotals[key] += fareDetails[key];
    }
  });

  const totalTaxAmount = Object.values(taxTotals)?.reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  if (typeof totalTaxAmount === "number") {
    localStorage.setItem("totalBaseFareMultiCity", totalTaxAmount.toString());
  } else {
    console.error("Invalid totalBaseFareMultiCity value.");
  }

  const totalFares = combinedArray.map((fare: any) => {
    if (fare.PTC === "ADT") {
      return flightSearchDetails.ADT * fare.Fare;
    } else if (fare.PTC === "CHD") {
      return flightSearchDetails.CHD * fare.Fare;
    } else if (fare.PTC === "INF") {
      return flightSearchDetails.INF * fare.Fare;
    } else {
      return 0;
    }
  });

  const commissionArray = flightObjForCommission.map((fare: any) => {
    let commission = 0;

    if (fare.PTCFare[0].PTC === "ADT") {
      commission = flightSearchDetails.ADT * (fare.TotalCommission || 0);
    } else if (fare.PTCFare[0].PTC === "CHD") {
      commission = flightSearchDetails.CHD * (fare.TotalCommission || 0);
    } else if (fare.PTCFare[0].PTC === "INF") {
      commission = flightSearchDetails.INF * (fare.TotalCommission || 0);
    }

    return commission;
  });

  const totalCommission = commissionArray.reduce(
    (total: any, commission: any) => total + commission,
    0
  );

  const totalBaseFare = totalFares.reduce(
    (acc: any, currentFare: any) => acc + currentFare,
    0
  );

  const discountCalc = markedPrice - sellingPrice;

  const discountRate = markedPrice
    ? Math.round(discountCalc + totalCommission * 0.95)
    : Math.round(totalCommission * 0.95);

  localStorage.setItem("discountCalcRoundMulti", discountRate.toString());

  // addons
  const {
    addonDetailsData,
    setAddonDetailsData,
    mealAddonDetailsData,
    setMealAddonDetailsData,
    seatAddonDetailsData,
    setSeatAddonDetailsData,
    finalAmount,
    setFinalAmount,
    totalValue,
    setTotalValue,
    totalAmount,
    setTotalAmount,
    totalMealValue,
    setTotalMealValue,
    totalSeatValue,
    setTotalSeatValue,
    totalSportBaggageValue,
    setTotalSportBaggageValue,
    totalPriorityBaggageValue,
    setTotalPriorityBaggageValue,
    totalSSRAmount,
    setTotalSSRAmount,
  } = useAddons();

  const BaggageAmount = totalValue;
  console.log("round trip Total Baggage Amount", BaggageAmount);
  const showBaggageAmount = BaggageAmount.some((amount) => amount > 0);

  const MealAmount = totalMealValue;
  console.log("round trip  Total Meal Amount", MealAmount);
  const showMealAmount = MealAmount.some((amountmeal) => amountmeal > 0);

  const SeatAmount = totalSeatValue;
  console.log("round trip  Total Seat Amount", SeatAmount);
  const showSeatAmount = SeatAmount.some((amountseat) => amountseat > 0);

  const SportAmount = totalSportBaggageValue;
  console.log("round trip  Total Sport Amount", SportAmount);
  const showSportAmount = SportAmount.some((amountsport) => amountsport > 0);

  const PriorityAmount = totalPriorityBaggageValue;
  console.log("round trip  Total Priority Amount", PriorityAmount);
  const showPriorityAmount = PriorityAmount.some(
    (amountpriority) => amountpriority > 0
  );
  //const totalValued = getaddonDetailsData + getmealAddonDetailsData + getseatAddonDetailsData;

  const getaddonDetailsData = addonDetailsData[0]?.charge || 0;
  const getmealAddonDetailsData = mealAddonDetailsData[0]?.charge || 0;
  const getseatAddonDetailsData = seatAddonDetailsData[0]?.Fare || 0;

  const totalValued =
    getaddonDetailsData +
    +getmealAddonDetailsData +
    +getseatAddonDetailsData +
    +totalValue +
    +totalMealValue +
    +SeatAmount +
    +totalSportBaggageValue +
    +totalPriorityBaggageValue;

  // Set the totalSSRAmount state variable
  setTotalSSRAmount(totalValued);

  // Now you can log the values
  console.log("totalValue for ssr amount", totalValue);
  console.log("setTotalSSRAmount", totalSSRAmount);

  //const totalFare = totalBaseFare + Number(totalTaxAmount) - discountRate + getaddonDetailsData;
  const totalFare =
    totalBaseFare +
    Number(totalTaxAmount) -
    discountRate +
    getaddonDetailsData +
    +getmealAddonDetailsData +
    +getseatAddonDetailsData +
    +BaggageAmount +
    +MealAmount +
    +SeatAmount +
    +SportAmount +
    +PriorityAmount;
  setFinalAmount(totalFare);
  console.log("finalAmount", totalFare);

  return (
    <div className="col-sm-4 sticky-top">
      <div className="confirmation-box">
        <div className="confirm-box">
          <h3 className="h3-bold">Fare Summary</h3>

          <div className="block block_right">
            <ul>
              <li className="py-1">
                <div
                  className="form-check px-0"
                  onClick={() =>
                    setShowHideBase(showHideBase === "base1" ? "" : "base1")
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex">
                    <span className="hading_check">
                      {showHideBase === "base1" ? (
                        <LazyImage
                          className="image_arrow active"
                          width={18}
                          height={18}
                          src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                          alt="collapse-arrow"
                        />
                      ) : (
                        <LazyImage
                          className="image_arrow"
                          id="image_first"
                          width={18}
                          height={18}
                          src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                          alt="collapse-arrow"
                        />
                      )}
                      &nbsp;Base Fare{" "}
                    </span>
                  </div>
                </div>
                <span className="hading_check">
                  ₹{Number(totalBaseFare)?.toLocaleString("en-IN")}
                </span>
              </li>
              <li
                className="py-1 listing_item"
                style={{ display: showHideBase === "base1" ? "block" : "none" }}
              >
                {combinedArray.map((fare: any, index: number) => (
                  <div
                    className="form-check px-0"
                    key={`sidebar-round-checkout-${index}`}
                  >
                    <div className="d-flex" style={{ marginRight: "auto" }}>
                      {fare.PTC === "ADT"
                        ? "Adult"
                        : fare.PTC === "CHD"
                        ? "Child"
                        : "Infant"}{" "}
                      (
                      {fare.PTC === "ADT"
                        ? flightSearchDetails.ADT
                        : fare.PTC === "CHD"
                        ? flightSearchDetails.CHD
                        : flightSearchDetails.INF}{" "}
                      X ₹ {fare.Fare})
                    </div>
                    <span style={{ marginLeft: "auto" }}>
                      ₹{" "}
                      {fare.PTC === "ADT"
                        ? flightSearchDetails.ADT * fare.Fare
                        : fare.PTC === "CHD"
                        ? flightSearchDetails.CHD * fare.Fare
                        : flightSearchDetails.INF * fare.Fare}
                    </span>
                  </div>
                ))}
              </li>
            </ul>
          </div>
          <hr />
          <div className="block block_right">
            <ul>
              <li className="py-1">
                <div
                  className="form-check px-0"
                  onClick={() =>
                    setShowHideBase(showHideBase === "base2" ? "" : "base2")
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex">
                    <span className="hading_check">
                      {" "}
                      {showHideBase === "base2" ? (
                        <LazyImage
                          className="image_arrow active"
                          width={18}
                          height={18}
                          src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                          alt="collapse-arrow"
                        />
                      ) : (
                        <LazyImage
                          className="image_arrow"
                          id="image_first"
                          width={18}
                          height={18}
                          src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                          alt="collapse-arrow"
                        />
                      )}
                      &nbsp;Tax & Charges{" "}
                    </span>
                  </div>
                </div>
                <span className="hading_check">
                  ₹{Number(totalTaxAmount)?.toLocaleString("en-IN")}
                </span>
              </li>

              {showHideBase === "base2" && (
                <>
                  {Object.keys(taxTotals)?.map((taxType: any) => (
                    <>
                      {taxTotals[taxType] > 0 && (
                        <li className="py-1" key={taxType}>
                          <div className="form-check px-0">
                            <div className="d-flex">{taxType}</div>
                          </div>
                          <span className="">₹ {taxTotals[taxType]}</span>
                        </li>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
          </div>

          {discountRate > 0 && (
            <>
              <hr />

              <div className="block block_right">
                <ul>
                  <li className="py-1">
                    <div
                      className="form-check px-0"
                      onClick={() =>
                        setShowHideBase(
                          showHideBase === "discount" ? "" : "discount"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex">
                        <span className="hading_check">
                          {" "}
                          {showHideBase === "discount" ? (
                            <LazyImage
                              className="image_arrow active"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          ) : (
                            <LazyImage
                              className="image_arrow"
                              id="image_first"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          )}
                          &nbsp;Discount{" "}
                        </span>
                      </div>
                    </div>
                    <span className="hading_check">
                      ₹{Number(discountRate)?.toLocaleString("en-IN")}
                    </span>
                  </li>

                  {showHideBase === "discount" && (
                    <li className="py-1">
                      <div className="form-check px-0">
                        <div className="d-flex">Discount</div>
                      </div>
                      <span className="">₹ {discountRate}</span>
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}
          <hr />
          {showBaggageAmount === true ? (
            <>
              <div className="block block_right">
                <ul>
                  <li className="py-1">
                    <div
                      className="form-check px-0"
                      onClick={() =>
                        setShowHideBase(
                          showHideBase === "BaggageAmount"
                            ? ""
                            : "BaggageAmount"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex">
                        <span className="hading_check">
                          {" "}
                          {showHideBase === "BaggageAmount" ? (
                            <LazyImage
                              className="image_arrow active"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          ) : (
                            <LazyImage
                              className="image_arrow"
                              id="image_first"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          )}
                          &nbsp;Baggage
                        </span>
                      </div>
                    </div>
                    <span className="hading_check">₹ {BaggageAmount}</span>
                  </li>
                  {showHideBase === "BaggageAmount" && (
                    <>
                      <li className="py-1 listing_item">
                        <div className="form-check px-0">
                          <div className="d-flex">Baggage</div>
                        </div>
                        <span className="">
                          ₹ {BaggageAmount}
                          {/* ₹ {BaggageAmount?.toLocaleString("en-IN")} */}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <hr />
            </>
          ) : null}

          {showMealAmount === true ? (
            <>
              <div className="block block_right">
                <ul>
                  <li className="py-1">
                    <div
                      className="form-check px-0"
                      onClick={() =>
                        setShowHideBase(
                          showHideBase === "showMealAmount"
                            ? ""
                            : "showMealAmount"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex">
                        <span className="hading_check">
                          {" "}
                          {showHideBase === "showMealAmount" ? (
                            <LazyImage
                              className="image_arrow active"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          ) : (
                            <LazyImage
                              className="image_arrow"
                              id="image_first"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          )}
                          &nbsp;Meals
                        </span>
                      </div>
                    </div>
                    <span className="hading_check">₹ {MealAmount}</span>
                  </li>
                  {showHideBase === "showMealAmount" && (
                    <>
                      <li className="py-1 listing_item">
                        <div className="form-check px-0">
                          <div className="d-flex">Meals</div>
                        </div>
                        <span className="">
                          {/* ₹ {MealAmount?.toLocaleString("en-IN")} */}₹{" "}
                          {MealAmount}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <hr />
            </>
          ) : null}

          {showSeatAmount === true ? (
            <>
              <div className="block block_right">
                <ul>
                  <li className="py-1">
                    <div
                      className="form-check px-0"
                      onClick={() =>
                        setShowHideBase(
                          showHideBase === "showSeatAmount"
                            ? ""
                            : "showSeatAmount"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex">
                        <span className="hading_check">
                          {" "}
                          {showHideBase === "showSeatAmount" ? (
                            <LazyImage
                              className="image_arrow active"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          ) : (
                            <LazyImage
                              className="image_arrow"
                              id="image_first"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          )}
                          &nbsp;Seats
                        </span>
                      </div>
                    </div>
                    <span className="hading_check">₹ {SeatAmount}</span>
                  </li>
                  {showHideBase === "showSeatAmount" && (
                    <>
                      <li className="py-1 listing_item">
                        <div className="form-check px-0">
                          <div className="d-flex">Seats</div>
                        </div>
                        <span className="">
                          {/* ₹ {SeatAmount?.toLocaleString("en-IN")} */}₹{" "}
                          {SeatAmount}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <hr />
            </>
          ) : null}
          {showSportAmount === true ? (
            <>
              <div className="block block_right">
                <ul>
                  <li className="py-1">
                    <div
                      className="form-check px-0"
                      onClick={() =>
                        setShowHideBase(
                          showHideBase === "showSportAmount"
                            ? ""
                            : "showSportAmount"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex">
                        <span className="hading_check">
                          {" "}
                          {showHideBase === "showSeatAmount" ? (
                            <LazyImage
                              className="image_arrow active"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          ) : (
                            <LazyImage
                              className="image_arrow"
                              id="image_first"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          )}
                          &nbsp;Sports Equip
                        </span>
                      </div>
                    </div>
                    <span className="hading_check">₹ {SportAmount}</span>
                  </li>
                  {showHideBase === "showSportAmount" && (
                    <>
                      <li className="py-1 listing_item">
                        <div className="form-check px-0">
                          <div className="d-flex">Sports Equip</div>
                        </div>
                        <span className="">
                          {/* ₹ {SportAmount?.toLocaleString("en-IN")} */}₹{" "}
                          {SportAmount}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <hr />
            </>
          ) : null}

          {showPriorityAmount === true ? (
            <>
              <div className="block block_right">
                <ul>
                  <li className="py-1">
                    <div
                      className="form-check px-0"
                      onClick={() =>
                        setShowHideBase(
                          showHideBase === "showPriorityAmount"
                            ? ""
                            : "showPriorityAmount"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex">
                        <span className="hading_check">
                          {" "}
                          {showHideBase === "showPriorityAmount" ? (
                            <LazyImage
                              className="image_arrow active"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          ) : (
                            <LazyImage
                              className="image_arrow"
                              id="image_first"
                              width={18}
                              height={18}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          )}
                          &nbsp;Priority Checking
                        </span>
                      </div>
                    </div>
                    <span className="hading_check">₹ {PriorityAmount}</span>
                  </li>
                  {showHideBase === "showPriorityAmount" && (
                    <>
                      <li className="py-1 listing_item">
                        <div className="form-check px-0">
                          <div className="d-flex">Priority Checking</div>
                        </div>
                        <span className="">
                          {/* ₹ {PriorityAmount?.toLocaleString("en-IN")} */}₹{" "}
                          {PriorityAmount}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <hr />
            </>
          ) : null}

          <div className="block">
            <ul>
              <li className="py-1">
                <div className="form-check px-0">
                  <div className="d-flex">
                    <span className="hading_check">Total Amount: </span>
                  </div>
                </div>
                <span className="hading_check">
                  ₹ {totalFare?.toLocaleString("en-IN")}
                </span>
              </li>
            </ul>
            <div style={{ color: "#6e6e6e", fontSize: "14px" }}>
              Total (includes taxes, charges and fees)
            </div>
          </div>
        </div>

        {/* Additional section */}
        {!storedToken && (
          <div
            className="login_section res_section"
            style={{ display: "block" }}
          >
            <h3 className="h3-bold">
              <span>
                <LazyImage className="img-fluid" src={signOutImg} alt="" />
              </span>
              Log-in to your Maashree account
            </h3>
            <div className="block">
              <button
                className="btn btn-login btn_login login_checkout"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalLogin"
              >
                {" "}
                <LazyImage src={loginSVG} alt="" />
                &nbsp; Log in
              </button>
              {!(emailValue !== "" && phoneValue !== "") && (
                <button
                  className="btn btn-login btn_guest"
                  type="button"
                  onClick={() => setGuestLoginIndication(true)}
                >
                  Continue as Guest
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
