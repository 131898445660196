import calendarSVG from "../../../assets/images/calender.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { Calendar } from "react-date-range";
import moment from "moment";
import plusIcon from "../../../assets/images/plus_icon.png";
import minusIcon from "../../../assets/images/minus_icon.png";
import { useLocation } from "react-router-dom";
import { hotelAdmin, hotelSearchListFilter } from "../../../api/HotelAPI";
import { toast } from "react-toastify";

import AutoCompleteHotel from "../shared/AutoCompleteHotel";
import {
  formatAndFilterHotelDestinationData,
  HotelDestinationList,
} from "./hotel-functions";
import { debounce } from "../../../utils/helpers";

interface SearchHotelProps {
  activeTab: string;
}

export const SearchHotel = ({ activeTab }: SearchHotelProps) => {
  const today = new Date();

  const guestsRef = useRef<HTMLUListElement | null>(null);

  const [hotelDestinationPlace, setHotelDestinationPlace] = useState("");
  const [hotelDestinationCode, setHotelDestinationCode] = useState("");
  const [hasProcessedStorage, setHasProcessedStorage] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [toggleRoom, setToggleRoom] = useState(false);
  const [numberOfNights, setNumberOfNights] = useState(1);
  const [hotelDestinationList, setHotelDestinationList] =
    useState<HotelDestinationList>();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [nationalityValue, setNationalityValue] = useState({
    country: "",
    code: "",
  });
  const [rooms, setRooms] = useState([
    {
      id: 1,
      adults: 2,
      children: 0,
      childAges: [],
    },
  ]);
  const [showCalender, setShowCalender] = useState(false);
  const [showGuests, setShowGuests] = useState(false);
  const [showCalenderCheckout, setShowCalenderCheckout] = useState(false);
  const [checkInDate, setCheckInDate] = useState<Date>();
  const [checkOutDate, setCheckOutDate] = useState<Date>();

  const checkInCalendarRef = useRef<HTMLSpanElement | null>(null);
  const roomTravelerRef = useRef<HTMLDivElement | null>(null);
  const checkOutCalendarRef = useRef<HTMLSpanElement | null>(null);

  // auto cursor move  input boxes
  const cityInput1Ref = useRef<HTMLInputElement>(null);
  const dateInput2Ref = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<HTMLInputElement>(null);
  const travelerSelectRef = useRef<HTMLDivElement>(null);
  const nightsRef = useRef<HTMLInputElement>(null);
  const nationalityRef = useRef<HTMLInputElement>(null);

  const getAirPortsJSON = localStorage.getItem("allAirports");

  const [isSelectEnabled, setSelectEnabled] = useState(false);

  const hotelAdminJSON = localStorage.getItem("hotel-admin");
  const hotelAdminStorage = JSON.parse(String(hotelAdminJSON));

  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (
        checkInCalendarRef.current &&
        !checkInCalendarRef.current.contains(event.target)
      ) {
        setShowCalender(false);
      }

      if (
        checkOutCalendarRef.current &&
        !checkOutCalendarRef.current.contains(event.target)
      ) {
        setShowCalenderCheckout(false);
      }

      if (
        roomTravelerRef.current &&
        !roomTravelerRef.current.contains(event.target)
      ) {
        if (nightsRef && nightsRef.current) {
          setTimeout(() => {
            nightsRef.current?.focus();
            nightsRef.current?.click();
          }, 2000);
        }
        setToggleRoom(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const hotelAdminHandler = async () => {
      await hotelAdmin()
        .then((response) => {
          const adminCode = response?.data.admin.allAccesses.edges[0].node.code;
          const clientData =
            response?.data.admin.allClients.edges[0].node.clientData;

          const supplierAccessCode =
            response?.data.admin.allAccesses.edges[0].node.accessData.code;
          const supplierData =
            response?.data.admin.allAccesses.edges[0].node.accessData.supplier;

          localStorage.setItem(
            "hotel-admin",
            JSON.stringify({
              adminCode,
              clientData,
              supplierData,
              supplierAccessCode,
            })
          );
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            toastId: "hotel-admin-toast",
          })
        );
    };
    if (activeTab === "hotel") {
      hotelAdminHandler();
    }
  }, [activeTab]);

  //Hotel list search - debounced API call function
  const hotelSearch = debounce(() => {
    if (activeTab === "hotel" && searchInputValue) {
      hotelSearchListFilter({ query: searchInputValue })
        .then((res) => {
          const hotelListResponseData = formatAndFilterHotelDestinationData(
            res?.data
          );
          setHotelDestinationList(
            hotelListResponseData as HotelDestinationList
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            toastId: "hotel-search-list-toast",
          });
        });
    }
  }, 400); // Debounce delay set to 400ms

  useEffect(() => {
    hotelSearch();

    // Cleanup timeout on component unmount or when searchInputValue changes
    return () => {
      // Here we are not using hotelSearch directly, so no need to clear the debounced function
      clearTimeout((hotelSearch as any).timer);
    };
  }, [searchInputValue, activeTab]);

  console.log(hotelDestinationList, "list of hotels");

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGuests]);

  useEffect(() => {
    if (!checkInDate || !checkOutDate) {
      setNumberOfNights(1);
      return;
    }
    const timeDifference = checkOutDate.getTime() - checkInDate.getTime();

    const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));
    setNumberOfNights(numberOfNights);
  }, [checkInDate, checkOutDate]);

  const hotelSearchJSON = localStorage.getItem("hotel-search");
  const hotelSearchStorage = hotelSearchJSON
    ? JSON.parse(hotelSearchJSON)
    : null;

  useEffect(() => {
    if (hotelSearchStorage && !hasProcessedStorage) {
      setHotelDestinationPlace(hotelSearchStorage.destinationName);
      setSearchInputValue(hotelSearchStorage.destinationName);
      setHotelDestinationCode(hotelSearchStorage.language);

      if (location.pathname !== "/") {
        if (hotelSearchStorage.checkInDate) {
          setCheckInDate(moment(hotelSearchStorage.checkInDate).toDate());
        }
        setCheckOutDate(moment(hotelSearchStorage.checkOutDate).toDate());
        setNumberOfNights(hotelSearchStorage.numberOfNights);
        setRooms([...hotelSearchStorage.rooms]);
        setNationalityValue({
          country: hotelSearchStorage.nationality,
          code: hotelSearchStorage.nationalityCode,
        });
      } else {
        setCheckOutDate(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
        );
        setCheckInDate(
          new Date(today.getFullYear(), today.getMonth(), today.getDate())
        );
      }

      setHasProcessedStorage(true);
    }

    if (!hotelSearchStorage) {
      setCheckOutDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
      );
      setCheckInDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelSearchStorage, hasProcessedStorage]);

  const updateCheckOutDate = () => {
    if (checkInDate && numberOfNights > 0) {
      const newCheckOutDate = new Date(checkInDate);
      newCheckOutDate.setDate(checkInDate.getDate() + numberOfNights);
      setCheckOutDate(newCheckOutDate);
    }
  };

  useEffect(() => {
    if (numberOfNights > 0) {
      updateCheckOutDate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfNights]);

  // Guests dropdown toggle
  const handleDocumentClick = (e: { target: any }) => {
    if (
      showGuests &&
      guestsRef.current &&
      !guestsRef.current.contains(e.target)
    ) {
      setShowGuests(false);
    }
  };

  const addRoom = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (rooms.length < 10) {
      const newRoom = {
        id: rooms.length + 1,
        adults: 1,
        children: 0,
        childAges: [],
      };
      setRooms([...rooms, newRoom]);
    }
  };

  const updateRoom = (id: number, field: any, value: any) => {
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === id ? { ...room, [field]: value } : room
      )
    );
  };

  const updateRoomChild = (roomId: number, childIndex: number, age: number) => {
    setRooms((prevRooms: any) =>
      prevRooms.map((room: any) =>
        room.id === roomId
          ? {
              ...room,
              childAges: room.childAges.map((item: any, index: number) =>
                index === childIndex ? age : item
              ),
            }
          : room
      )
    );
  };

  const removeRoom = (roomId: number) => {
    const updatedRooms = rooms.filter((room) => room.id !== roomId);
    setRooms(updatedRooms);
  };

  function generateAgeOptions() {
    return Array.from({ length: 18 }, (_, index) => ({
      value: index,
      label: index === 0 ? "< 1" : String(index),
    }));
  }

  const handleNumberOfNightsChange = (e: any) => {
    let value = e.target.value;
    value = parseInt(value, 10) || 0;

    value = Math.min(value, 30);

    if (value > 29) {
      setNumberOfNights(29);
    } else {
      setNumberOfNights(value);
    }
  };

  function calculateTotalTravelers() {
    let totalAdults = 0;
    let totalChildren = 0;

    rooms.forEach((room) => {
      totalAdults += room.adults;
      totalChildren += room.children;
    });

    return totalAdults + totalChildren;
  }

  function generateLabel() {
    const totalTravelers = calculateTotalTravelers();
    const totalRooms = rooms.length;

    return {
      Traveler: `${totalTravelers} Traveler`,
      Room: `${totalRooms} Room${totalRooms > 1 ? "s" : ""}`,
    };
  }

  // label for calling the Traveler and Room count
  const label = generateLabel();

  const handleDropdownToggle = () => {
    setShowGuests(!showGuests);
  };

  const handleMenuClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  const searchHotelAlert = () => {
    if (hotelDestinationPlace === "") {
      toast.error("Please fill your Hotel details", {
        toastId: "hotel-fill-for-search",
      });
    } else if (numberOfNights === 0) {
      toast.error("Number of Nights cannot be 0", {
        toastId: "hotel-fill-for-nights",
      });
    } else if (nationalityValue.country === "") {
      toast.error("Please enter nationality", {
        toastId: "hotel-fill-for-nationality",
      });
    } else {
      localStorage.setItem(
        "hotel-search",
        JSON.stringify({
          destinationName: hotelDestinationPlace,
          checkInDate: checkInDate,
          numberOfNights: numberOfNights,
          nationality: nationalityValue.country,
          nationalityCode: nationalityValue.code,
          market: hotelDestinationCode.toUpperCase(),
          language: hotelDestinationCode,
          checkOutDate,
          rooms,
        })
      );
      window.location.replace("/search-hotels");
    }
  };

  const removeDuplicateCountries = (data: any) => {
    const uniqueCountries = new Set();
    const filteredData: any[] = [];
    if (data) {
      for (const item of data) {
        const countryName = item.Country.toLowerCase();
        if (!uniqueCountries.has(countryName)) {
          uniqueCountries.add(countryName);
          filteredData.push(item);
        }
      }
    }

    return filteredData;
  };

  const filteredCountries = useMemo(() => {
    const uniqueCountriesData = removeDuplicateCountries(
      JSON.parse(String(getAirPortsJSON))
    );

    return uniqueCountriesData.filter(({ Country, Code }: any) =>
      `${Country} ${Code}`
        .toLowerCase()
        .includes(nationalityValue.country?.toLowerCase())
    );
  }, [getAirPortsJSON, nationalityValue.country]);

  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);

  const focusNextInput = (
    currentInputRef: React.RefObject<HTMLInputElement>
  ) => {
    if (currentInputRef === cityInput1Ref) {
      dateInput2Ref.current?.focus();
      dateInput2Ref.current?.click();
    } else if (currentInputRef === dateInput2Ref) {
      datePickerRef.current?.focus();
      datePickerRef.current?.click();
    }
  };
  return (
    <>
      <div className="" id="one-room">
        <div className="inline-form2">
          <AutoCompleteHotel
            label="Destination"
            hotelDestinationList={hotelDestinationList}
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
            setHotelDestinationCode={setHotelDestinationCode}
            forwardedRef={cityInput1Ref}
            focusNextInput={() => focusNextInput(cityInput1Ref)}
          />

          {/* check In Calender  */}
          <div className="inline-text">
            <div className="border px-2 py-2 rounded-2 border-dark">
              <div className="d-flex align-items-center gap-2">
                <img
                  loading="lazy"
                  className="multi-icon"
                  src={calendarSVG}
                  alt=""
                  onClick={() => {
                    setShowCalender(!showCalender);
                  }}
                />

                <span className="fw-bold fs-6">Check In</span>
              </div>

              <div
                className="input_heading"
                onClick={() => {
                  setShowCalender(!showCalender);
                }}
              >
                <input
                  type="text"
                  ref={dateInput2Ref}
                  className="fw-bold border-0 fs-4 w-100"
                  style={{ width: "100%" }}
                  value={moment(checkInDate).format("DD")}
                  onChange={() => {}}
                />
                <span className="date_formate">
                  {moment(checkInDate).format("MMM'YY")}
                </span>
              </div>

              <div
                className="code_heading"
                onClick={() => {
                  setShowCalender(!showCalender);
                }}
              >
                <span className="code-des d-inline-block text-truncate w-100">
                  {" "}
                  {moment(checkInDate).format("dddd")}{" "}
                </span>
              </div>
              {showCalender && (
                <span
                  ref={checkInCalendarRef}
                  style={{ position: "absolute", zIndex: 9999 }}
                >
                  <Calendar
                    minDate={
                      new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate()
                      )
                    }
                    maxDate={maxDate}
                    onChange={(item) => {
                      setCheckInDate(item);

                      const startDate = moment(item).toDate();

                      if (startDate >= moment(checkOutDate).toDate()) {
                        setCheckOutDate(
                          moment(startDate).add(1, "days").toDate()
                        );
                      }

                      setTimeout(() => {
                        datePickerRef?.current?.focus();
                        datePickerRef?.current?.click();
                      }, 200);

                      setShowCalender(!showCalender);
                    }}
                    date={checkInDate}
                  />
                </span>
              )}
            </div>
          </div>

          {/* check out Calender  */}
          <div className="inline-text">
            <div className="border px-2 py-2 rounded-2 border-dark">
              <div className="d-flex align-items-center gap-2">
                <img
                  loading="lazy"
                  className="multi-icon"
                  src={calendarSVG}
                  alt=""
                  onClick={() => {
                    setShowCalenderCheckout(!showCalenderCheckout);
                  }}
                />

                <span className="fw-bold fs-6">Check Out</span>
              </div>

              <div
                className="input_heading"
                onClick={() => {
                  setShowCalenderCheckout(!showCalenderCheckout);
                }}
              >
                <input
                  type="text"
                  ref={datePickerRef}
                  className="fw-bold border-0 fs-4 w-100"
                  style={{ width: "100%" }}
                  value={moment(checkOutDate).format("DD")}
                  onChange={() => {}}
                />
                <span className="date_formate">
                  {moment(checkOutDate).format("MMM'YY")}
                </span>
              </div>

              <div
                className="code_heading"
                onClick={() => {
                  setShowCalenderCheckout(!showCalenderCheckout);
                }}
              >
                <span className="code-des d-inline-block text-truncate w-100">
                  {" "}
                  {moment(checkOutDate).format("dddd")}{" "}
                </span>
              </div>
              {showCalenderCheckout && (
                <span
                  ref={checkOutCalendarRef}
                  style={{ position: "absolute", zIndex: 9999 }}
                >
                  <Calendar
                    minDate={
                      checkInDate !== undefined
                        ? moment(checkInDate).add(1, "days").toDate()
                        : new Date(
                            today.getFullYear(),
                            today.getMonth(),
                            today.getDate() + 1
                          )
                    }
                    maxDate={moment(checkInDate).add(30, "days").toDate()}
                    onChange={(item) => {
                      setCheckOutDate(item);

                      setTimeout(() => {
                        travelerSelectRef?.current?.click();
                      }, 200);

                      setShowCalenderCheckout(!showCalenderCheckout);
                    }}
                    date={checkOutDate}
                  />
                </span>
              )}
            </div>
          </div>

          <div className="inline-text">
            <div className="dropdown select-dropdown mx-0">
              <div className="dropdown-toggle">
                <div
                  ref={travelerSelectRef}
                  className="border px-2 py-2 rounded-2 border-dark"
                  onClick={() => setToggleRoom(!toggleRoom)}
                >
                  <div className="d-flex align-items-center gap-2">
                    <img
                      loading="lazy"
                      className="multi-icon"
                      src={calendarSVG}
                      alt=""
                    />
                    <span className="fw-bold fs-6">Room & Guest</span>
                  </div>
                  <div className="input_heading">
                    <input
                      type="text"
                      className="fw-bold border-0 fs-4 w-100"
                      value=""
                      style={{ width: "100%" }}
                    />
                    <span className="date_formate" style={{ left: "10px" }}>
                      {label.Traveler}
                    </span>
                  </div>
                  <div className="code_heading">
                    <span className="code-des d-inline-block text-truncate w-100">
                      {" "}
                      {label.Room}{" "}
                    </span>
                  </div>
                </div>
              </div>
              {toggleRoom && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    height: "200%",
                    overflow: "auto",
                    marginTop: "10px",
                  }}
                  ref={roomTravelerRef}
                >
                  <div
                    style={{
                      position: "relative",
                      zIndex: "999",
                      border: "1px solid",
                      padding: "10px",
                      marginTop: "-18px",
                      backgroundColor: "white",
                    }}
                  >
                    {rooms.map((room, index) => (
                      <div
                        // className="add-room-section"
                        key={room.id}
                        onClick={handleMenuClick}
                        style={{
                          width: "17rem",
                          position: "relative",
                          top: "5px",
                        }}
                      >
                        <div className="rom-list">
                          <div
                            className="top-row pb-0"
                            style={{ fontWeight: " 600" }}
                          >
                            Room {index + 1}
                          </div>
                          <div className="top-row">
                            Adults
                            <div>
                              {" "}
                              <img
                                loading="lazy"
                                src={plusIcon}
                                alt=""
                                onClick={() => {
                                  if (room.adults > 1) {
                                    const maxChildren = (room.adults - 1) * 6;
                                    if (room.children > maxChildren) {
                                      updateRoom(
                                        room.id,
                                        "children",
                                        room.children - 6
                                      );
                                    }
                                    updateRoom(
                                      room.id,
                                      "adults",
                                      room.adults - 1
                                    );
                                  }
                                }}
                              />{" "}
                              {room.adults}{" "}
                              <img
                                loading="lazy"
                                src={minusIcon}
                                alt=""
                                onClick={() => {
                                  if (room.adults < 8) {
                                    updateRoom(
                                      room.id,
                                      "adults",
                                      room.adults + 1
                                    );
                                  }
                                }}
                              />{" "}
                            </div>
                          </div>
                          Age (18+)
                        </div>
                        <div
                          className="rom-list"
                          style={{ marginBottom: "6px" }}
                        >
                          <div className="top-row">
                            Children
                            <div>
                              {" "}
                              <img
                                loading="lazy"
                                src={plusIcon}
                                alt=""
                                onClick={() => {
                                  if (room.children > 0) {
                                    updateRoom(
                                      room.id,
                                      "children",
                                      room.children - 1
                                    );
                                  }
                                }}
                              />{" "}
                              {room.children}{" "}
                              <img
                                loading="lazy"
                                src={minusIcon}
                                alt=""
                                onClick={() => {
                                  if (room.children < 4) {
                                    updateRoom(
                                      room.id,
                                      "children",
                                      room.children + 1
                                    );
                                  }
                                }}
                              />{" "}
                            </div>
                          </div>
                          Age (0-17)
                        </div>
                        {room.children > 0 && (
                          <div
                            className="rom-list"
                            style={{ marginBottom: "7px" }}
                          >
                            {Array.from(
                              { length: room.children },
                              (_, childIndex) => (
                                <div key={childIndex}>
                                  <div className="top-row">
                                    Child {childIndex + 1} age
                                    <div className="drowdown">
                                      <select
                                        className="select-age"
                                        disabled={isSelectEnabled}
                                        value={room.childAges[childIndex]}
                                        onClick={() => {
                                          // setSelectEnabled(true);
                                        }}
                                        onChange={(e) => {
                                          // setSelectEnabled(true);
                                          const age = parseInt(
                                            e.target.value,
                                            10
                                          );
                                          updateRoomChild(
                                            room.id,
                                            childIndex,
                                            age
                                          );
                                        }}
                                      >
                                        {generateAgeOptions().map(
                                          (ageOption) => (
                                            <option
                                              key={ageOption.value}
                                              value={ageOption.value}
                                            >
                                              {ageOption.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {index > 0 && (
                          <div
                            className="rom-list"
                            style={{
                              padding: "15px 0px 0px 0px",
                              marginBottom: "13px",
                            }}
                            onClick={() => removeRoom(room.id)}
                          >
                            <span
                              style={{
                                color: "red",
                                border: "1px solid #ccc",
                                padding: "4px",
                                cursor: "pointer",
                              }}
                            >
                              Remove room
                            </span>
                          </div>
                        )}

                        <hr className="dropdown-divider" />
                        {rooms.length < 10 && (
                          <>
                            {index === rooms.length - 1 && (
                              <div className="rom-list" onClick={addRoom}>
                                <span
                                  className="remove-btn"
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  + Add Another Room
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="inline-text">
            <div className="border px-2 py-2 rounded-2 border-dark">
              <div className="d-flex align-items-center gap-2">
                <span className="fw-bold fs-6">Nights</span>
              </div>
              <div className="input_heading">
                <input
                  ref={nightsRef}
                  type="search"
                  aria-label="Search"
                  className="fw-bold border-0 fs-4 w-100"
                  placeholder="No. of nights"
                  value={numberOfNights}
                  onChange={(e) => {
                    handleNumberOfNightsChange(e);
                    setTimeout(() => {
                      if (nationalityRef.current) {
                        nationalityRef.current?.focus();
                        nationalityRef.current?.click();
                      }
                    }, 2000);
                  }}
                />
              </div>
              <div className="code_heading">
                <span className="code-des d-inline-block text-truncate w-100">
                  Nights
                </span>
              </div>
            </div>
          </div>

          <div className="inline-text">
            <div className="border px-2 py-2 rounded-2 border-dark">
              <div className="d-flex align-items-center gap-2">
                <span className="fw-bold fs-6">Nationality</span>
              </div>
              <div className="input_heading" title={nationalityValue.country}>
                <input
                  ref={nationalityRef}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  type="search"
                  aria-label="Search"
                  className="fw-bold border-0 fs-4 w-100"
                  placeholder={"Nationality"}
                  value={nationalityValue.country}
                  onChange={(e) => {
                    setNationalityValue({
                      country: e.target.value,
                      code: nationalityValue.code,
                    });
                    setShowDropdown(true);
                  }}
                />
              </div>
              <div className="code_heading">
                <span
                  className="code-des d-inline-block text-truncate w-100"
                  style={{ textTransform: "uppercase" }}
                >
                  {nationalityValue.code}
                </span>
              </div>
            </div>
            {showDropdown && (
              <ul
                style={{
                  margin: "2px",
                  border: "1px solid #ccc",
                  padding: "6px",
                  position: "absolute",
                  backgroundColor: "white",
                  width: "100%",
                  borderRadius: "5px",
                  zIndex: 999,
                  maxHeight: "20rem",
                  overflowY: "auto",
                }}
              >
                {filteredCountries.map(
                  ({ Country, Code, LogoPath }: any, idx: number) => {
                    return (
                      <li
                        key={Code}
                        style={{
                          listStyleType: "none",
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "5px",
                          paddingTop: "5px",
                          cursor: "pointer",
                          // backgroundColor:
                          //   idx === highlightedIndex ? "#ffebeb" : "white",
                          // borderRadius: idx === highlightedIndex ? "10px" : "",
                          paddingLeft: "7px",
                        }}
                        onClick={() => {
                          setNationalityValue({
                            country: Country,
                            code: LogoPath,
                          });
                          setShowDropdown(false);
                        }}
                        tabIndex={0}
                      >
                        <span>{Country}</span>
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </div>

          <div className="inline-text">
            <button
              className="btn btn-login search_record"
              type="button"
              onClick={() => {
                searchHotelAlert();
              }}
            >
              Search
            </button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
