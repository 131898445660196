import axios from "axios";
import {
  bookingDetailsURL,
  bookingHotelURL,
  bookingListHotelURL,
  contentBoardURL,
  destinationHotelURL,
  hotelAdminURL,
  hotelContentURL,
  hotelListURL,
  hotelRoomListURL,
  hotelSearchURL,
  searchHotelList,
  upToDatePriceBookingURL,
  validatePanCardURL,
} from "./endpoints";

interface hotelListPayloadProps {
  access: string;
  hotelCodes: any[];
  countries: any[];
  destinationCodes: string;
  createdAt_lt: string;
  updatedAt_gt: string;
  name_in: false;
}

interface hotelSearchListFilterPayloadProps {
  query: string;
}

interface hotelSearchPayloadProps {
  checkIn: string;
  checkOut: string;
  occupancies: any[];
  hotelIds: string[];
  filterSearch: string[];
  currency: string;
  market: string;
  language: string;
  nationality: string;
  client: string;
  context: string;
  auditTransactions: boolean;
  testMode: boolean;
  timeout: number;
  accessIncludes?: string[];
}

interface hotelRoomPayloadProps {
  access: string;
  roomCodes: string[];
}

interface hotelContentPayload {
  access: string;
  hotelCodes?: string;
  destinationCodes?: string[];
}

interface upToDateBookingPayloadProps {
  optionRefId: string;
  client: string;
  auditTransactions: boolean;
  context: string;
  testMode: boolean;
  timeout: number;
}

interface hotelListFilterPayloadProps {
  access: string;
  maxSize: number;
}

interface hotelBookingPayloadProps {
  optionRefId: string;
  deltaPriceAmount: number;
  deltaPricePercent: number;
  holderName: string;
  holderSurname: string;
  remarks: string;
  rooms: any[];
}

interface hotelBookingFinalListPayloadProps {
  accessCode: string;
  language: string;
  typeSearch: string;
  dates: {
    dateType: string;
    start: string;
    end: string;
  };
}

interface contentBoardsRoomPayloadProps {
  access: string;
}

interface bookingDetailsPayloadProps {
  bookingID: string;
  client: string;
  auditTransactions: boolean;
  context: string;
  testMode: boolean;
  timeout: number;
}

interface validatePanCardPayloadProps {
  PanNumber: string;
  FirstName: string;
  LastName: string;
}

export async function hotelAdmin() {
  try {
    const response = await axios.get(hotelAdminURL);
    return response.data;
  } catch (error) {
    console.error("Error in hotel Admin", error);
    throw error;
  }
}

export async function hotelSearchListFilter(
  hotelSearchListFilterPayload: hotelSearchListFilterPayloadProps
) {
  const payload = { ...hotelSearchListFilterPayload };

  try {
    const response = await axios.post(searchHotelList, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel list", error);
    throw error;
  }
}

export async function hotelDestinationListFilter(
  hotelListFilterPayload: hotelListFilterPayloadProps
) {
  const payload = { ...hotelListFilterPayload };

  try {
    const response = await axios.post(destinationHotelURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel list", error);
    throw error;
  }
}

export async function hotelContentsList(
  hotelContentPayload: hotelContentPayload
) {
  const payload = { ...hotelContentPayload };

  try {
    const response = await axios.post(hotelContentURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel content", error);
    throw error;
  }
}

export async function getAllHotels(hotelListPayload: hotelListPayloadProps) {
  const payload = { ...hotelListPayload };

  try {
    const response = await axios.post(hotelListURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel list", error);
    throw error;
  }
}

export async function hotelSearch(hotelSearchPayload: hotelSearchPayloadProps) {
  const payload = { ...hotelSearchPayload };

  try {
    const response = await axios.post(hotelSearchURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel list", error);
    throw error;
  }
}

export async function hotelRoomList(hotelRoomPayload: hotelRoomPayloadProps) {
  const payload = { ...hotelRoomPayload };

  try {
    const response = await axios.post(hotelRoomListURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel list", error);
    throw error;
  }
}

export async function contentBoardsRoom(
  contentBoardsRoomPayload: contentBoardsRoomPayloadProps
) {
  const payload = { ...contentBoardsRoomPayload };

  try {
    const response = await axios.post(contentBoardURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel list", error);
    throw error;
  }
}

export async function upToDateBooking(
  upToDateBookingPayload: upToDateBookingPayloadProps
) {
  const payload = { ...upToDateBookingPayload };

  try {
    const response = await axios.post(upToDatePriceBookingURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel up to date price", error);
    throw error;
  }
}

export async function hotelBooking(
  hotelBookingPayload: hotelBookingPayloadProps
) {
  const payload = { ...hotelBookingPayload };

  try {
    const response = await axios.post(bookingHotelURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel booking", error);
    throw error;
  }
}

export async function bookingDetailsConfirmation(
  bookingDetailsPayload: bookingDetailsPayloadProps
) {
  const payload = { ...bookingDetailsPayload };

  try {
    const response = await axios.post(bookingDetailsURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel booking", error);
    throw error;
  }
}

export async function hotelBookingFinalList(
  hotelBookingFinalListPayload: hotelBookingFinalListPayloadProps
) {
  const payload = { ...hotelBookingFinalListPayload };

  try {
    const response = await axios.post(bookingListHotelURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in hotel booking", error);
    throw error;
  }
}

export async function validatePanCard(
  validatePanCardPayload: validatePanCardPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...validatePanCardPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  try {
    const response = await axios.post(validatePanCardURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in cancelation api", error);
    throw error;
  }
}
