import { SearchBarActivity } from "./SearchBarActivity";

export const SearchActivity = () => {
  return (
    <>
      <div className="search-bar-wrapper">
        <SearchBarActivity />
      </div>
    </>
  );
};
