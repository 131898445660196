import React from "react";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material";

interface BookNowButtonProps {
  SellingPrice: number;
  NetFare: number;
  Index: string;
}

interface SelectNowButtonProps {
  handleSelect: () => void;
}

// Styled Chip Component
export const StyledChip = styled(Chip)({
  background: "none",
  fontSize: "11px",
  color: "#535252",
  "& .MuiChip-label": {
    paddingLeft: "5px",
  },
  "& .MuiChip-icon": {
    fontSize: "15px",
    margin: "0px",
  },
});

export const BookNowButton: React.FC<BookNowButtonProps> = ({
  SellingPrice,
  NetFare,
  Index,
}) => {
  const handleBooking = () => {
    const flightBookingData = {
      fare: SellingPrice || NetFare,
      orderId: 1,
      index: Index,
    };

    localStorage.setItem("bookingDetails", JSON.stringify(flightBookingData));
    window.open("/flight-checkout", "_blank");
  };

  return (
    <div className="block">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1.5rem",
        }}
      >
        <div className="book-now-btn" style={{ padding: "0px" }}>
          <button
            className="btn btn-login"
            type="button"
            style={{ width: "100%" }}
            onClick={handleBooking}
          >
            Book now
          </button>
        </div>
        <Chip
          style={{
            background: "none",
            fontSize: "12px",
            color: "#535252",
          }}
          label="No hidden fees"
          icon={<DoneIcon style={{ fontSize: "17px" }} />}
        />
      </div>
    </div>
  );
};

export const SelectNowButton: React.FC<SelectNowButtonProps> = ({
  handleSelect,
}) => {
  return (
    <div className="block">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1.5rem",
        }}
      >
        <div className="card-content">
          <button
            className="btn btn-select"
            type="button"
            onClick={() => {
              handleSelect();
            }}
          >
            Select
          </button>
        </div>
        <StyledChip label="No hidden fees" icon={<DoneIcon />} />
      </div>
    </div>
  );
};
