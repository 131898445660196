import { useEffect, useState } from "react";
import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import filterImage from "../../assets/images/filter.png";
import arrowImage from "../../assets/images/sorting-arrows.png";
import { SearchFlight } from "../../components/forms/flight/SearchFlight";
import { MulticityCheapestFlights } from "../../components/forms/flight/MulticityCheapestFlight";
import { MulticityFastestFlights } from "../../components/forms/flight/MulticityFastestFlight";
import { TalkToAgents } from "../../components/TalkToAgent";
// import loadedFlightIndication from "../../assets/images/loaded-flights.gif";
import { SidebarFilter } from "../../components/forms/flight/SidebarFilter";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFlightLoader } from "../../components/forms/shared/useFlightLoader";
import { formatFlightClass } from "../../utils/helpers";
// import { toast } from "react-toastify";
import LazyImage from "../../pages/LazyImage";

import {
  expressSearch,
  getTUItokenAfterExpressSearch,
} from "../../api/FlightAPI";
import useFilterFlights from "../../utils/useFilterFlight";
import { slabsList } from "../../api/AdminAPI";

export const MulticityFlightSearch = () => {
  const flightListHistory: any[] = [];
  const [slabsListData, setSlabsListData] = useState<any>([]);

  const [flightType, setFlightType] = useState("cheapest");
  const [selectedArrivalTimes, setSelectedArrivalTimes] = useState<string[]>(
    []
  );

  const [flightMulticityJourney, setFlightMulticityJourney] = useState<any[]>(
    []
  );

  const [flightMultiCityArray, setFlightMultiCityArray] = useState<any[]>([]);

  const [flightDetails, setFlightDetails] = useState([null, null]);

  //filter flight states
  const [priceRange, setPriceRange] = useState<number>(0);
  const [getHightestPrice, setGetHightestPrice] = useState<number>(0);

  const [upToFlights, setUpToFlights] = useState(getHightestPrice);
  const [minPriceRange, setMinPriceRange] = useState<number>(priceRange);

  const [toggleFLightPrice, setToggleFlightPrice] = useState(false);
  const [changeFlightToggle, setChangeFlightToggle] = useState(false);
  const [refundable, setRefundable] = useState(false);
  const [flightIndication, setFlightIndication] = useState(false);
  const [selectedStops, setSelectedStops] = useState<any>([]);

  const [selectedFlights, setSelectedFlights] = useState<string[]>([]);
  const [selectedArrivalAirport, setSelectedArrivalAirport] = useState<any[]>(
    []
  );
  const [selectedDepartureTimes, setSelectedDepartureTimes] = useState<
    string[]
  >([]);

  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();
  const fetchFlightForCity = async (city: any, tuiArray: any[]) => {
    const flightDataJSON = localStorage.getItem("userMultiFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    const allAirportsData = localStorage.getItem("allAirports");
    const getallAirports = JSON.parse(String(allAirportsData));

    // const indiaTrips = getallAirports.filter((trip: any) => {
    //   return trip.Country === "India";
    // });

    const isAllDestinationsInIndia = flightData.cities.every((city: any) => {
      const destinationAirport = getallAirports.find(
        (airport: any) => airport.Code === city.destinationCode
      );
      return destinationAirport && destinationAirport.Country === "India";
    });

    const trips: any = [];
    flightData.cities.forEach((city: any) => {
      let trip = {
        From: city.departureCode,
        To: city.destinationCode,
        ReturnDate: "",
        OnwardDate:
          city.departureDate !== ""
            ? moment(city.departureDate).format("YYYY-MM-DD")
            : "",
        // FromCity: city.departure,
        // FromArptName: city.departureAirportName,
        // ToCity: city.destination,
        // ToArptName: city.destinationAirportName,
      };
      trips.push(trip);
    });

    try {
      const response = await expressSearch({
        ADT: flightData.ADT,
        CHD: flightData.CHD,
        INF: flightData.INF,
        Cabin: String(formatFlightClass(flightData.Cabin)),
        Source: "CF",
        Mode: "AS",
        TUI: "",
        //  FareType: indiaTrips.length > 0 ? "DM" : "IM",
        FareType: isAllDestinationsInIndia ? "DM" : "IM",

        ClientID: "API19201",
        IsMultipleCarrier: false,
        IsRefundable: false,
        SecType: "",
        YTH: 0,
        Trips: isAllDestinationsInIndia
          ? [
              {
                From: city.departureCode,
                To: city.destinationCode,
                ReturnDate: "",
                OnwardDate:
                  city.departureDate !== ""
                    ? moment(city.departureDate).format("YYYY-MM-DD")
                    : "",
                // FromCity: city.departure,
                // FromArptName: city.departureAirportName,
                // ToCity: city.destination,
                // ToArptName: city.destinationAirportName,
              },
            ]
          : trips,
        Parameters: {
          Airlines: "",
          GroupType: "",
          Refundable: "",
          IsDirect: flightData.IsDirect,
          IsStudentFare: flightData.IsStudentFare,
          IsNearbyAirport: flightData.IsNearbyAirport,
          IsSeniorCitizen: flightData.IsSeniorCitizen,
        },
      });

      tuiArray.push(response.data.TUI);

      localStorage.setItem("exp-token", response.data.TUI);
      return response.data;
    } catch (error: any) {
      throw error?.response?.data?.message;
    }
  };

  const pollTokenApi = async (TUI: string) => {
    const timeoutMs = 30000;

    try {
      let elapsedTime = 0;

      while (elapsedTime < timeoutMs) {
        const response = await getTUItokenAfterExpressSearch(TUI);

        if (response.data.Completed === "True") {
          setIsFlightLoading(true);

          setTimeout(() => {
            setIsFlightLoading(false);
          }, 2000);

          localStorage.setItem(
            "flightJourney",
            JSON.stringify({
              tUI: response.data.TUI,
              trips: [...flightListHistory],
            })
          );
          // setIsFlightLoading(false);
          return response.data.Trips;
        }

        await new Promise((resolve) => setTimeout(resolve, 100));

        elapsedTime += 100;
      }

      setIsFlightLoading(false);
      return null;
    } catch (err) {
      setIsFlightLoading(false);
      return null;
    }
  };

  useEffect(() => {
    const flightDataJSON = localStorage.getItem("userMultiFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    if (flightData) {
      setIsFlightLoading(true);

      const cities = flightData.cities;
      const tuiArray: any[] = [];

      const fetchAllFlights = async () => {
        const responses = await Promise.all(
          cities.map(
            async (city: any) => await fetchFlightForCity(city, tuiArray)
          )
        );

        const tokenPromises = responses.map((response) =>
          pollTokenApi(response.TUI)
        );

        localStorage.setItem("flightJourneyMulti", JSON.stringify(responses));

        const tokenResponses = (await Promise.all(tokenPromises)).flat();
        setFlightMulticityJourney(tokenResponses.flat());

        const allResponsesMeetCondition = tokenResponses.every(
          (tokenResponse) =>
            tokenResponse?.Journey && tokenResponse?.Journey.length > 0
        );

        if (allResponsesMeetCondition) {
          // All token API responses meet the condition
          setIsFlightLoading(false);
          setFlightIndication(true);

          setTimeout(() => {
            setFlightIndication(false);
          }, 2000);
        } else {
          console.log("Not all token API responses meet the condition.");
        }
      };

      fetchAllFlights();

      setTimeout(() => {
        setIsFlightLoading(false);
      }, 30000);
    }
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const flightDataJSON = localStorage.getItem("userMultiFlightSearchData");
  //       const flightData = JSON.parse(String(flightDataJSON));

  //       if (!flightData) {
  //         console.log("Flight data not available.");
  //         return;
  //       }

  //       setIsFlightLoading(true);

  //       const cities = flightData.cities;
  //       const tuiArray: any[] = [];

  //       const responses = await Promise.all(
  //         cities.map(async (city: any) => {
  //           const response = await fetchFlightForCity(city, tuiArray);
  //           const tokenResponse = await pollTokenApi(response.TUI);
  //           return { response, tokenResponse };
  //         })
  //       );

  //       localStorage.setItem("flightJourneyMulti", JSON.stringify(responses));

  //       const tokenResponses = responses.map(({ tokenResponse }) => tokenResponse);
  //       setFlightMulticityJourney(tokenResponses.flat());

  //       const allResponsesMeetCondition = tokenResponses.every(
  //         (tokenResponse) => tokenResponse?.Journey && tokenResponse?.Journey.length > 0
  //       );

  //       if (allResponsesMeetCondition) {
  //         setIsFlightLoading(false);
  //         setFlightIndication(true);

  //         setTimeout(() => {
  //           setFlightIndication(false);
  //         }, 2000);
  //       } else {
  //         console.log("Not all token API responses meet the condition.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setIsFlightLoading(false);
  //     }
  //   };

  //   fetchData();
  //   }, []);

  // useEffect(() => {
  //   slabsList()
  //     .then((res) => {
  //       setSlabsListData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    const fetchSlabs = async () => {
      try {
        const res = await slabsList();
        setSlabsListData(res.data);
      } catch (error) {
        console.error("Error fetching slabs:", error);
      }
    };

    fetchSlabs();
  }, []);

  const noNullElements = flightMulticityJourney?.every(
    (element) => element !== null
  );

  const getIndexFlightSearch =
    noNullElements && flightMulticityJourney.length !== 0
      ? flightMulticityJourney[currentIndex]?.Journey
      : flightListHistory;

  const getCitiesArrayJSON = localStorage.getItem("userMultiFlightSearchData");
  const getCitiesArray = JSON.parse(String(getCitiesArrayJSON));

  const handleSelectNextCity = () => {
    if (
      currentIndex < getCitiesArray.cities.length - 1 &&
      currentIndex !== getCitiesArray.cities.length
    ) {
      if (changeFlightToggle && flightMultiCityArray) {
        if (flightMultiCityArray.length === getCitiesArray.cities.length) {
          setCurrentIndex(Number(flightMultiCityArray.length - 1));
        } else {
          setCurrentIndex(Number(flightMultiCityArray.length));
        }
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    } else {
      navigate("/flight-checkout");
    }
  };

  const handleSelectPreviousCity = (index: any) => {
    if (currentIndex >= 0) {
      setCurrentIndex(index);
    }
  };

  const currentCity =
    getCitiesArray !== null && getCitiesArray.cities?.[currentIndex];

  function removeDuplicatesByFlightNo(arr: any[]) {
    const uniqueFlightNos = new Set();
    return arr?.filter((item) => {
      if (!uniqueFlightNos.has(item.Index)) {
        uniqueFlightNos.add(item.Index);
        return true;
      }
      return false;
    });
  }

  const airlines = getIndexFlightSearch && [
    ...removeDuplicatesByFlightNo(getIndexFlightSearch),
  ];

  const allConnectingAirports =
    getIndexFlightSearch &&
    airlines.reduce(
      (airports: any, flight: { Connections: { ArrAirportName: any }[] }) => {
        const connectingAirports = flight.Connections.map(
          (connection: { ArrAirportName: any }) =>
            connection?.ArrAirportName?.split("|")[1]
        );

        return [...airports, ...connectingAirports];
      },
      []
    );

  const uniqueConnectingAirportsSet = new Set(allConnectingAirports);

  const uniqueConnectingAirports = Array.from(uniqueConnectingAirportsSet);
  // useEffect(() => {
  //   const getPriceMax = () => {
  //     getIndexFlightSearch &&
  //       removeDuplicatesByFlightNo(getIndexFlightSearch)?.filter(
  //         (flight: any) => {
  //           const highestPrice =
  //             getIndexFlightSearch &&
  //             Math.max(
  //               ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
  //                 // (flightl: any) => flightl.GrossFare
  //                 (flightl: any) => flightl.NetFare
  //               )
  //             );

  //           const lowestPrice =
  //             getIndexFlightSearch &&
  //             Math.min(
  //               ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
  //                 (flightl: any) => flightl.NetFare
  //               )
  //             );
  //           const lowestPriceMin =
  //             getIndexFlightSearch &&
  //             Math.min(
  //               ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
  //                 (flightl: any) => flightl.NetFare
  //               )
  //             );

  //           const priceRangeMax = highestPrice;
  //           setPriceRange(lowestPrice);
  //         //  setMinPriceRange(lowestPriceMin); getlowestPrice
  //         setMinPriceRange(lowestPriceMin);
  //           setUpToFlights(priceRangeMax);
  //           setGetHightestPrice(priceRangeMax);
  //           return priceRangeMax;
  //         }
  //       );
  //   };
  //   getPriceMax();
  // }, [getIndexFlightSearch]);
  useEffect(() => {
    const highestPrice = Math.max(
      ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
        // (flight: any) => flight.GrossFare
        (flight: any) => flight.NetFare
      )
    );
    const lowestPrice = Math.min(
      ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
        (flight: any) => flight.NetFare
      )
    );
    const lowestPriceMin = Math.min(
      ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
        (flight: any) => flight.NetFare
      )
    );
    setPriceRange(lowestPrice);
    // setMinPriceRange(lowestPriceMin);
    setUpToFlights(highestPrice);
    setGetHightestPrice(highestPrice);
  }, [getIndexFlightSearch]);

  const filteredFlightsList = useFilterFlights({
    flightList: getIndexFlightSearch,
    toggleFLightPrice,
    priceRange,
    getHightestPrice,
    selectedStops,
    selectedFlights,
    selectedDepartureTimes,
    selectedArrivalTimes,
    selectedArrivalAirport,
    refundable,
  });

  useEffect(() => {
    // window.scrollTo({
    //   top: 300,
    //   behavior: "smooth",
    // });
  }, [filteredFlightsList, getIndexFlightSearch]);

  // Cheapest flight sorting :
  const sortFlightsByFare = (flights: any[]) => {
    return flights
      ?.slice()
      ?.sort((a: { NetFare: number }, b: { NetFare: number }) => {
        return a?.NetFare - b?.NetFare;
      });
  };

  const handleNoOfStopsChange = (stop: any) => {
    if (selectedStops.includes(stop)) {
      setSelectedStops(selectedStops?.filter((s: any) => s !== stop));
    } else {
      setSelectedStops([...selectedStops, stop]);
    }
  };

  const handleArrivalTimeChange = (time: any) => {
    if (selectedArrivalTimes.includes(time)) {
      setSelectedArrivalTimes((prevTimes) =>
        prevTimes?.filter((selectedTime) => selectedTime !== time)
      );
    } else {
      setSelectedArrivalTimes((prevTimes) => [...prevTimes, time]);
    }
  };

  const handleDepartureTimeChange = (value: string) => {
    if (selectedDepartureTimes.includes(value)) {
      setSelectedDepartureTimes(
        selectedDepartureTimes?.filter((time) => time !== value)
      );
    } else {
      setSelectedDepartureTimes([...selectedDepartureTimes, value]);
    }
  };

  const matchingFlights = getIndexFlightSearch?.filter(
    (flight: { Duration: string }) => {
      return flight?.Duration;
    }
  );
  const sortedFlightsCheapest = sortFlightsByFare(matchingFlights);

  // Fastest flights
  const sortFlightsByDuration = (flights: any[]) => {
    return flights
      ?.slice()
      ?.sort((a: { Duration: string }, b: { Duration: string }) => {
        const parseDuration = (duration: string) => {
          const parts = duration?.split(" ");
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          return hours * 60 + minutes;
        };

        const durationA = parseDuration(a?.Duration);
        const durationB = parseDuration(b?.Duration);

        return durationA - durationB;
      });
  };

  const fastestFlights = getIndexFlightSearch?.filter(
    (flight: { Duration: string }) => {
      return flight?.Duration;
    }
  );

  const sortedFlightsFastest = sortFlightsByDuration(fastestFlights);
  const sortedFlightByDeparture = getIndexFlightSearch
    .slice()
    .sort(
      (
        a: { DepartureTime: string | number | Date },
        b: { DepartureTime: string | number | Date }
      ) => {
        const departureTimeA: any = new Date(a.DepartureTime);
        const departureTimeB: any = new Date(b.DepartureTime);
        return departureTimeA - departureTimeB;
      }
    );

  const sortedFlightByArrival = getIndexFlightSearch
    .slice()
    .sort(
      (
        a: { ArrivalTime: string | number | Date },
        b: { ArrivalTime: string | number | Date }
      ) => {
        const arrivalTimeA: any = new Date(a.ArrivalTime);
        const arrivalTimeB: any = new Date(b.ArrivalTime);
        return arrivalTimeA - arrivalTimeB;
      }
    );

  const removeFilters = () => {
    setSelectedFlights([]);
    setSelectedArrivalAirport([]);
    setToggleFlightPrice(false);
    setRefundable(false);
    setUpToFlights(0);
    setSelectedStops([]);
    setSelectedDepartureTimes([]);
    setSelectedArrivalTimes([]);
  };

  const handleSelect = (index: number, flight: any) => {
    const flightDataJSON = localStorage.getItem("userMultiFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    const updatedFlightDetails: any = [...flightDetails];
    updatedFlightDetails[index] = {
      fare: flight.SellingPrice ? flight.SellingPrice : flight.NetFare,
      orderId: 1,
      index: flight.Index,
    };
    setFlightDetails(updatedFlightDetails);

    const updatedFlightMultiDetails: any = [...flightMultiCityArray];

    updatedFlightMultiDetails[index] = {
      ...flight,
    };

    setFlightMultiCityArray(updatedFlightMultiDetails);

    if (index !== flightData.cities.length - 1) {
      localStorage.setItem("returnId", flight.ReturnIdentifier);
    }

    if (index === flightData.cities.length - 1) {
      localStorage.setItem(
        "bookingDetails",
        JSON.stringify(updatedFlightDetails)
      );
      window.open("/checkout-multi-city", "_blank");
    }
  };

  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  const updateFlight = (flight: {
    NetFare: any;
    CommissionRate: any;
    AddonRate: any;
    SellingPrice: any;
    MarkedPrice: any;
    Duration: any;
    Discount: any;
    DiscountAmount: any;
  }) => {
    const netFare = flight.NetFare;
    const slab = findSlab(netFare);

    const markedPriceRate =
      flight.NetFare + flight.NetFare * (slab?.addonRate / 100);
    const sellingPriceRate =
      flight.NetFare + flight.NetFare * (slab?.commissionRate / 100);

    if (slab) {
      flight.CommissionRate = slab?.commissionRate;
      flight.AddonRate = slab?.addonRate;
      flight.MarkedPrice = Math.round(markedPriceRate);
      flight.SellingPrice = Math.round(sellingPriceRate + 1);

      const currentSellingPrice = flight.SellingPrice;
      // console.log("currentSellingPrice",currentSellingPrice);
      // sellingPricesArray.push(currentSellingPrice);

      flight.Discount = Math.round(
        ((flight.MarkedPrice - flight.SellingPrice) / flight.MarkedPrice) * 100
      );
      flight.DiscountAmount = Math.round(
        flight.MarkedPrice - flight.SellingPrice
      );
    }

    return flight;
  };

  const updatedFlightsCheapest = sortedFlightsCheapest.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  useEffect(() => {
    const cheapestFlight = updatedFlightsCheapest[0]?.SellingPrice
      ? updatedFlightsCheapest[0]
      : sortedFlightsCheapest[0];

    const formattedPrice = cheapestFlight?.SellingPrice
      ? cheapestFlight.SellingPrice.toLocaleString()
      : cheapestFlight?.NetFare.toLocaleString();

    setMinPriceRange(formattedPrice);
  }, [updatedFlightsCheapest, sortedFlightsCheapest]);

  const updatedFlightsFastest = sortedFlightsFastest.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  const updatedFlightsDeparture = sortedFlightByDeparture.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  const updatedFlightsArrival = sortedFlightByArrival.map(
    (flight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      Duration: any;
      DiscountAmount: any;
    }) => updateFlight(flight)
  );

  return (
    <AppLayout>
      <main className="container">
        <div className="tabbing-bar res_tabbing">
          <div className="tab-content">
            <SearchFlight />
          </div>
        </div>
        <>
          <div className="search-sticky">
            <div
              className="select-container res-hide-flight-tap"
              style={{ justifyContent: "flex-end" }}
            >
              <div
                onClick={() => setFlightType("cheapest")}
                className={
                  flightType === "cheapest"
                    ? "card card_cheapest_section card_active"
                    : "card card_cheapest_section"
                }
                style={{ width: "15rem" }}
              >
                <div className="card-body px-2 py-1">
                  <h5 className="card-title card_title mb-0">Cheapest</h5>
                  <span className="card_text">
                    ₹{" "}
                    {updatedFlightsCheapest[0]?.SellingPrice
                      ? updatedFlightsCheapest[0]?.SellingPrice.toLocaleString()
                      : sortedFlightsCheapest[0]?.NetFare.toLocaleString()}{" "}
                    ・ Duration: {sortedFlightsCheapest[0]?.Duration}
                  </span>
                </div>
              </div>
              <div
                onClick={() => setFlightType("fastest")}
                className={
                  flightType === "fastest"
                    ? "card card_cheapest_section card_active"
                    : "card card_cheapest_section"
                }
                style={{ width: "15rem" }}
              >
                <div className="card-body px-2 py-1">
                  <h5 className="card-title card_title mb-0">Fastest</h5>
                  <span className="card_text">
                    ₹{" "}
                    {updatedFlightsFastest[0]?.SellingPrice
                      ? updatedFlightsFastest[0]?.SellingPrice.toLocaleString()
                      : sortedFlightsFastest[0]?.NetFare.toLocaleString()}{" "}
                    ・ Duration: {sortedFlightsFastest[0]?.Duration}
                  </span>
                </div>
              </div>

              <div
                onClick={() => setFlightType("departure")}
                className={
                  flightType === "departure"
                    ? "card card_cheapest_section card_active"
                    : "card card_cheapest_section"
                }
                style={{ width: "15rem" }}
              >
                <div className="card-body px-2 py-1">
                  <h5 className="card-title card_title mb-0">Departure</h5>
                  <span className="card_text">
                    ₹{" "}
                    {updatedFlightsDeparture[0]?.SellingPrice
                      ? updatedFlightsDeparture[0]?.SellingPrice.toLocaleString()
                      : sortedFlightByDeparture[0]?.NetFare.toLocaleString()}{" "}
                    ・ Duration: {sortedFlightByDeparture[0]?.Duration}
                  </span>
                </div>
              </div>

              <div
                onClick={() => setFlightType("arrival")}
                className={
                  flightType === "arrival"
                    ? "card card_cheapest_section card_active"
                    : "card card_cheapest_section"
                }
                style={{ width: "15rem" }}
              >
                <div className="card-body px-2 py-1">
                  <h5 className="card-title card_title mb-0">Arrival</h5>
                  <span className="card_text">
                    ₹{" "}
                    {updatedFlightsArrival[0]?.SellingPrice
                      ? updatedFlightsArrival[0]?.SellingPrice.toLocaleString()
                      : sortedFlightByArrival[0]?.NetFare.toLocaleString()}{" "}
                    ・ Duration: {sortedFlightByArrival[0]?.Duration}
                  </span>
                </div>
              </div>
            </div>
            <div className="res-show-flight-tap">
              <div className="d-flex justify-content-between align-content-center">
                <div className="left-filter">
                  <h5
                    className="h3-bold mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <span>
                      <LazyImage
                        className="img-fluid"
                        src={arrowImage}
                        alt=""
                      />{" "}
                    </span>
                    Sort
                  </h5>
                </div>
                <div className="right-filter">
                  <h5
                    className="h3-bold mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#flight-exampleModal_flight_search"
                  >
                    <span>
                      <LazyImage
                        className="img-fluid"
                        src={filterImage}
                        alt=""
                      />{" "}
                    </span>
                    Filter
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal modal-bottom fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal_bottom">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="h3 bold">Sort</h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={flightType === "cheapest"}
                      onChange={() => setFlightType("cheapest")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Cheapest
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={flightType === "fastest"}
                      onChange={() => setFlightType("fastest")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Fastest
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault4"
                      checked={flightType === "departure"}
                      onChange={() => setFlightType("departure")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault4"
                    >
                      Departure
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      checked={flightType === "arrival"}
                      onChange={() => setFlightType("arrival")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      Arrival
                    </label>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-login search_record"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-login search_record"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          {(getIndexFlightSearch &&
            removeDuplicatesByFlightNo(
              noNullElements && flightMulticityJourney.length !== 0
                ? flightMulticityJourney[currentIndex]?.Journey
                : flightListHistory
            )?.length !== 0) ||
            (flightMulticityJourney[currentIndex]?.Journey !== null && <></>)}
          <div className="row">
            {isFlightLoading ? (
              <div style={{ display: "none" }}>{/* <AirplaneLoader /> */}</div>
            ) : (getIndexFlightSearch &&
                removeDuplicatesByFlightNo(
                  noNullElements && flightMulticityJourney.length !== 0
                    ? flightMulticityJourney[currentIndex]?.Journey
                    : flightListHistory
                )?.length === 0) ||
              flightMulticityJourney[currentIndex]?.Journey === null ||
              !noNullElements ? (
              <div style={{ textAlign: "center", fontSize: "27px" }}>
                No Data Found.
              </div>
            ) : (
              <>
                {/* Left side filter  */}
                <SidebarFilter
                  priceRange={priceRange}
                  selectedFlights={selectedFlights}
                  selectedArrivalAirport={selectedArrivalAirport}
                  setPriceRange={setPriceRange}
                  handleArrivalTimeChange={handleArrivalTimeChange}
                  selectedArrivalTimes={selectedArrivalTimes}
                  setSelectedFlights={setSelectedFlights}
                  setSelectedArrivalAirport={setSelectedArrivalAirport}
                  uniqueConnectingAirports={uniqueConnectingAirports}
                  getHightestPrice={getHightestPrice as number}
                  setToggleFlightPrice={setToggleFlightPrice}
                  refundable={refundable}
                  setRefundable={setRefundable}
                  handleNoOfStopsChange={handleNoOfStopsChange}
                  selectedStops={selectedStops}
                  handleDepartureTimeChange={handleDepartureTimeChange}
                  selectedDepartureTimes={selectedDepartureTimes}
                  flightList={getIndexFlightSearch}
                  upToFlights={upToFlights}
                  setUpToFlights={setUpToFlights}
                  minPriceRange={minPriceRange}
                  oneWay={true}
                />

                {/* ---Flight list----  */}
                <div className="col-sm-9">
                  {flightMultiCityArray && flightMultiCityArray?.length > 0 ? (
                    <>
                      {flightMultiCityArray?.map(
                        (flight: any, index: number) => (
                          <>
                            {flight && index !== currentIndex && (
                              <div className="left" key={index}>
                                <div className="select-flight my-3 px-3 py-3">
                                  <div className="depart-body-conten">
                                    <div className="main_content res_containt m-0 gap-0">
                                      <div className="block">
                                        <div className="card-img">
                                          <LazyImage
                                            className="img-fluid"
                                            src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight?.OAC}.jpg`}
                                            alt="flight?.OAC"
                                          />
                                        </div>
                                        <div className="card-content">
                                          <h3 className="h3-bold mb-0 res-font">
                                            {flight?.AirlineName?.split("|")[0]}
                                          </h3>
                                          <p className="card-text">
                                            {" "}
                                            {flight?.OAC} {"-"}{" "}
                                            {flight?.FlightNo}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font mb-0">
                                            {" "}
                                            {moment(
                                              flight?.DepartureTime
                                            ).format("LT")}
                                          </h3>
                                          <p className="card-text text_overflow">
                                            {flight?.FromName?.split("|")[1]}
                                            <span style={{color:"oranged", fontSize:"10px" }}>
                                              [{flight?.From}]
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="card-content content_body">
                                        <p className="card-text mb-0">
                                          {flight?.Duration}
                                        </p>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="left-image">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              fill="currentColor"
                                              viewBox="0 0 256 256"
                                              data-testid="originIcon"
                                            >
                                              <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                            </svg>
                                          </div>
                                          <div className="dot-arrow"></div>
                                          <div className="left-image">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              fill="currentColor"
                                              viewBox="0 0 256 256"
                                              data-testid="destinationIcon"
                                            >
                                              <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                        <p className="card-text">
                                          {flight?.Connections?.length}
                                          stop
                                        </p>
                                        <p
                                          className="card-text"
                                          style={{
                                            visibility: "hidden",
                                            height: "0px",
                                          }}
                                        >
                                          some some some te
                                        </p>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font mb-0">
                                            {moment(flight?.ArrivalTime).format(
                                              "LT"
                                            )}
                                          </h3>
                                          <p className="card-text text_overflow">
                                            {" "}
                                            {flight?.ToName?.split("|")[1]}
                                            <span style={{color:"oranged", fontSize:"10px" }}>
                                              [{flight?.To}]
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font">
                                            {" "}
                                            ₹
                                            {flight?.SellingPrice
                                              ? flight.SellingPrice
                                              : flight?.NetFare?.toLocaleString(
                                                  "en-IN"
                                                )}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <button
                                          className="change-flight-btn"
                                          onClick={() => {
                                            handleSelectPreviousCity(index);
                                            setChangeFlightToggle(true);
                                          }}
                                        >
                                          Change Flight
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )
                      )}

                      <div className="left-side mb-3" id="second_depart">
                        <div className="depart-heading">
                          <h3 className="h3-bold mb-0">
                            {currentIndex + 1}. Departing to{" "}
                            {currentCity?.destination}
                          </h3>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="left-side mb-3" id="first_depart">
                      <div className="depart-heading">
                        <h3 className="h3-bold mb-0">
                          1. Departing to {currentCity?.destination}
                        </h3>
                      </div>
                    </div>
                  )}
                  <div>
                    {/* Cheapest Flights */}
                    {flightType === "cheapest" && (
                      <>
                        {filteredFlightsList.length > 0 ? (
                          <MulticityCheapestFlights
                            key={Math.random()}
                            cheapestFlightList={filteredFlightsList}
                            handleSelectNextCity={handleSelectNextCity}
                            currentIndex={currentIndex}
                            removeFilters={removeFilters}
                            handleSelect={handleSelect}
                            slabsListData={slabsListData}
                          />
                        ) : (
                          <h1> No data found</h1>
                        )}
                      </>
                    )}

                    {/* Fastest Flights */}
                    {flightType === "fastest" && (
                      <>
                        {filteredFlightsList.length > 0 ? (
                          <MulticityFastestFlights
                            fastestFlightList={filteredFlightsList}
                            handleSelectNextCity={handleSelectNextCity}
                            currentIndex={currentIndex}
                            removeFilters={removeFilters}
                            handleSelect={handleSelect}
                            slabsListData={slabsListData}
                          />
                        ) : (
                          <h1> No data found</h1>
                        )}
                      </>
                    )}

                    {flightType === "departure" && (
                      <>
                        {filteredFlightsList.length > 0 ? (
                          <MulticityCheapestFlights
                            key={Math.random()}
                            cheapestFlightList={filteredFlightsList}
                            handleSelectNextCity={handleSelectNextCity}
                            currentIndex={currentIndex}
                            removeFilters={removeFilters}
                            handleSelect={handleSelect}
                            slabsListData={slabsListData}
                            tag="departure"
                          />
                        ) : (
                          <h1> No data found</h1>
                        )}
                      </>
                    )}

                    {flightType === "arrival" && (
                      <>
                        {filteredFlightsList.length > 0 ? (
                          <MulticityCheapestFlights
                            key={Math.random()}
                            cheapestFlightList={filteredFlightsList}
                            handleSelectNextCity={handleSelectNextCity}
                            currentIndex={currentIndex}
                            removeFilters={removeFilters}
                            handleSelect={handleSelect}
                            tag="arrival"
                            slabsListData={slabsListData}
                          />
                        ) : (
                          <h1> No data found</h1>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </main>
      {flightIndication && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 17%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <span style={{ fontSize: "26px", color: "red" }}>
            {filteredFlightsList.length + " results"}
          </span>
        </div>
      )}

      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
