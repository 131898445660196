import fileArrow from "../../assets/images/FileArrowDown.png";
import airplaneTilt from "../../assets/images/AirplaneTilt.png";
import warning from "../../assets/images/warning_icon.png";
import failed_icon from "../../assets/images/failed_icon.png";

import alert from "../../assets/images/alert.png";
import LazyImage from "../../pages/LazyImage";

import { AppLayout } from "../../components/AppLayout";
import { useEffect, useState } from "react";
import { TalkToAgents } from "../../components/TalkToAgent";
import Footer from "../../components/Footer";
import {
  downloadBooking,
  flightItineraryStatus,
  retrieveBooking,
  sendEmailConfirmationForBooking,
  sendSMSForBooking,
  updateStoreFlightData,
} from "../../api/FlightAPI";
import { toast } from "react-toastify";
import moment from "moment";
import AirplaneLoader from "../../components/loaders/AirplaneLoader";
import { slabsList } from "../../api/AdminAPI";
import {
  AddonsProvider,
  useAddons,
} from "../../components/forms/flight/addonccontext";
import { processMAC } from "../../utils/helpers";

export const BookingConfirmation = () => {
  const [toggleFare, setToggleFare] = useState("");
  const [itineraryStatus, setItineraryStatus] = useState<any>();
  const [baseFareDetails, setBaseFareDetails] = useState<any>({
    baseFare: "",
    tax: "",
    ptcFare: "",
    discount: "",
    netAmount: "",
    commission: "",
  });
  const [bookingDetails, setBookingDetails] = useState<any>();
  // const [refreshBooking, setRefreshBooking] = useState(false);

  const [multiCityFlights, setMulticityFlights] = useState<any[]>([]);
  const [isStatusApiEnded, setIsStatusApiEnded] = useState(false);
  const [slabsListData, setSlabsListData] = useState<any>([]);
  const [markedPrice, setMarkedPrice] = useState(0);
  const [storedAddonPercent, setStoredAddonPercent] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);

  const getPaymentStatusJSON = localStorage.getItem("paymentObj");

  const getPaymentStatus = JSON.parse(String(getPaymentStatusJSON));

  const itineraryTUI = localStorage.getItem("createITernTUI");
  const parsedItinerary = JSON.parse(String(itineraryTUI));

  const getCurrentSelectedTab = localStorage.getItem("tabSearchData");

  const flightSearchDetailsJSON = localStorage.getItem("userFlightSearchData");
  const flightSearchDetails = JSON.parse(String(flightSearchDetailsJSON));
  const [showHideBase, setShowHideBase] = useState("");
  const [bookingData, setBookingData] = useState([]);

  // new code here
  const {
    addonDetailsData,
    setAddonDetailsData,
    mealAddonDetailsData,
    setMealAddonDetailsData,
    seatAddonDetailsData,
    setSeatAddonDetailsData,
    finalAmount,
    setFinalAmount,
    totalValue,
    setTotalValue,
    totalAmount,
    setTotalAmount,
    totalMealValue,
    setTotalMealValue,
    totalSeatValue,
    setTotalSeatValue,
    totalSportBaggageValue,
    setTotalSportBaggageValue,
    totalPriorityBaggageValue,
    setTotalPriorityBaggageValue,
    totalSSRAmount,
    setTotalSSRAmount,
  } = useAddons();

  const BaggageAmount = totalValue;
  // console.log("Total Baggage Amount", BaggageAmount);
  const showBaggageAmount = BaggageAmount.some((amount) => amount > 0);
  //console.log("bookingDetails",bookingDetails.SSR);
  const MealAmount = totalMealValue;
  // console.log("Total Meal Amount", MealAmount);
  const showMealAmount = MealAmount.some((amountmeal) => amountmeal > 0);

  const SeatAmount = totalSeatValue;
  // console.log("Total Seat Amount", SeatAmount);
  const showSeatAmount = SeatAmount.some((amountseat) => amountseat > 0);

  const SportAmount = totalSportBaggageValue;
  //console.log("Total Sport Amount", SportAmount);
  const showSportAmount = SportAmount.some((amountsport) => amountsport > 0);

  const PriorityAmount = totalPriorityBaggageValue;
  //console.log("Total Priority Amount", PriorityAmount);
  const showPriorityAmount = PriorityAmount.some(
    (amountpriority) => amountpriority > 0
  );

  useEffect(() => {
    slabsList()
      .then((res) => {
        setSlabsListData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let callCount = 0;
    const makeAPICall = () => {
      if (Array.isArray(parsedItinerary)) {
        if (callCount < parsedItinerary.length) {
          const itineraryItem = parsedItinerary[callCount];
          flightItineraryStatus({
            TransactionID: Number(itineraryItem?.transactionId),
            TUI: String(itineraryItem?.TUI),
          })
            .then((res) => {
              if (res.data) {
                setItineraryStatus(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              callCount++;
              if (callCount < parsedItinerary.length) {
                setTimeout(makeAPICall, 2000);
              }
              setIsStatusApiEnded(true);
            });
        }
      } else {
        if (callCount < 4) {
          flightItineraryStatus({
            TransactionID: Number(parsedItinerary?.transactionId),
            TUI: String(parsedItinerary?.TUI),
          })
            .then((res) => {
              setItineraryStatus(res.data);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              callCount++;
              if (callCount < 4) {
                setTimeout(makeAPICall, 2000);
              }
              if (callCount === 3) {
                setIsStatusApiEnded(true);
              }
            });
        }
      }
    };
    // setRefreshBooking(false)
    makeAPICall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getBookingDetails = async () => {
      const paymentObjectJSON = localStorage.getItem("paymentObj");
      const paymentObject = JSON.parse(String(paymentObjectJSON));

      if (Array.isArray(parsedItinerary) && isStatusApiEnded) {
        // If it's an array, run retrieveBooking in a loop for each item
        const promises = parsedItinerary.map(
          async (item: { TUI: any; transactionId: any }) => {
            return retrieveBooking({
              TUI: String(item.TUI),
              ClientID: "API19201",
              ReferenceNumber: String(item.transactionId),
              ReferenceType: "T",
              ServiceType: "FLT",
            });
          }
        );

        try {
          const responses = await Promise.all(promises);
          const allMulticityFlights: any[] = [];

          // Handle responses here
          responses.forEach((response, index) => {
            if (response?.data?.Trips[0]?.Journey[0]) {
              allMulticityFlights.push(response.data.Trips[0].Journey[0]);
            }
            if (response.data) {
              sendSMSForBooking({
                TUI: response.data.TUI,
                ClientID: "API19201",
                ReferenceNumber: String(response.data.TransactionID),
                ReferenceType: "T",
                ServiceType: "FLT",
              })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));

              if (getPaymentStatus?.paymentResponse) {
                sendEmailConfirmationForBooking({
                  amount: getPaymentStatus.paymentResponse.net_amount_debit,
                  ClientID: "API19201",
                  ReferenceNumber: String(response.data.TransactionID),
                  ReferenceType: "T",
                  ServiceType: "FLT",
                  TUI: response.data.TUI,
                });
              }

              updateStoreFlightData({
                ...response?.data,
                userId: paymentObject?.userId,
                TransactionID: paymentObject?.txnId,
              })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));

              setBookingDetails(response.data);

              setBaseFareDetails({
                ...baseFareDetails,
                baseFare:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .TotalBaseFare,
                tax: response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                  .TotalTax,
                ptcFare:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .PTCFare,
                discount:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .TotalCommission,
                commission:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .TotalCommission,
                netAmount:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .NetFare,
              });
            }
          });
          setMulticityFlights(allMulticityFlights);
        } catch (error) {
          toast.error("Error in Retrieving Your booking details", {
            toastId: "error-retrieve-booking",
          });
        }
      } else {
        // If parsedItinerary is not an array, handle it as a single item
        if (isStatusApiEnded) {
          await retrieveBooking({
            TUI: String(parsedItinerary?.TUI),
            ClientID: "API19201",
            ReferenceNumber: String(parsedItinerary?.transactionId),
            ReferenceType: "T",
            ServiceType: "FLT",
          })
            .then((response) => {
              sendSMSForBooking({
                TUI: response.data.TUI,
                ClientID: "API19201",
                ReferenceNumber: String(response.data.TransactionID),
                ReferenceType: "T",
                ServiceType: "FLT",
              })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));

              if (getPaymentStatus?.paymentResponse) {
                sendEmailConfirmationForBooking({
                  amount: getPaymentStatus.paymentResponse.net_amount_debit,
                  ClientID: "API19201",
                  ReferenceNumber: String(response.data.TransactionID),
                  ReferenceType: "T",
                  ServiceType: "FLT",
                  TUI: response.data.TUI,
                });
              }

              updateStoreFlightData({
                ...response?.data,
                userId: paymentObject?.userId,
                TransactionID: paymentObject?.txnId,
              })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));

              setBookingDetails(response.data);
              setBaseFareDetails({
                ...baseFareDetails,
                baseFare:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .TotalBaseFare,
                tax: response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                  .TotalTax,
                ptcFare:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .PTCFare,
                discount:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .TotalCommission,
                commission:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .TotalCommission,
                netAmount:
                  response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                    .NetFare,
              });
            })
            .catch((error) => {
              toast.error(error?.response?.data?.message, {
                toastId: "error-retrieve-booking",
              });
            });
        }
      }
    };

    getBookingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusApiEnded]);

  const downloadBookingPDFHandler = async () => {
    if (Array.isArray(parsedItinerary)) {
      parsedItinerary.map(async (item) => {
        downloadBooking({
          ReferenceNumber: item.transactionId,
        })
          .then((res) => {
            const blob = new Blob([res], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "booking.pdf";

            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => console.log(err));
      });
    } else {
      downloadBooking({
        ReferenceNumber: parsedItinerary?.transactionId,
      })
        .then((res) => {
          const blob = new Blob([res], { type: "application/pdf" });

          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "booking.pdf";

          a.click();

          window.URL.revokeObjectURL(url);
        })
        .catch((err) => console.log(err));
    }
  };

  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  useEffect(() => {
    const flight = bookingDetails;

    if (flight) {
      const netFare = flight.NetAmount;
      const slab = findSlab(netFare);

      const markedPriceRate =
        flight.NetAmount + flight.NetAmount * (slab?.addonRate / 100);
      const sellingPriceRate =
        flight.NetAmount + flight.NetAmount * (slab?.commissionRate / 100);

      if (slab) {
        flight.CommissionRate = slab?.commissionRate;
        flight.AddonRate = slab?.addonRate;
        flight.MarkedPrice = Math.round(markedPriceRate);
        flight.SellingPrice = Math.round(sellingPriceRate + 1);

        flight.Discount = Math.round(
          ((flight.MarkedPrice - flight.SellingPrice) / flight.MarkedPrice) *
            100
        );
        flight.DiscountAmount = Math.round(
          flight.MarkedPrice - flight.SellingPrice
        );
      }

      if (flight?.SellingPrice || flight?.MarkedPrice) {
        setMarkedPrice(flight?.MarkedPrice);
        setSellingPrice(flight?.SellingPrice);
        setStoredAddonPercent(flight.NetAmount * (flight?.AddonRate / 100));
      } else {
        setMarkedPrice(0);
        setSellingPrice(0);
        setStoredAddonPercent(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDetails]);

  const mergedArrayTravelers =
    bookingDetails !== undefined &&
    bookingDetails?.Pax?.map((obj: any, index: string | number) => ({
      ...obj,
      ...bookingDetails?.Trips[0]?.Journey[0]?.Segments[0]?.Flight?.TicketInfo[
        index
      ],
    }));

  const flightInfo =
    bookingDetails !== undefined &&
    bookingDetails?.Trips[0]?.Journey[0]?.Segments[0]?.Flight;

  const allFlights =
    bookingDetails !== undefined && bookingDetails?.Trips[0]?.Journey;

  const checkReturn = bookingDetails?.Trips.length === 2;

  const flightReturnInfo =
    bookingDetails !== undefined && checkReturn && bookingDetails?.Trips;

  const checkConnectingFlights =
    bookingDetails !== undefined &&
    bookingDetails?.Trips[0]?.Journey[0]?.Segments[0]?.Flight?.Hops;

  const roundFirst =
    bookingDetails !== undefined &&
    checkReturn &&
    bookingDetails?.Trips?.[0]?.Journey?.[0]?.Segments;

  const roundSecond =
    bookingDetails !== undefined &&
    checkReturn &&
    bookingDetails?.Trips?.[1]?.Journey?.[0]?.Segments;

  const flightObjRound = [
    roundFirst[0]?.Fares?.PTCFare,
    roundSecond[0]?.Fares?.PTCFare,
  ];

  const ptcForRound = [
    ...(roundFirst[0]?.Fares?.PTCFare || []),
    ...(roundSecond[0]?.Fares?.PTCFare || []),
  ];

  const roundTripFareDetails =
    roundFirst[0]?.Fares?.PTCFare &&
    flightObjRound?.flat()?.reduce(
      (
        accumulator: any[],
        currentObj: {
          PTC: any;
          Fare: any;
          ST: any;
          YQ: any;
          UD: any;
          YR: any;
          RCF: any;
          PHF: any;
          API: any;
          RCS: any;
          PSF: any;
          K3: any;
          OT: any;
          VATonServiceCharge: any;
        }
      ) => {
        const index = accumulator.findIndex(
          (item) => item?.PTC === currentObj?.PTC
        );

        if (index !== -1) {
          accumulator[index].Fare += currentObj.Fare;
          accumulator[index].ST += currentObj.ST;
          accumulator[index].YQ += currentObj.YQ;
          accumulator[index].UD += currentObj.UD;
          accumulator[index].YR += currentObj.YR;
          accumulator[index].RCF += currentObj.RCF;
          accumulator[index].PHF += currentObj.PHF;
          accumulator[index].RCS += currentObj.RCS;
          accumulator[index].API += currentObj.API;
          accumulator[index].PSF += currentObj.PSF;
          accumulator[index].K3 += currentObj.K3;
          accumulator[index].VATonServiceCharge +=
            currentObj.VATonServiceCharge;
          accumulator[index].OT += `, ${currentObj.OT}`;
        } else {
          accumulator.push({
            ...currentObj,
          });
        }
        return accumulator;
      },
      []
    );

  const fareRuleText = bookingDetails?.Rules[0]?.FareRuleText;

  //one-way fare

  const ptcFares = baseFareDetails.ptcFare || [];

  const adultCount = flightSearchDetails.ADT || 0;
  const childCount = flightSearchDetails.CHD || 0;
  const infantCount = flightSearchDetails.INF || 0;

  // Initialize tax totals for each passenger type
  const adultTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    RCS: 0,
    RCF: 0,
    PHF: 0,
    CommonUserTerminalEquipment: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const childTaxTotals = {
    AirlineMisc: 0,
    RCS: 0,
    RCF: 0,
    PHF: 0,
    CommonUserTerminalEquipment: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };
  const infantTaxTotals = {
    AirlineMisc: 0,
    RCS: 0,
    RCF: 0,
    PHF: 0,
    CommonUserTerminalEquipment: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  // Calculate taxes for each passenger type
  ptcFares?.forEach(
    (ptc: {
      PTC: any;
      OT: string;
      ST: string;
      YQ: string;
      RCS: any;
      CUTE: any;
      YR: string;
      RCF: string;
      PHF: string;
      UD: string;
      API: string;
      PSF: string;
      K3: string;
      VATonServiceCharge: string;
    }) => {
      const ptcType = ptc.PTC;
      switch (ptcType) {
        case "ADT":
          const otValues =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",").map(
                  (value) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSum = otValues?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );

          adultTaxTotals.AirlineMisc +=
            (otSum + parseFloat(ptc.ST)) * adultCount;
          adultTaxTotals.FuelTax += parseFloat(ptc.YQ) * adultCount;
          adultTaxTotals.CongestionFee += parseFloat(ptc.YR) * adultCount;
          adultTaxTotals.RCF += parseFloat(ptc.RCF) * adultCount;
          adultTaxTotals.PHF += parseFloat(ptc.PHF) * adultCount;
          adultTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * adultCount;
          adultTaxTotals.APITax += parseFloat(ptc.API) * adultCount;
          adultTaxTotals.RCS += ptc.RCS * adultCount;
          adultTaxTotals.CommonUserTerminalEquipment += ptc.CUTE * adultCount;
          adultTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * adultCount;
          adultTaxTotals.GST += parseFloat(ptc.K3) * adultCount;
          adultTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * adultCount;
          break;
        case "CHD":
          const otValuesChild = ptc?.OT?.split(",").map(
            (value) => parseFloat(value.trim()) || 0
          );

          const otSumChild = otValuesChild?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          childTaxTotals.AirlineMisc +=
            (otSumChild + parseFloat(ptc.ST)) * childCount;
          childTaxTotals.FuelTax += parseFloat(ptc.YQ) * childCount;
          childTaxTotals.RCS += ptc.RCS * childCount;
          childTaxTotals.CommonUserTerminalEquipment += ptc.CUTE * childCount;
          childTaxTotals.CongestionFee += parseFloat(ptc.YR) * childCount;
          childTaxTotals.RCF += parseFloat(ptc.RCF) * childCount;
          childTaxTotals.PHF += parseFloat(ptc.PHF) * childCount;
          childTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * childCount;
          childTaxTotals.APITax += parseFloat(ptc.API) * childCount;
          childTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * childCount;
          childTaxTotals.GST += parseFloat(ptc.K3) * childCount;
          childTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * childCount;
          break;
        case "INF":
          const otValuesInfant = ptc?.OT?.split(",").map(
            (value) => parseFloat(value.trim()) || 0
          );

          const otSumInfant = otValuesInfant?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          infantTaxTotals.AirlineMisc +=
            (otSumInfant + parseFloat(ptc.ST)) * infantCount;
          infantTaxTotals.FuelTax += parseFloat(ptc.YQ) * infantCount;
          infantTaxTotals.RCS += ptc.RCS * infantCount;
          infantTaxTotals.CommonUserTerminalEquipment += ptc.CUTE * infantCount;
          infantTaxTotals.CongestionFee += parseFloat(ptc.YR) * infantCount;
          infantTaxTotals.RCF += parseFloat(ptc.RCF) * infantCount;
          infantTaxTotals.PHF += parseFloat(ptc.PHF) * infantCount;
          infantTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * infantCount;
          infantTaxTotals.APITax += parseFloat(ptc.API) * infantCount;
          infantTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * infantCount;
          infantTaxTotals.GST += parseFloat(ptc.K3) * infantCount;
          infantTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * infantCount;
          break;
        default:
      }
    }
  );

  const taxTotals: any = {
    AirlineMisc: 0 + storedAddonPercent ? Math.round(storedAddonPercent) : 0,
    FuelTax: 0,
    CongestionFee: 0,
    RCS: 0,
    RCF: 0,
    PHF: 0,
    CommonUserTerminalEquipment: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  function calculateFare(tax: any, passengerCount: number) {
    const otValues =
      typeof tax?.OT === "string"
        ? tax?.OT?.split(",").map(
            (value: string) => parseFloat(value.trim()) || 0
          )
        : [];

    const otSum = otValues?.reduce(
      (acc: number, currentValue: number) => acc + currentValue,
      0
    );

    return {
      AirlineMisc: (otSum + parseFloat(tax.ST)) * passengerCount,
      RCS: parseFloat(tax.RCS) * passengerCount || 0,
      FuelTax: parseFloat(tax.YQ) * passengerCount || 0,
      CongestionFee: parseFloat(tax.YR) * passengerCount || 0,
      RCF: parseFloat(tax.RCF) * passengerCount || 0,
      PHF: parseFloat(tax.PHF) * passengerCount || 0,
      UserDevelopmentFee: parseFloat(tax.UD) * passengerCount || 0,
      APITax: parseFloat(tax.API) * passengerCount || 0,
      CommonUserTerminalEquipment: parseFloat(tax.CUTE) * passengerCount || 0,
      PassengerServiceTax: parseFloat(tax.PSF) * passengerCount || 0,
      GST: parseFloat(tax.K3) * passengerCount || 0,
      VATonServiceCharge:
        parseFloat(tax.VATonServiceCharge) * passengerCount || 0,
    };
  }

  ptcFares?.forEach((ptc: any) => {
    const ptcType = ptc.PTC;
    const passengerCount =
      ptcType === "ADT"
        ? adultCount
        : ptcType === "CHD"
        ? childCount
        : infantCount;

    const fareDetails: any = calculateFare(ptc, passengerCount);

    for (const key in taxTotals) {
      taxTotals[key] += fareDetails[key];
    }
  });

  const totalTaxAmount = Object.values(taxTotals)?.reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  const discountCalc = markedPrice - sellingPrice;

  const discountRateOneWay = markedPrice
    ? Math.round(discountCalc + baseFareDetails?.commission)
    : baseFareDetails?.commission;

  const totalFareCalcOne =
    baseFareDetails.baseFare + totalTaxAmount - discountRateOneWay;

  //Round Trip Fare

  const adultCountRound = flightSearchDetails.ADT || 0;
  const childCountRound = flightSearchDetails.CHD || 0;
  const infantCountRound = flightSearchDetails.INF || 0;

  const adultTaxTotalsRound = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    RCF: 0,
    PHF: 0,
    RCS: 0,
    CommonUserTerminalEquipment: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const childTaxTotalsRound = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    RCF: 0,
    PHF: 0,
    RCS: 0,
    CommonUserTerminalEquipment: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };
  const infantTaxTotalsRound = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    RCS: 0,
    RCF: 0,
    PHF: 0,
    CommonUserTerminalEquipment: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  ptcForRound?.forEach(
    (ptc: {
      PTC: any;
      OT: string;
      ST: string;
      YQ: string;
      YR: string;
      RCF: string;
      PHF: string;
      RCS: any;
      CUTE: any;
      UD: string;
      API: string;
      PSF: string;
      K3: string;
      VATonServiceCharge: string;
    }) => {
      const ptcType = ptc.PTC;

      switch (ptcType) {
        case "ADT":
          const otValues =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",").map(
                  (value) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSum = otValues?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );

          adultTaxTotalsRound.AirlineMisc +=
            (otSum + parseFloat(ptc.ST)) * adultCountRound;
          adultTaxTotalsRound.FuelTax += parseFloat(ptc.YQ) * adultCountRound;
          adultTaxTotalsRound.RCS += ptc.RCS * adultCountRound;
          adultTaxTotalsRound.CommonUserTerminalEquipment +=
            ptc.CUTE * adultCountRound;
          adultTaxTotalsRound.CongestionFee +=
            parseFloat(ptc.YR) * adultCountRound;
          adultTaxTotalsRound.RCF += parseFloat(ptc.RCF) * adultCountRound;
          adultTaxTotalsRound.PHF += parseFloat(ptc.PHF) * adultCountRound;
          adultTaxTotalsRound.UserDevelopmentFee +=
            parseFloat(ptc.UD) * adultCountRound;
          adultTaxTotalsRound.APITax += parseFloat(ptc.API) * adultCountRound;
          adultTaxTotalsRound.PassengerServiceTax +=
            parseFloat(ptc.PSF) * adultCountRound;
          adultTaxTotalsRound.GST += parseFloat(ptc.K3) * adultCountRound;
          adultTaxTotalsRound.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * adultCountRound;
          break;
        case "CHD":
          const otValuesChild = ptc?.OT?.split(",").map(
            (value) => parseFloat(value.trim()) || 0
          );

          const otSumChild = otValuesChild?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          childTaxTotalsRound.AirlineMisc +=
            (otSumChild + parseFloat(ptc.ST)) * childCountRound;
          childTaxTotalsRound.FuelTax += parseFloat(ptc.YQ) * childCountRound;
          childTaxTotalsRound.RCS += ptc.RCS * childCountRound;
          childTaxTotalsRound.CommonUserTerminalEquipment +=
            ptc.CUTE * childCountRound;
          childTaxTotalsRound.CongestionFee +=
            parseFloat(ptc.YR) * childCountRound;
          childTaxTotalsRound.RCF += parseFloat(ptc.RCF) * childCountRound;
          childTaxTotalsRound.PHF += parseFloat(ptc.PHF) * childCountRound;
          childTaxTotalsRound.UserDevelopmentFee +=
            parseFloat(ptc.UD) * childCountRound;
          childTaxTotalsRound.APITax += parseFloat(ptc.API) * childCountRound;
          childTaxTotalsRound.PassengerServiceTax +=
            parseFloat(ptc.PSF) * childCountRound;
          childTaxTotalsRound.GST += parseFloat(ptc.K3) * childCountRound;
          childTaxTotalsRound.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * childCountRound;
          break;
        case "INF":
          const otValuesInfant = ptc?.OT?.split(",").map(
            (value) => parseFloat(value.trim()) || 0
          );

          const otSumInfant = otValuesInfant?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          infantTaxTotalsRound.AirlineMisc +=
            (otSumInfant + parseFloat(ptc.ST)) * infantCountRound;
          infantTaxTotalsRound.FuelTax += parseFloat(ptc.YQ) * infantCountRound;
          infantTaxTotalsRound.RCS += ptc.RCS * infantCountRound;
          infantTaxTotalsRound.CommonUserTerminalEquipment +=
            ptc.CUTE * infantCountRound;
          infantTaxTotalsRound.CongestionFee +=
            parseFloat(ptc.YR) * infantCountRound;
          infantTaxTotalsRound.RCF += parseFloat(ptc.RCF) * infantCountRound;
          infantTaxTotalsRound.PHF += parseFloat(ptc.PHF) * infantCountRound;
          infantTaxTotalsRound.UserDevelopmentFee +=
            parseFloat(ptc.UD) * infantCountRound;
          infantTaxTotalsRound.APITax += parseFloat(ptc.API) * infantCountRound;
          infantTaxTotalsRound.PassengerServiceTax +=
            parseFloat(ptc.PSF) * infantCountRound;
          infantTaxTotalsRound.GST += parseFloat(ptc.K3) * infantCountRound;
          infantTaxTotalsRound.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * infantCountRound;
          break;
        default:
      }
    }
  );

  const taxTotalsRound: any = {
    AirlineMisc: 0 + storedAddonPercent ? Math.round(storedAddonPercent) : 0,
    RCS: 0,
    CommonUserTerminalEquipment: 0,
    FuelTax: 0,
    PHF: 0,
    RCF: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  function calculateFareRound(tax: any, passengerCount: number) {
    const otValues =
      typeof tax?.OT === "string"
        ? tax?.OT?.split(",").map(
            (value: string) => parseFloat(value.trim()) || 0
          )
        : [];

    const otSum = otValues?.reduce(
      (acc: number, currentValue: number) => acc + currentValue,
      0
    );

    return {
      AirlineMisc: (otSum + parseFloat(tax.ST)) * passengerCount,
      RCS: parseFloat(tax.RCS) * passengerCount || 0,
      FuelTax: parseFloat(tax.YQ) * passengerCount || 0,
      CongestionFee: parseFloat(tax.YR) * passengerCount || 0,
      RCF: parseFloat(tax.RCF) * passengerCount || 0,
      PHF: parseFloat(tax.PHF) * passengerCount || 0,
      UserDevelopmentFee: parseFloat(tax.UD) * passengerCount || 0,
      APITax: parseFloat(tax.API) * passengerCount || 0,
      CommonUserTerminalEquipment: parseFloat(tax.CUTE) * passengerCount || 0,
      PassengerServiceTax: parseFloat(tax.PSF) * passengerCount || 0,
      GST: parseFloat(tax.K3) * passengerCount || 0,
      VATonServiceCharge:
        parseFloat(tax.VATonServiceCharge) * passengerCount || 0,
    };
  }

  ptcForRound?.forEach((ptc: any) => {
    const ptcType = ptc.PTC;
    const passengerCount =
      ptcType === "ADT"
        ? adultCount
        : ptcType === "CHD"
        ? childCount
        : infantCount;

    const fareDetails: any = calculateFareRound(ptc, passengerCount);

    for (const key in taxTotalsRound) {
      taxTotalsRound[key] += fareDetails[key];
    }
  });

  const taxFareRoundTrip = Object.values(taxTotalsRound)?.reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  const baseFareRoundTrip =
    checkReturn &&
    roundFirst[0]?.Fares?.TotalBaseFare + roundSecond[0]?.Fares?.TotalBaseFare;

  const commissionRoundTrip =
    checkReturn &&
    roundFirst[0]?.Fares?.TotalCommission +
      roundSecond[0]?.Fares?.TotalCommission;

  const discountCalcRound = markedPrice - sellingPrice;

  const discountRateRound = markedPrice
    ? Math.round(discountCalcRound + commissionRoundTrip)
    : commissionRoundTrip;

  const totalFareRoundTrip =
    baseFareRoundTrip + taxFareRoundTrip - discountRateRound;

  //Multi city Fare

  const flightObj =
    multiCityFlights &&
    multiCityFlights.map((rules: any) => rules?.Segments[0]?.Fares.PTCFare);

  const combinedArrayMultiCity = flightObj.flat()?.reduce(
    (
      accumulator: any[],
      currentObj: {
        PTC: any;
        Fare: any;
        ST: any;
        YQ: any;
        UD: any;
        YR: any;
        RCF: any;
        PHF: any;
        API: any;
        PSF: any;
        K3: any;
        OT: any;
        VATonServiceCharge: any;
      }
    ) => {
      const index = accumulator.findIndex(
        (item) => item.PTC === currentObj.PTC
      );

      if (index !== -1) {
        accumulator[index].Fare += currentObj.Fare;
        accumulator[index].ST += currentObj.ST;
        accumulator[index].YQ += currentObj.YQ;
        accumulator[index].UD += currentObj.UD;
        accumulator[index].YR += currentObj.YR;
        accumulator[index].RCF += currentObj.RCF;
        accumulator[index].PHF += currentObj.PHF;
        accumulator[index].API += currentObj.API;
        accumulator[index].PSF += currentObj.PSF;
        accumulator[index].K3 += currentObj.K3;
        accumulator[index].VATonServiceCharge += currentObj.VATonServiceCharge;
        accumulator[index].OT += `, ${currentObj.OT}`;
      } else {
        accumulator.push({
          ...currentObj,
        });
      }
      return accumulator;
    },
    []
  );

  const multiPtcFares = combinedArrayMultiCity || [];

  const multiAdultCount = flightSearchDetails.ADT || 0;
  const multiChildCount = flightSearchDetails.CHD || 0;
  const multiInfantCount = flightSearchDetails.INF || 0;

  const multiAdultTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    RCF: 0,
    PHF: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const multiChildTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    RCF: 0,
    PHF: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const multiInfantTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    RCF: 0,
    PHF: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  multiPtcFares?.forEach(
    (ptc: {
      PTC: any;
      OT: string;
      ST: string;
      YQ: string;
      YR: string;
      RCF: string;
      PHF: string;
      UD: string;
      API: string;
      PSF: string;
      K3: string;
      VATonServiceCharge: string;
    }) => {
      const ptcType = ptc.PTC;

      switch (ptcType) {
        case "ADT":
          const otValues =
            typeof ptc?.OT === "string"
              ? ptc?.OT.split(",").map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSum = otValues?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );

          multiAdultTaxTotals.AirlineMisc +=
            (otSum + parseFloat(ptc.ST)) * multiAdultCount;
          multiAdultTaxTotals.FuelTax += parseFloat(ptc.YQ) * multiAdultCount;
          multiAdultTaxTotals.CongestionFee +=
            parseFloat(ptc.YR) * multiAdultCount;
          multiAdultTaxTotals.RCF += parseFloat(ptc.RCF) * multiAdultCount;
          multiAdultTaxTotals.PHF += parseFloat(ptc.PHF) * multiAdultCount;
          multiAdultTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * multiAdultCount;
          multiAdultTaxTotals.APITax += parseFloat(ptc.API) * multiAdultCount;
          multiAdultTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * multiAdultCount;
          multiAdultTaxTotals.GST += parseFloat(ptc.K3) * multiAdultCount;
          multiAdultTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * multiAdultCount;
          break;
        case "CHD":
          const otValuesChild =
            typeof ptc?.OT === "string"
              ? ptc?.OT.split(",").map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumChild = otValuesChild?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          multiChildTaxTotals.AirlineMisc +=
            (otSumChild + parseFloat(ptc.ST)) * multiChildCount;
          multiChildTaxTotals.FuelTax += parseFloat(ptc.YQ) * multiChildCount;
          multiChildTaxTotals.CongestionFee +=
            parseFloat(ptc.YR) * multiChildCount;
          multiChildTaxTotals.RCF += parseFloat(ptc.RCF) * multiChildCount;
          multiChildTaxTotals.PHF += parseFloat(ptc.PHF) * multiChildCount;
          multiChildTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * multiChildCount;
          multiChildTaxTotals.APITax += parseFloat(ptc.API) * multiChildCount;
          multiChildTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * multiChildCount;
          multiChildTaxTotals.GST += parseFloat(ptc.K3) * multiChildCount;
          multiChildTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * multiChildCount;
          break;
        case "INF":
          const otValuesInfant =
            typeof ptc?.OT === "string"
              ? ptc?.OT.split(",").map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumInfant = otValuesInfant?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          multiInfantTaxTotals.AirlineMisc +=
            (otSumInfant + parseFloat(ptc.ST)) * multiInfantCount;
          multiInfantTaxTotals.FuelTax += parseFloat(ptc.YQ) * multiInfantCount;
          multiInfantTaxTotals.CongestionFee +=
            parseFloat(ptc.YR) * multiInfantCount;
          multiInfantTaxTotals.RCF += parseFloat(ptc.RCF) * multiInfantCount;
          multiInfantTaxTotals.PHF += parseFloat(ptc.PHF) * multiInfantCount;
          multiInfantTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * multiInfantCount;
          multiInfantTaxTotals.APITax += parseFloat(ptc.API) * multiInfantCount;
          multiInfantTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * multiInfantCount;
          multiInfantTaxTotals.GST += parseFloat(ptc.K3) * multiInfantCount;
          multiInfantTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * multiInfantCount;
          break;
        default:
      }
    }
  );

  const multiTaxTotals: any = {
    AirlineMisc: 0 + storedAddonPercent ? storedAddonPercent : 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  function calculateFareMulti(tax: any, passengerCount: number) {
    const otValues =
      typeof tax.OT === "string"
        ? tax.OT.split(",").map(
            (value: string) => parseFloat(value.trim()) || 0
          )
        : [];

    const otSum = otValues?.reduce(
      (acc: number, currentValue: number) => acc + currentValue,
      0
    );

    return {
      AirlineMisc: (otSum + parseFloat(tax.ST)) * passengerCount,
      FuelTax: parseFloat(tax.YQ) * passengerCount || 0,
      CongestionFee: parseFloat(tax.YR) * passengerCount || 0,
      RCF: parseFloat(tax.RCF) * passengerCount || 0,
      PHF: parseFloat(tax.PHF) * passengerCount || 0,
      UserDevelopmentFee: parseFloat(tax.UD) * passengerCount || 0,
      APITax: parseFloat(tax.API) * passengerCount || 0,
      PassengerServiceTax: parseFloat(tax.PSF) * passengerCount || 0,
      GST: parseFloat(tax.K3) * passengerCount || 0,
      VATonServiceCharge:
        parseFloat(tax.VATonServiceCharge) * passengerCount || 0,
    };
  }

  multiPtcFares?.forEach((ptc: any) => {
    const ptcType = ptc.PTC;
    const passengerCount =
      ptcType === "ADT"
        ? multiAdultCount
        : ptcType === "CHD"
        ? multiChildCount
        : multiInfantCount;

    const fareDetails: any = calculateFareMulti(ptc, passengerCount);

    for (const key in multiTaxTotals) {
      multiTaxTotals[key] += fareDetails[key];
    }
  });

  const multiTotalTaxAmount = Object.values(multiTaxTotals)?.reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  const multiTotalFares = combinedArrayMultiCity.map((fare: any) => {
    if (fare.PTC === "ADT") {
      return multiAdultCount * fare.Fare;
    } else if (fare.PTC === "CHD") {
      return multiChildCount * fare.Fare;
    } else if (fare.PTC === "INF") {
      return multiInfantCount * fare.Fare;
    } else {
      return 0;
    }
  });

  const flightObjForCommission =
    multiCityFlights &&
    multiCityFlights.map((rules: any) => rules?.Segments[0]?.Fares).flat();

  const commissionArray = flightObjForCommission.map((fare: any) => {
    let commission = 0;

    if (fare.PTCFare[0].PTC === "ADT") {
      commission = flightSearchDetails.ADT * (fare.TotalCommission || 0);
    } else if (fare.PTCFare[0].PTC === "CHD") {
      commission = flightSearchDetails.CHD * (fare.TotalCommission || 0);
    } else if (fare.PTCFare[0].PTC === "INF") {
      commission = flightSearchDetails.INF * (fare.TotalCommission || 0);
    }

    return commission;
  });

  const totalCommission = commissionArray?.reduce(
    (total: any, commission: any) => total + commission,
    0
  );

  const multiTotalBaseFare = multiTotalFares?.reduce(
    (acc: any, currentFare: any) => acc + currentFare,
    0
  );

  const discountCalcMulti = markedPrice - sellingPrice;

  const discountRateMulti = markedPrice
    ? Math.round(discountCalcMulti + totalCommission)
    : totalCommission;

  const totalFareMultiCity =
    multiTotalBaseFare + Number(multiTotalTaxAmount) - discountRateMulti;

  //const data = bookingDetails;
  // setBookingData(data);
  const baggage = "";
  const Data = bookingDetails;

  console.log("baggage", Data);

  const calculateTotalChargeByType = (type: any) => {
    if (!bookingDetails.SSR || bookingDetails.SSR.length === 0) {
      return 0;
    }

    const filteredSSR = bookingDetails.SSR.filter(
      (ssr: any) => ssr.Type === type
    );
    return filteredSSR.reduce((total: any, ssr: any) => total + ssr.Charge, 0);
  };

  const getTypeName = (type: string): string => {
    switch (type) {
      case "1":
        return "Meals";
      case "2":
        return "Baggage";
      case "3":
        return "Sports";
      case "9":
        return "Seat";
      case "8":
        return "Priority Baggage";
      default:
        return "Unknown";
    }
  };

  // const getTotalChargesByType = () => {
  //   const totalCharges: { [key: string]: number } = {};

  //   if (bookingDetails.SSR && bookingDetails.SSR.length > 0) {
  //     bookingDetails.SSR.forEach((ssr: any) => {
  //       if (!totalCharges[ssr.Type]) {
  //         totalCharges[ssr.Type] = 0;
  //       }
  //       totalCharges[ssr.Type] += ssr.Charge;
  //     });
  //   }

  //   return totalCharges;
  // };

  // const totalChargesByType = getTotalChargesByType();
  const getTotalChargesByType = () => {
    const totalCharges: { [key: string]: number } = {};
    let overallSum = 0;

    if (bookingDetails && bookingDetails.SSR && bookingDetails.SSR.length > 0) {
      bookingDetails.SSR.forEach((ssr: any) => {
        if (!totalCharges[ssr.Type]) {
          totalCharges[ssr.Type] = 0;
        }
        totalCharges[ssr.Type] += ssr.Charge;
        overallSum += ssr.Charge;
      });
    }

    return { totalCharges, overallSum };
  };

  const { totalCharges, overallSum } = getTotalChargesByType();

  const totalFareRoundTripFinal = totalFareRoundTrip + overallSum;
  console.log("totalFareRoundTripFinal", totalFareRoundTripFinal);

  const totalFareMultiCityFinal = totalFareMultiCity + overallSum;
  console.log("totalFareMultiCityFinal", totalFareMultiCityFinal);

  const getTotalFareCalc = localStorage.getItem("totalFareCalc");
  const gettotalBaseFar = localStorage.getItem("totalBaseFare");
  const getDiscountCalc = localStorage.getItem("discountCalcOneway");
  const discountRateRoundTrip = localStorage.getItem("discountRateRoundTrip");

  const gettotalBaseFarMultiCity = localStorage.getItem("totalBaseFare");
  const getDiscountCalcMulti = localStorage.getItem("discountCalcRoundMulti");

  const gettotalTaxAmountOneWay = localStorage.getItem("totalTaxAmountOneWay");
  const gettotalTaxAmountRoundTrip = localStorage.getItem(
    "totalTaxAmountRoundTrip"
  );

  const gettotalTaxAmountMultiCity = localStorage.getItem(
    "totalBaseFareMultiCity"
  );
  const [totalFinamlAmountOneWay, setTotalFinamlAmountOneWay] = useState<
    number | null
  >(null);

  if (gettotalTaxAmountOneWay !== null) {
    const totalFinamlAmount = parseFloat(gettotalTaxAmountOneWay) + overallSum;
    // setTotalFinamlAmountOneWay(totalFinamlAmount)
    console.log("totalChargesByType - totalFareCalcOne", totalFinamlAmount);
  } else {
    console.error("gettotalTaxAmountOneWay is null.");
  }

  if (gettotalTaxAmountRoundTrip !== null) {
    const totalFinamlAmount =
      parseFloat(gettotalTaxAmountRoundTrip) + overallSum;
    // setTotalFinamlAmountOneWay(totalFinamlAmount)
    console.log("gettotalTaxAmountRoundTrip", totalFinamlAmount);
  } else {
    console.error("gettotalTaxAmountRoundTrip is null.");
  }

  const seatValues: string | null = localStorage.getItem("ssrTolatAmount");

  console.log(bookingDetails, "booking details");

  return (
    <AppLayout>
      <main>
        <>
          <div className="md-stepper-horizontal orange">
            <div className="d-flex justify-content-evenly align-content-center flex-wrap">
              <div className="d-flex align-items-center gap-3">
                <div className="md-step active done">
                  {getPaymentStatus?.status === 2 &&
                    itineraryStatus !== undefined &&
                    itineraryStatus.PaymentStatus === "Success" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-success"
                        width="75"
                        height="75"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                      </svg>
                    )}

                  {itineraryStatus !== undefined &&
                    itineraryStatus.PaymentStatus === "Failed" && (
                      <LazyImage
                        className="waring_images"
                        src={failed_icon}
                        alt="warning-icon"
                      />
                    )}

                  {getPaymentStatus?.status === 3 ||
                    (getPaymentStatus?.status === 5 && (
                      <LazyImage
                        className="waring_images"
                        src={warning}
                        alt="warning-icon"
                      />
                    ))}
                </div>
                <div className="md-step active editable">
                  <div className="md-step-title">
                    <h2 className="h1-inner mb-0">
                      {itineraryStatus !== undefined &&
                      itineraryStatus.PaymentStatus === "Success"
                        ? "Thank You!"
                        : "Processing!"}
                    </h2>
                    <p>
                      {" "}
                      {itineraryStatus !== undefined &&
                      itineraryStatus.PaymentStatus === "Success"
                        ? "Your Payment has been Received "
                        : itineraryStatus !== undefined &&
                          itineraryStatus.PaymentStatus === "Failed"
                        ? "Your Payment has been Received "
                        : getPaymentStatus?.status === 5
                        ? "Your Payment has been Received "
                        : "Your Payment has been Received "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center res_padding">
                <div className="md-step active res_padding">
                  <div className="md-step-circle">
                    <span>
                      {itineraryStatus !== undefined &&
                      itineraryStatus.PaymentStatus === "Success" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-success"
                          width="75"
                          height="75"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          style={{
                            width: "33px",
                            marginTop: "-22px",
                            marginLeft: "-2px",
                            fill: "white",
                          }}
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                        </svg>
                      ) : (
                        <LazyImage
                          className="waring_images"
                          src={alert}
                          alt="warning-icon"
                        />
                      )}
                    </span>
                  </div>
                  <div className="md-step-title">
                    <h3 className="payment_heading">
                      {" "}
                      Payment Received
                      {/* {itineraryStatus !== undefined &&
                      itineraryStatus.PaymentStatus === "Success"
                        ? " Payment Received "
                        : itineraryStatus !== undefined &&
                          itineraryStatus.PaymentStatus === "Failed"
                        ? " Payment Received "
                        : getPaymentStatus?.status === 5
                        ? " Payment Received "
                        : " Payment Received "}{" "} */}
                    </h3>
                    <p className="payment-sub">
                      Payment received
                      {/* {itineraryStatus !== undefined &&
                      itineraryStatus.PaymentStatus === "Success"
                        ? `₹ ${
                            getPaymentStatus?.paymentResponse &&
                            getPaymentStatus?.paymentResponse?.net_amount_debit
                          } Payment received`
                        : itineraryStatus?.PaymentStatus === "Failed"
                        ? `₹ ${
                            getPaymentStatus?.paymentResponse &&
                            getPaymentStatus?.paymentResponse?.net_amount_debit
                          } Payment received`
                        : `₹ ${
                            getPaymentStatus?.paymentResponse &&
                            getPaymentStatus?.paymentResponse?.net_amount_debit
                          } Payment received`} */}
                    </p>
                  </div>
                  <div className="md-step-bar-right"></div>
                </div>

                <div className="md-step">
                  <div className="md-step-circle">
                    <span>
                      {itineraryStatus?.CurrentStatus &&
                      itineraryStatus?.CurrentStatus === "Failed" ? (
                        <LazyImage
                          className="waring_images"
                          src={failed_icon}
                          alt="warning-icon"
                        />
                      ) : itineraryStatus?.CurrentStatus &&
                        itineraryStatus?.CurrentStatus === "Success" ? (
                        <div className="md-step-circle">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-success"
                            width="75"
                            height="75"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            style={{ width: "inherit", marginTop: "-22.5px" }}
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                          </svg>
                        </div>
                      ) : (
                        <div
                          className="spinner-grow "
                          role="status"
                          style={{
                            color: "#ffcb4a",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div className="md-step-title">
                    <h3 className="payment_heading">
                      {itineraryStatus?.CurrentStatus &&
                      itineraryStatus?.CurrentStatus === "Success"
                        ? " Booking Success"
                        : itineraryStatus?.CurrentStatus &&
                          itineraryStatus?.CurrentStatus === "Failed"
                        ? " Booking Failed"
                        : " Booking In Progress"}
                      {/* <span
                        style={{
                          background: "#ed3237",
                          padding: "7px",
                          borderRadius: "50%",
                          position: "absolute",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => setRefreshBooking(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                          style={{
                            fill: "#fff",
                            width: "20px",
                            height: "20px",
                            marginBottom: "2px",
                          }}
                        >
                          <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0-32-14.3-32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"></path>
                        </svg>
                      </span> */}
                    </h3>
                    <p className="payment-sub">For Flight</p>
                  </div>
                  <div className="md-step-bar-left"></div>
                  <div className="md-step-bar-right"></div>
                </div>
              </div>
            </div>
          </div>

          {bookingDetails !== undefined ? (
            <div className="container pt-2">
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <h1 className="h1-inner mb-0">Booking confirmation</h1>
                  <div className="sub-heading-confirmation">
                    <span className="">
                      Booking no.: {bookingDetails.TransactionID} |{" "}
                      {bookingDetails?.ContactInfo[0].Email} | Booked on{" "}
                      {moment(bookingDetails.BookingDate).format(
                        "DD MMM, YYYY"
                      )}
                    </span>
                    {itineraryStatus?.CurrentStatus &&
                      itineraryStatus?.CurrentStatus === "Success" && (
                        <span
                          className="card-right first_card"
                          style={{ cursor: "pointer" }}
                          onClick={() => downloadBookingPDFHandler()}
                        >
                          <span>
                            <LazyImage
                              className="img-fluid"
                              src={fileArrow}
                              alt="view-icon"
                            />
                          </span>
                          Download
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="tabbing-bar mt-0" style={{ top: "0px" }}>
                <div className="tab-content" id="nav-tabContent">
                  <div>
                    <h3 className="text-table pb-3">
                      Passenger information (
                      {flightSearchDetails.ADT +
                        flightSearchDetails.CHD +
                        flightSearchDetails.INF}{" "}
                      )
                    </h3>
                    <table className="table mb-5">
                      <thead>
                        <tr>
                          <th
                            style={{ backgroundColor: "#cfe4f9 " }}
                            scope="col"
                          >
                            Sr No.
                          </th>
                          {/* <th
                            style={{ backgroundColor: "#cfe4f9 " }}
                            scope="col"
                          >
                            Title
                          </th> */}
                          <th
                            style={{ backgroundColor: "#cfe4f9 " }}
                            scope="col"
                          >
                            Full name
                          </th>

                          <th
                            style={{ backgroundColor: "#cfe4f9 " }}
                            scope="col"
                          >
                            {/* Age */}
                          </th>
                          <th
                            style={{ backgroundColor: "#cfe4f9 " }}
                            scope="col"
                          >
                            Ticket number
                          </th>
                          <th
                            style={{ backgroundColor: "#cfe4f9 " }}
                            scope="col "
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {mergedArrayTravelers?.map(
                          (passenger: any, index: number) => (
                            <tr>
                              <th scope="row" data-label="Sr No.">
                                {index + 1}
                              </th>
                              <td data-label="Full name">
                                {passenger.Title +
                                  "." +
                                  "  " +
                                  passenger.FName +
                                  " " +
                                  passenger.LName}
                              </td>
                              {/* <td data-label="Gender">{passenger.Title}</td> */}
                              <td data-label="Age">
                                {passenger.PTC === "ADT"
                                  ? "Adult"
                                  : passenger.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}{" "}
                              </td>
                              <td data-label="Ticket number">
                                {passenger.TicketNo === ""
                                  ? "-"
                                  : passenger.TicketNo}
                              </td>
                              <td className="status-co" data-label="Status">
                                {itineraryStatus?.CurrentStatus &&
                                  itineraryStatus?.CurrentStatus}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  {!Array.isArray(parsedItinerary) ? (
                    <>
                      {bookingDetails !== undefined &&
                      bookingDetails?.Trips.length === 1 ? (
                        <>
                          {allFlights.map((flight: any) => (
                            <>
                              {flight.Segments.map((segment: any) => (
                                <>
                                  <div className="py-3 block_main_container">
                                    <h3 className="h3-bold">
                                      {
                                        segment.Flight.DepAirportName.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                      to{" "}
                                      {
                                        segment.Flight.ArrAirportName.split(
                                          "|"
                                        )[1]
                                      }
                                    </h3>
                                    <span className="">
                                      <LazyImage
                                        className="img-fluid"
                                        src={airplaneTilt}
                                        alt=""
                                      />
                                      {moment(
                                        segment.Flight.DepartureTime
                                      ).format("DD MMM, YYYY")}
                                    </span>
                                  </div>
                                  <div className="card card_section my-2">
                                    <div className="card-body">
                                      <div className="main_content res_containt">
                                        <div className="block">
                                          <div className="card-img">
                                            <LazyImage
                                              className="img-fluid"
                                              src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${segment.Flight?.MAC}.jpg`}
                                              alt="segment.Flight?.MAC"
                                            />
                                          </div>
                                          <div className="card-content">
                                            <h3 className="h3-bold mb-0">
                                              {" "}
                                              {
                                                segment.Flight?.Airline?.split(
                                                  "|"
                                                )[0]
                                              }
                                            </h3>
                                            <p className="card-text">
                                              {" "}
                                              {processMAC(
                                                segment.Flight?.MAC
                                              )}{" "}
                                              {"-"} {segment.Flight?.FlightNo}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2">
                                              Departure
                                            </p>
                                            <h3 className="h3-bold">
                                              {moment(
                                                segment.Flight?.DepartureTime
                                              ).format("LT")}
                                            </h3>
                                            <p className="card-text">
                                              {" "}
                                              {
                                                segment.Flight.DepAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block res_icon_block">
                                          <div className="card-content content_body">
                                            <p className="card-text mb-2"></p>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className="left-image">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  fill="currentColor"
                                                  viewBox="0 0 256 256"
                                                  data-testid="originIcon"
                                                >
                                                  <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                                </svg>
                                              </div>
                                              {checkConnectingFlights.length >
                                              0 ? (
                                                <div className="dot-arrow">
                                                  <span className="dot-span">
                                                    {" "}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="dot-arrow">
                                                  <span
                                                    className="dot-span"
                                                    style={{
                                                      visibility: "hidden",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                </div>
                                              )}

                                              <div className="left-image">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  fill="currentColor"
                                                  viewBox="0 0 256 256"
                                                  data-testid="destinationIcon"
                                                >
                                                  <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.90A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                                </svg>
                                              </div>
                                            </div>

                                            <p className="card-text">
                                              {checkConnectingFlights.length}{" "}
                                              stop
                                            </p>
                                            <p
                                              className="card-text"
                                              style={{
                                                visibility: "hidden",
                                                height: "0px",
                                              }}
                                            >
                                              some some some{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2">
                                              Arrival
                                            </p>
                                            <h3 className="h3-bold">
                                              {" "}
                                              {moment(
                                                segment.Flight?.ArrivalTime
                                              ).format("LT")}
                                            </h3>
                                            <p className="card-text">
                                              {" "}
                                              {
                                                segment.Flight.ArrAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2">
                                              Travel duration
                                            </p>
                                            <h3 className="h3-bold">
                                              {" "}
                                              {segment.Flight.Duration}
                                            </h3>
                                            <p className="card-text"></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          {flightReturnInfo.map((trip: any) =>
                            trip.Journey.map((journey: any) =>
                              journey.Segments.map((segment: any) => (
                                <>
                                  <div className="py-3 block_main_container">
                                    <h3 className="h3-bold">
                                      {
                                        segment?.Flight.DepAirportName.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                      to{" "}
                                      {
                                        segment?.Flight.ArrAirportName.split(
                                          "|"
                                        )[1]
                                      }
                                    </h3>
                                    <span className="">
                                      <LazyImage
                                        className="img-fluid"
                                        src={airplaneTilt}
                                        alt=""
                                      />
                                      {moment(
                                        segment?.Flight.DepartureTime
                                      ).format("DD MMM, YYYY")}
                                    </span>
                                  </div>
                                  <div className="card card_section my-2">
                                    <div className="card-body">
                                      <div className="main_content res_containt">
                                        <div className="block">
                                          <div className="card-img">
                                            <LazyImage
                                              className="img-fluid"
                                              src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${segment?.Flight?.MAC}.jpg`}
                                              alt="segment?.Flight?.MAC"
                                            />
                                          </div>
                                          <div className="card-content">
                                            <h3 className="h3-bold mb-0">
                                              {
                                                segment?.Flight?.Airline?.split(
                                                  "|"
                                                )[0]
                                              }
                                            </h3>
                                            <p className="card-text">
                                              {processMAC(flightInfo?.MAC)} -{" "}
                                              {flightInfo?.FlightNo}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2">
                                              Departure
                                            </p>
                                            <h3 className="h3-bold">
                                              {moment(
                                                segment?.Flight?.DepartureTime
                                              ).format("LT")}
                                            </h3>
                                            <p className="card-text">
                                              {
                                                segment?.Flight.DepAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content content_body">
                                            <p className="card-text mb-2"></p>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className="left-image">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  fill="currentColor"
                                                  viewBox="0 0 256 256"
                                                  data-testid="originIcon"
                                                >
                                                  <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                                </svg>
                                              </div>
                                              {checkConnectingFlights.length >
                                              0 ? (
                                                <div className="dot-arrow">
                                                  <span className="dot-span">
                                                    {" "}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="dot-arrow">
                                                  <span
                                                    className="dot-span"
                                                    style={{
                                                      visibility: "hidden",
                                                    }}
                                                  >
                                                    {" "}
                                                  </span>
                                                </div>
                                              )}

                                              <div className="left-image">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  fill="currentColor"
                                                  viewBox="0 0 256 256"
                                                  data-testid="destinationIcon"
                                                >
                                                  <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.90A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                                </svg>
                                              </div>
                                            </div>
                                            <p className="card-text">
                                              {checkConnectingFlights.length}{" "}
                                              stop
                                            </p>
                                            <p
                                              className="card-text"
                                              style={{
                                                visibility: "hidden",
                                                height: "0px",
                                              }}
                                            >
                                              some some some{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2">
                                              Arrival
                                            </p>
                                            <h3 className="h3-bold">
                                              {moment(
                                                segment?.Flight?.ArrivalTime
                                              ).format("LT")}
                                            </h3>
                                            <p className="card-text">
                                              {
                                                segment?.Flight.ArrAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2">
                                              Travel duration
                                            </p>
                                            <h3 className="h3-bold">
                                              {segment?.Flight.Duration}
                                            </h3>
                                            <p className="card-text"></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))
                            )
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {multiCityFlights.length > 0 &&
                        multiCityFlights?.map((journey: any) =>
                          journey?.Segments?.map((segment: any) => (
                            <>
                              <div className="py-3 block_main_container">
                                <h3 className="h3-bold">
                                  {segment?.Flight.DepAirportName.split("|")[1]}{" "}
                                  to{" "}
                                  {segment?.Flight.ArrAirportName.split("|")[1]}
                                </h3>
                                <span className="">
                                  <LazyImage
                                    className="img-fluid"
                                    src={airplaneTilt}
                                    alt=""
                                  />
                                  {moment(segment?.Flight.DepartureTime).format(
                                    "DD MMM, YYYY"
                                  )}
                                </span>
                              </div>
                              <div className="card card_section my-2">
                                <div className="card-body">
                                  <div className="main_content res_containt">
                                    <div className="block">
                                      <div className="card-img">
                                        <LazyImage
                                          className="img-fluid"
                                          src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${segment?.Flight?.MAC}.jpg`}
                                          alt="segment?.Flight?.MAC"
                                        />
                                      </div>
                                      <div className="card-content">
                                        <h3 className="h3-bold mb-0">
                                          {
                                            segment?.Flight?.Airline?.split(
                                              "|"
                                            )[0]
                                          }
                                        </h3>
                                        <p className="card-text">
                                          {processMAC(flightInfo?.MAC)} -{" "}
                                          {flightInfo?.FlightNo}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2">
                                          Departure
                                        </p>
                                        <h3 className="h3-bold">
                                          {moment(
                                            segment?.Flight?.DepartureTime
                                          ).format("LT")}
                                        </h3>
                                        <p className="card-text">
                                          {
                                            segment?.Flight.DepAirportName.split(
                                              "|"
                                            )[1]
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content content_body">
                                        <p className="card-text mb-2"></p>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="left-image">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              fill="currentColor"
                                              viewBox="0 0 256 256"
                                              data-testid="originIcon"
                                            >
                                              <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                            </svg>
                                          </div>
                                          {segment?.Flight?.Hops > 0 ? (
                                            <div className="dot-arrow">
                                              <span className="dot-span">
                                                {" "}
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="dot-arrow">
                                              <span
                                                className="dot-span"
                                                style={{ visibility: "hidden" }}
                                              >
                                                {" "}
                                              </span>
                                            </div>
                                          )}

                                          <div className="left-image">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              fill="currentColor"
                                              viewBox="0 0 256 256"
                                              data-testid="destinationIcon"
                                            >
                                              <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.90A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                        <p className="card-text">
                                          {segment?.Flight?.Hops?.length} stop
                                        </p>
                                        <p
                                          className="card-text"
                                          style={{
                                            visibility: "hidden",
                                            height: "0px",
                                          }}
                                        >
                                          some some some{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2">
                                          Arrival
                                        </p>
                                        <h3 className="h3-bold">
                                          {moment(
                                            segment?.Flight?.ArrivalTime
                                          ).format("LT")}
                                        </h3>
                                        <p className="card-text">
                                          {
                                            segment?.Flight.ArrAirportName.split(
                                              "|"
                                            )[1]
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2">
                                          Travel duration
                                        </p>
                                        <h3 className="h3-bold">
                                          {segment?.Flight.Duration}
                                        </h3>
                                        <p className="card-text"></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        )}
                    </>
                  )}

                  <div className="row mt-5">
                    <div className="col-sm-7 col-md-7 mb-3 res-padding">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button fw-bold"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Cancellation policy and Terms & conditions
                            </button>
                          </h2>
                          {fareRuleText === null ? (
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              {" "}
                              NONE
                            </div>
                          ) : (
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <ul
                                  className="confirmation-content"
                                  style={{ padding: "10px" }}
                                >
                                  <li>{fareRuleText}</li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button fw-bold collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Important Information for Checkin
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul
                                className="confirmation-content"
                                style={{ padding: "10px" }}
                              >
                                <li>
                                  All Guests, including children and infants,
                                  must present valid identification at check-in.
                                </li>
                                <li>
                                  Check-in begins 2 hours prior to the flight
                                  for seat assignment and closes 60 minutes
                                  prior to the scheduled departure.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-5 col-ms-5 box-ind-main"
                      style={{ padding: "30px 13px" }}
                    >
                      {!Array.isArray(parsedItinerary) ? (
                        <>
                          <div className="w-100 px-3">
                            {bookingDetails !== undefined &&
                            bookingDetails?.Trips.length === 1 ? (
                              <>
                                <div className="confirm-box">
                                  <h3
                                    className="h3-bold"
                                    id="one way Fare Summary"
                                  >
                                    Fare Summary
                                  </h3>
                                  <div className="main-box">
                                    {toggleFare === "base" ? (
                                      <LazyImage
                                        className="image-arrow active"
                                        width={18}
                                        height={18}
                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                        alt="collapse-arrow"
                                      />
                                    ) : (
                                      <LazyImage
                                        className="image-arrow"
                                        width={18}
                                        height={18}
                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                        alt="collapse-arrow"
                                      />
                                    )}

                                    <div
                                      className="box-header accordion"
                                      id="acc_image_first"
                                      onClick={() =>
                                        setToggleFare(
                                          toggleFare === "base" ? "" : "base"
                                        )
                                      }
                                    >
                                      <h3 className="box-heading">Base Fare</h3>
                                      <h3 className="box-heading">
                                        {" "}
                                        ₹
                                        {baseFareDetails.baseFare.toLocaleString(
                                          "en-IN"
                                        )}
                                      </h3>
                                    </div>
                                    <ul
                                      className="box-dropdown w3-animate-left"
                                      id="box_first"
                                      style={{
                                        display:
                                          toggleFare === "base"
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <li className="box-content">
                                        {baseFareDetails?.ptcFare &&
                                          baseFareDetails?.ptcFare?.map(
                                            (fare: any, index: number) => (
                                              <>
                                                <div
                                                  className="box-subcontent"
                                                  key={`index-flight-${index}`}
                                                >
                                                  <p className="box-list">
                                                    {" "}
                                                    {fare?.PTC === "ADT"
                                                      ? "Adult"
                                                      : fare?.PTC === "CHD"
                                                      ? "Child"
                                                      : "Infant"}{" "}
                                                    (
                                                    {fare.PTC === "ADT"
                                                      ? flightSearchDetails?.ADT
                                                      : fare?.PTC === "CHD"
                                                      ? flightSearchDetails?.CHD
                                                      : flightSearchDetails?.INF}{" "}
                                                    X ₹ {fare?.Fare})
                                                  </p>{" "}
                                                  <p className="box-list">
                                                    ₹{" "}
                                                    {fare.PTC === "ADT"
                                                      ? flightSearchDetails?.ADT *
                                                        fare?.Fare
                                                      : fare?.PTC === "CHD"
                                                      ? flightSearchDetails?.CHD *
                                                        fare?.Fare
                                                      : flightSearchDetails?.INF *
                                                        fare?.Fare}
                                                  </p>
                                                </div>
                                              </>
                                            )
                                          )}
                                      </li>
                                    </ul>
                                  </div>
                                  <hr className="mb-0" />
                                  <div className="main-box">
                                    {toggleFare === "tax" ? (
                                      <LazyImage
                                        className="image-arrow active"
                                        width={18}
                                        height={18}
                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                        alt="collapse-arrow"
                                      />
                                    ) : (
                                      <LazyImage
                                        className="image-arrow"
                                        width={18}
                                        height={18}
                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                        alt="collapse-arrow"
                                      />
                                    )}
                                    <div
                                      className="box-header accordion"
                                      id="acc_image_third"
                                    >
                                      <h3
                                        className="box-heading"
                                        onClick={() =>
                                          setToggleFare(
                                            toggleFare === "tax" ? "" : "tax"
                                          )
                                        }
                                      >
                                        Tax and charges
                                      </h3>
                                      <h3 className="box-heading">
                                        ₹ {gettotalTaxAmountOneWay}
                                        {/* {Number(totalTaxAmount)?.toLocaleString(
                                          "en-IN"
                                        )} */}
                                      </h3>
                                    </div>
                                    <ul
                                      className="box-dropdown w3-animate-left"
                                      style={{
                                        display:
                                          toggleFare === "tax"
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <div className="box-subcontent">
                                        <p className="box-list">
                                          Tax and charges
                                        </p>
                                        <p className="box-list">
                                          ₹ {gettotalTaxAmountOneWay}
                                          {/* {Number(
                                                  discountRateOneWay
                                                )?.toLocaleString("en-IN")} */}
                                        </p>
                                      </div>
                                      <>
                                        {/* {Object.keys(taxTotals)?.map(
                                          (taxType: any) => (
                                            <>
                                              {taxTotals[taxType] > 0 && (
                                                <li
                                                  className="box-content"
                                                  key={taxType}
                                                >
                                                  <div className="box-subcontent">
                                                    <p className="box-list">
                                                      {taxType}
                                                    </p>
                                                    <p className="box-list">
                                                      ₹ {taxTotals[taxType]}
                                                    </p>
                                                  </div>
                                                </li>
                                              )}
                                            </>
                                          )
                                        )} */}
                                      </>
                                    </ul>
                                  </div>

                                  {discountRateOneWay > 0 && (
                                    <>
                                      <hr
                                        className="mb-0"
                                        style={{ height: " 0px" }}
                                      />
                                      <div className="main-box">
                                        {toggleFare === "discount" ? (
                                          <LazyImage
                                            className="image-arrow active"
                                            width={18}
                                            height={18}
                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                            alt="collapse-arrow"
                                          />
                                        ) : (
                                          <LazyImage
                                            className="image-arrow"
                                            width={18}
                                            height={18}
                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                            alt="collapse-arrow"
                                          />
                                        )}
                                        <div
                                          className="box-header accordion"
                                          id="acc_image_third"
                                        >
                                          <h3
                                            className="box-heading"
                                            onClick={() =>
                                              setToggleFare(
                                                toggleFare === "discount"
                                                  ? ""
                                                  : "discount"
                                              )
                                            }
                                          >
                                            Discount
                                          </h3>
                                          <h3 className="box-heading">
                                            ₹ {getDiscountCalc}
                                            {/* {Number(
                                              discountRateOneWay
                                            )?.toLocaleString("en-IN")} */}
                                          </h3>
                                        </div>
                                        <ul
                                          className="box-dropdown w3-animate-left"
                                          style={{
                                            display:
                                              toggleFare === "discount"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <li className="box-content">
                                            <div className="box-subcontent">
                                              <p className="box-list">
                                                Discount
                                              </p>
                                              <p className="box-list">
                                                ₹ {getDiscountCalc}
                                                {/* {Number(
                                                  discountRateOneWay
                                                )?.toLocaleString("en-IN")} */}
                                              </p>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </>
                                  )}
                                  <hr className="mb-0" />
                                  {bookingDetails.SSR &&
                                    bookingDetails.SSR.length > 0 && (
                                      <>
                                        {Array.from(
                                          new Set(
                                            bookingDetails.SSR.map(
                                              (ssr: any) => ssr.Type
                                            )
                                          )
                                        ).map((type: any, index) => (
                                          <div
                                            key={index}
                                            className="block block_right"
                                            style={{ paddingRight: "15px" }}
                                          >
                                            {calculateTotalChargeByType(type) >
                                              0 && (
                                              <ul>
                                                <li className="py-2 px-2">
                                                  <div
                                                    className="form-check px-0"
                                                    onClick={() =>
                                                      setShowHideBase(
                                                        showHideBase === type
                                                          ? ""
                                                          : type
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <div className="d-flex">
                                                      <span className="box-heading">
                                                        {" "}
                                                        {showHideBase ===
                                                        type ? (
                                                          <LazyImage
                                                            className="image_arrow active"
                                                            width={18}
                                                            height={18}
                                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                            alt="collapse-arrow"
                                                          />
                                                        ) : (
                                                          <LazyImage
                                                            className="image_arrow"
                                                            id="image_first"
                                                            width={18}
                                                            height={18}
                                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                            alt="collapse-arrow"
                                                          />
                                                        )}
                                                        &nbsp;
                                                        {getTypeName(type)}
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <span className="box-heading">
                                                    ₹{" "}
                                                    {calculateTotalChargeByType(
                                                      type
                                                    )}
                                                  </span>
                                                </li>
                                                {showHideBase === type && (
                                                  <>
                                                    <li className="py-1 listing_item px-3">
                                                      <div className="form-check px-0">
                                                        <div className="d-flex">
                                                          {getTypeName(type)}
                                                        </div>
                                                      </div>
                                                      <span className="">
                                                        ₹{" "}
                                                        {calculateTotalChargeByType(
                                                          type
                                                        )}
                                                      </span>
                                                    </li>
                                                  </>
                                                )}
                                                <hr className="mb-0" />
                                              </ul>
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  {seatValues !== null &&
                                    Number(seatValues) !== 0 && (
                                      <>
                                        <div
                                          className="block block_right"
                                          style={{ paddingRight: "15px" }}
                                        >
                                          <ul>
                                            <li className="py-1">
                                              <div
                                                className="form-check px-0"
                                                onClick={() =>
                                                  setShowHideBase(
                                                    showHideBase ===
                                                      "showSeatAmount"
                                                      ? ""
                                                      : "showSeatAmount"
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                <div className="d-flex">
                                                  <span className="box-heading">
                                                    {" "}
                                                    {showHideBase ===
                                                    "showSeatAmount" ? (
                                                      <LazyImage
                                                        className="image_arrow active"
                                                        width={18}
                                                        height={18}
                                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                        alt="collapse-arrow"
                                                      />
                                                    ) : (
                                                      <LazyImage
                                                        className="image_arrow"
                                                        id="image_first"
                                                        width={18}
                                                        height={18}
                                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                        alt="collapse-arrow"
                                                      />
                                                    )}
                                                    &nbsp;Seats
                                                  </span>
                                                </div>
                                              </div>
                                              <span className="box-heading">
                                                ₹ {seatValues}
                                              </span>
                                            </li>
                                            {showHideBase ===
                                              "showSeatAmount" && (
                                              <>
                                                <li className="py-1 listing_item px-3">
                                                  <div className="form-check px-0">
                                                    <div className="d-flex">
                                                      Seats
                                                    </div>
                                                  </div>
                                                  <span className="">
                                                    {/* ₹ {SeatAmount?.toLocaleString("en-IN")} */}
                                                    ₹ {seatValues}
                                                  </span>
                                                </li>
                                              </>
                                            )}
                                          </ul>
                                        </div>
                                        <hr />
                                      </>
                                    )}

                                  <div className="main-box">
                                    <div
                                      className="box-header accordion"
                                      id="acc_image_five"
                                    >
                                      <h3 className="box-heading">
                                        Total Amount:
                                      </h3>
                                      <h3 className="box-heading">
                                        ₹{" "}
                                        {getPaymentStatus?.paymentResponse &&
                                          getPaymentStatus?.paymentResponse
                                            ?.net_amount_debit}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="confirm-box">
                                  <h3
                                    className="h3-bold"
                                    id="round trip Fare Summary"
                                  >
                                    Fare Summary
                                  </h3>

                                  <div
                                    className="block block_right"
                                    style={{
                                      paddingRight: "15px",
                                      paddingLeft: "15px",
                                    }}
                                  >
                                    <ul>
                                      <li className="py-1">
                                        <div
                                          className="form-check px-0"
                                          onClick={() =>
                                            setToggleFare(
                                              toggleFare === "base"
                                                ? ""
                                                : "base"
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="d-flex">
                                            <span className="box-heading">
                                              {" "}
                                              {toggleFare === "base" ? (
                                                <LazyImage
                                                  className="image_arrow active"
                                                  width={18}
                                                  height={18}
                                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                  alt="collapse-arrow"
                                                />
                                              ) : (
                                                <LazyImage
                                                  className="image_arrow"
                                                  id="image_first"
                                                  width={18}
                                                  height={18}
                                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                  alt="collapse-arrow"
                                                />
                                              )}
                                              &nbsp;Base Fare{" "}
                                            </span>
                                          </div>
                                        </div>
                                        <span className="box-heading">
                                          ₹
                                          {baseFareRoundTrip?.toLocaleString(
                                            "en-IN"
                                          )}
                                        </span>
                                      </li>
                                      <li
                                        className="py-1"
                                        style={{
                                          display:
                                            toggleFare === "base"
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        {roundTripFareDetails.map(
                                          (fare: any, index: number) => (
                                            <div
                                              className="form-check px-0"
                                              key={`sidebar-round-checkout-${index}`}
                                            >
                                              <div
                                                className="d-flex"
                                                style={{ marginRight: "auto" }}
                                              >
                                                {fare.PTC === "ADT"
                                                  ? "Adult"
                                                  : fare.PTC === "CHD"
                                                  ? "Child"
                                                  : "Infant"}{" "}
                                                (
                                                {fare.PTC === "ADT"
                                                  ? flightSearchDetails.ADT
                                                  : fare.PTC === "CHD"
                                                  ? flightSearchDetails.CHD
                                                  : flightSearchDetails.INF}{" "}
                                                X ₹ {fare.Fare})
                                              </div>
                                              <span
                                                style={{ marginLeft: "auto" }}
                                              >
                                                ₹{" "}
                                                {fare.PTC === "ADT"
                                                  ? flightSearchDetails.ADT *
                                                    fare.Fare
                                                  : fare.PTC === "CHD"
                                                  ? flightSearchDetails.CHD *
                                                    fare.Fare
                                                  : flightSearchDetails.INF *
                                                    fare.Fare}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                  <hr className="mb-0" />
                                  <div
                                    className="block block_right"
                                    style={{
                                      paddingRight: "15px",
                                      paddingLeft: "15px",
                                    }}
                                  >
                                    <ul>
                                      <li className="py-1">
                                        <div
                                          className="form-check px-0"
                                          onClick={() =>
                                            setToggleFare(
                                              toggleFare === "tax" ? "" : "tax"
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="d-flex">
                                            <span className="box-heading">
                                              {" "}
                                              {toggleFare === "tax" ? (
                                                <LazyImage
                                                  className="image_arrow active"
                                                  width={18}
                                                  height={18}
                                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                  alt="collapse-arrow"
                                                />
                                              ) : (
                                                <LazyImage
                                                  className="image_arrow"
                                                  id="image_first"
                                                  width={18}
                                                  height={18}
                                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                  alt="collapse-arrow"
                                                />
                                              )}
                                              &nbsp;Tax & Charges{" "}
                                            </span>
                                          </div>
                                        </div>
                                        <span className="box-heading">
                                          ₹ {gettotalTaxAmountRoundTrip}
                                          {/* {Number(
                                            taxFareRoundTrip
                                          )?.toLocaleString("en-IN")} */}
                                        </span>
                                      </li>
                                      {toggleFare === "tax" && (
                                        <>
                                          {/* {Object.keys(taxTotalsRound)?.map(
                                            (taxType: any) => (
                                              <>
                                                {taxTotalsRound[taxType] >
                                                  0 && (
                                                    <li
                                                      className="py-1"
                                                      key={taxType}
                                                    >
                                                      <div className="form-check px-0">
                                                        <div className="d-flex">
                                                          {taxType}
                                                        </div>
                                                      </div>
                                                      <span className="">
                                                        ₹{" "}
                                                        {taxTotalsRound[taxType]}
                                                      </span>
                                                    </li>
                                                  )}
                                              </>
                                            )
                                          )} */}
                                        </>
                                      )}
                                    </ul>
                                  </div>

                                  <hr className="mb-0" />
                                  {discountRateRound > 0 && (
                                    <div
                                      className="block block_right"
                                      style={{
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <ul>
                                        <li className="py-1">
                                          <div
                                            className="form-check px-0"
                                            onClick={() =>
                                              setToggleFare(
                                                toggleFare === "discount"
                                                  ? ""
                                                  : "discount"
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div className="d-flex">
                                              <span className="box-heading">
                                                {toggleFare === "discount" ? (
                                                  <LazyImage
                                                    className="image_arrow active"
                                                    width={18}
                                                    height={18}
                                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                    alt="collapse-arrow"
                                                  />
                                                ) : (
                                                  <LazyImage
                                                    className="image_arrow"
                                                    id="image_first"
                                                    width={18}
                                                    height={18}
                                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                    alt="collapse-arrow"
                                                  />
                                                )}{" "}
                                                &nbsp;Discount{" "}
                                              </span>
                                            </div>
                                          </div>
                                          <span className="box-heading">
                                            ₹ {discountRateRoundTrip}
                                            {/* {Number(
                                              discountRateRound
                                            )?.toLocaleString("en-IN")} */}
                                          </span>
                                        </li>
                                        {toggleFare === "discount" && (
                                          <>
                                            <li className="py-1">
                                              <div className="form-check px-0">
                                                <div className="d-flex">
                                                  <span className="box-heading">
                                                    Discount
                                                  </span>
                                                </div>
                                              </div>
                                              <span className="">
                                                ₹ {discountRateRoundTrip}
                                                {/* {Number(
                                                  discountRateRound
                                                )?.toLocaleString("en-IN")} */}
                                              </span>
                                            </li>
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                  <hr className="mb-0" />
                                  {bookingDetails.SSR &&
                                    bookingDetails.SSR.length > 0 && (
                                      <>
                                        {Array.from(
                                          new Set(
                                            bookingDetails.SSR.map(
                                              (ssr: any) => ssr.Type
                                            )
                                          )
                                        ).map((type: any, index) => (
                                          <div
                                            key={index}
                                            className="block block_right"
                                            style={{ paddingRight: "15px" }}
                                          >
                                            {calculateTotalChargeByType(type) >
                                              0 && (
                                              <ul>
                                                <li className="py-2 px-2">
                                                  <div
                                                    className="form-check px-0"
                                                    onClick={() =>
                                                      setShowHideBase(
                                                        showHideBase === type
                                                          ? ""
                                                          : type
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <div className="d-flex">
                                                      <span className="box-heading">
                                                        {" "}
                                                        {showHideBase ===
                                                        type ? (
                                                          <LazyImage
                                                            className="image_arrow active"
                                                            width={18}
                                                            height={18}
                                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                            alt="collapse-arrow"
                                                          />
                                                        ) : (
                                                          <LazyImage
                                                            className="image_arrow"
                                                            id="image_first"
                                                            width={18}
                                                            height={18}
                                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                            alt="collapse-arrow"
                                                          />
                                                        )}
                                                        &nbsp;
                                                        {getTypeName(type)}
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <span className="box-heading">
                                                    ₹{" "}
                                                    {calculateTotalChargeByType(
                                                      type
                                                    )}
                                                  </span>
                                                </li>
                                                {showHideBase === type && (
                                                  <>
                                                    <li className="py-1 listing_item px-3">
                                                      <div className="form-check px-0">
                                                        <div className="d-flex">
                                                          {getTypeName(type)}
                                                        </div>
                                                      </div>
                                                      <span className="">
                                                        ₹{" "}
                                                        {calculateTotalChargeByType(
                                                          type
                                                        )}
                                                      </span>
                                                    </li>
                                                  </>
                                                )}
                                                <hr className="mb-0" />
                                              </ul>
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  {seatValues !== null &&
                                    Number(seatValues) !== 0 && (
                                      <>
                                        <div
                                          className="block block_right"
                                          style={{ paddingRight: "15px" }}
                                        >
                                          <ul>
                                            <li className="py-1 px-2">
                                              <div
                                                className="form-check px-0"
                                                onClick={() =>
                                                  setShowHideBase(
                                                    showHideBase ===
                                                      "showSeatAmount"
                                                      ? ""
                                                      : "showSeatAmount"
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                <div className="d-flex">
                                                  <span className="box-heading">
                                                    {" "}
                                                    {showHideBase ===
                                                    "showSeatAmount" ? (
                                                      <LazyImage
                                                        className="image_arrow active"
                                                        width={18}
                                                        height={18}
                                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                        alt="collapse-arrow"
                                                      />
                                                    ) : (
                                                      <LazyImage
                                                        className="image_arrow"
                                                        id="image_first"
                                                        width={18}
                                                        height={18}
                                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                        alt="collapse-arrow"
                                                      />
                                                    )}
                                                    &nbsp;Seats
                                                  </span>
                                                </div>
                                              </div>
                                              <span className="box-heading">
                                                ₹ {seatValues}
                                              </span>
                                            </li>
                                            {showHideBase ===
                                              "showSeatAmount" && (
                                              <>
                                                <li className="py-1 listing_item px-3">
                                                  <div className="form-check px-0">
                                                    <div className="d-flex">
                                                      Seats
                                                    </div>
                                                  </div>
                                                  <span className="">
                                                    {/* ₹ {SeatAmount?.toLocaleString("en-IN")} */}
                                                    ₹ {seatValues}
                                                  </span>
                                                </li>
                                              </>
                                            )}
                                          </ul>
                                        </div>
                                        <hr />
                                      </>
                                    )}
                                  <div
                                    className="block"
                                    style={{
                                      paddingRight: "15px",
                                      paddingLeft: "15px",
                                    }}
                                  >
                                    <ul>
                                      <li className="py-1">
                                        <div className="form-check px-0">
                                          <div className="d-flex">
                                            <span className="box-heading">
                                              Total Amount:{" "}
                                            </span>
                                          </div>
                                        </div>
                                        <span className="box-heading">
                                          ₹{" "}
                                          {getPaymentStatus?.paymentResponse &&
                                            getPaymentStatus?.paymentResponse
                                              ?.net_amount_debit}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="confirm-box">
                            <h3
                              className="h3-bold"
                              id="multi city Fare Summary"
                            >
                              Fare Summary
                            </h3>

                            <div className="block block_right">
                              <ul>
                                <li className="py-1">
                                  <div
                                    className="form-check px-0"
                                    onClick={() =>
                                      setToggleFare(
                                        toggleFare === "base" ? "" : "base"
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="d-flex">
                                      <span className="hading_check">
                                        {" "}
                                        {toggleFare === "base" ? (
                                          <LazyImage
                                            className="image_arrow active"
                                            width={18}
                                            height={18}
                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                            alt="collapse-arrow"
                                          />
                                        ) : (
                                          <LazyImage
                                            className="image_arrow"
                                            id="image_first"
                                            width={18}
                                            height={18}
                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                            alt="collapse-arrow"
                                          />
                                        )}
                                        &nbsp;Base Fare{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <span className="hading_check">
                                    ₹
                                    {Number(multiTotalBaseFare)?.toLocaleString(
                                      "en-IN"
                                    )}
                                  </span>
                                </li>
                                <li
                                  className="py-1"
                                  style={{
                                    display:
                                      toggleFare === "base" ? "block" : "none",
                                  }}
                                >
                                  {combinedArrayMultiCity.map(
                                    (fare: any, index: number) => (
                                      <div
                                        className="form-check px-0"
                                        key={`sidebar-round-checkout-${index}`}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{ marginRight: "auto" }}
                                        >
                                          {fare.PTC === "ADT"
                                            ? "Adult"
                                            : fare.PTC === "CHD"
                                            ? "Child"
                                            : "Infant"}{" "}
                                          (
                                          {fare.PTC === "ADT"
                                            ? flightSearchDetails.ADT
                                            : fare.PTC === "CHD"
                                            ? flightSearchDetails.CHD
                                            : flightSearchDetails.INF}{" "}
                                          X ₹ {fare.Fare})
                                        </div>
                                        <span style={{ marginLeft: "auto" }}>
                                          ₹{" "}
                                          {fare.PTC === "ADT"
                                            ? flightSearchDetails.ADT *
                                              fare.Fare
                                            : fare.PTC === "CHD"
                                            ? flightSearchDetails.CHD *
                                              fare.Fare
                                            : flightSearchDetails.INF *
                                              fare.Fare}
                                        </span>
                                      </div>
                                    )
                                  )}
                                </li>
                              </ul>
                            </div>
                            <hr />
                            <div className="block block_right">
                              <ul>
                                <li className="py-1">
                                  <div
                                    className="form-check px-0"
                                    onClick={() =>
                                      setToggleFare(
                                        toggleFare === "tax" ? "" : "tax"
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="d-flex">
                                      <span className="box-heading">
                                        {" "}
                                        {toggleFare === "tax" ? (
                                          <LazyImage
                                            className="image_arrow active"
                                            width={18}
                                            height={18}
                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                            alt="collapse-arrow"
                                          />
                                        ) : (
                                          <LazyImage
                                            className="image_arrow"
                                            id="image_first"
                                            width={18}
                                            height={18}
                                            src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                            alt="collapse-arrow"
                                          />
                                        )}
                                        &nbsp;Tax & Charges{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <span className="box-heading">
                                    ₹ {gettotalTaxAmountMultiCity}
                                    {/* {Number(multiTotalTaxAmount).toLocaleString(
                                      "en-IN"
                                    )} */}
                                  </span>
                                </li>

                                {toggleFare === "tax" && (
                                  <>
                                    {/* {Object.keys(multiTaxTotals)?.map(
                                      (taxType: any) => (
                                        <>
                                          {multiTaxTotals[taxType] > 0 && (
                                            <li className="py-1" key={taxType}>
                                              <div className="form-check px-0">
                                                <div className="d-flex">
                                                  {taxType}
                                                </div>
                                              </div>
                                              <span className="">
                                                ₹ {multiTaxTotals[taxType]}
                                              </span>
                                            </li>
                                          )}
                                        </>
                                      )
                                    )} */}
                                  </>
                                )}
                              </ul>
                            </div>

                            {discountRateMulti > 0 && (
                              <>
                                <hr />

                                <div className="block block_right">
                                  <ul>
                                    <li className="py-1">
                                      <div
                                        className="form-check px-0"
                                        onClick={() =>
                                          setToggleFare(
                                            toggleFare === "discount"
                                              ? ""
                                              : "discount"
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div className="d-flex">
                                          <span className="box-heading">
                                            {" "}
                                            {toggleFare === "discount" ? (
                                              <LazyImage
                                                className="image_arrow active"
                                                width={18}
                                                height={18}
                                                src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                alt="collapse-arrow"
                                              />
                                            ) : (
                                              <LazyImage
                                                className="image_arrow"
                                                id="image_first"
                                                width={18}
                                                height={18}
                                                src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                alt="collapse-arrow"
                                              />
                                            )}
                                            &nbsp;Discount{" "}
                                          </span>
                                        </div>
                                      </div>
                                      <span className="box-heading">
                                        ₹ {getDiscountCalcMulti}
                                        {/* {Number(
                                          discountRateMulti
                                        ).toLocaleString("en-IN")} */}
                                      </span>
                                    </li>

                                    {toggleFare === "discount" && (
                                      <>
                                        <li className="py-1">
                                          <div className="form-check px-0">
                                            <div className="d-flex">
                                              Discount
                                            </div>
                                          </div>
                                          <span className="">
                                            ₹ {getDiscountCalcMulti}
                                            {/* {discountRateMulti} */}
                                          </span>
                                        </li>
                                      </>
                                    )}
                                  </ul>
                                </div>
                              </>
                            )}
                            <hr className="mb-0" />
                            {bookingDetails.SSR &&
                              bookingDetails.SSR.length > 0 && (
                                <>
                                  {Array.from(
                                    new Set(
                                      bookingDetails.SSR.map(
                                        (ssr: any) => ssr.Type
                                      )
                                    )
                                  ).map((type: any, index) => (
                                    <div
                                      key={index}
                                      className="block block_right"
                                      style={{ paddingRight: "15px" }}
                                    >
                                      {calculateTotalChargeByType(type) > 0 && (
                                        <ul>
                                          <li className="py-2 px-2">
                                            <div
                                              className="form-check px-0"
                                              onClick={() =>
                                                setShowHideBase(
                                                  showHideBase === type
                                                    ? ""
                                                    : type
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div className="d-flex">
                                                <span className="box-heading">
                                                  {" "}
                                                  {showHideBase === type ? (
                                                    <LazyImage
                                                      className="image_arrow active"
                                                      width={18}
                                                      height={18}
                                                      src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                      alt="collapse-arrow"
                                                    />
                                                  ) : (
                                                    <LazyImage
                                                      className="image_arrow"
                                                      id="image_first"
                                                      width={18}
                                                      height={18}
                                                      src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                      alt="collapse-arrow"
                                                    />
                                                  )}
                                                  &nbsp;{getTypeName(type)}
                                                </span>
                                              </div>
                                            </div>

                                            <span className="box-heading">
                                              ₹{" "}
                                              {calculateTotalChargeByType(type)}
                                            </span>
                                          </li>
                                          {showHideBase === type && (
                                            <>
                                              <li className="py-1 listing_item px-3">
                                                <div className="form-check px-0">
                                                  <div className="d-flex">
                                                    {getTypeName(type)}
                                                  </div>
                                                </div>
                                                <span className="">
                                                  ₹{" "}
                                                  {calculateTotalChargeByType(
                                                    type
                                                  )}
                                                </span>
                                              </li>
                                            </>
                                          )}
                                          <hr className="mb-0" />
                                        </ul>
                                      )}
                                    </div>
                                  ))}
                                </>
                              )}
                            {seatValues !== null &&
                              Number(seatValues) !== 0 && (
                                <>
                                  <div
                                    className="block block_right"
                                    style={{ paddingRight: "15px" }}
                                  >
                                    <ul>
                                      <li className="py-1">
                                        <div
                                          className="form-check px-0"
                                          onClick={() =>
                                            setShowHideBase(
                                              showHideBase === "showSeatAmount"
                                                ? ""
                                                : "showSeatAmount"
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="d-flex">
                                            <span className="box-heading">
                                              {" "}
                                              {showHideBase ===
                                              "showSeatAmount" ? (
                                                <LazyImage
                                                  className="image_arrow active"
                                                  width={18}
                                                  height={18}
                                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                  alt="collapse-arrow"
                                                />
                                              ) : (
                                                <LazyImage
                                                  className="image_arrow"
                                                  id="image_first"
                                                  width={18}
                                                  height={18}
                                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                                  alt="collapse-arrow"
                                                />
                                              )}
                                              &nbsp;Seats
                                            </span>
                                          </div>
                                        </div>
                                        <span className="box-heading">
                                          ₹ {seatValues}
                                        </span>
                                      </li>
                                      {showHideBase === "showSeatAmount" && (
                                        <>
                                          <li className="py-1 listing_item px-3">
                                            <div className="form-check px-0">
                                              <div className="d-flex">
                                                Seats
                                              </div>
                                            </div>
                                            <span className="">
                                              {/* ₹ {SeatAmount?.toLocaleString("en-IN")} */}
                                              ₹ {seatValues}
                                            </span>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </div>
                                  <hr />
                                </>
                              )}
                            <div className="block">
                              <ul>
                                <li className="py-1">
                                  <div className="form-check px-0">
                                    <div className="d-flex">
                                      <span className="hading_check">
                                        Total Amount:{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <span className="hading_check">
                                    ₹{" "}
                                    {getPaymentStatus?.paymentResponse &&
                                      getPaymentStatus?.paymentResponse
                                        ?.net_amount_debit}
                                    {/* {totalFareMultiCityFinal} */}
                                    {/* {totalFareMultiCity?.toLocaleString(
                                      "en-IN"
                                    )} */}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* contact information section */}
                  <div className="row">
                    <div className="col-md-12 m-auto res-padding">
                      <div className="card card-contact mt-2">
                        <div className="card-body">
                          <h3 className="text-table">Primary Contact</h3>
                          <p className="box-heading">
                            We will contact you on this number. You can email or
                            WhatsApp the ticket to other contacts.
                          </p>
                          <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                            <div className="contact-block">
                              <p className="box-heading mb-0">
                                <span>
                                  <LazyImage src="" alt="" />
                                </span>{" "}
                                {bookingDetails?.Pax[0].FName +
                                  " " +
                                  bookingDetails?.Pax[0].LName}
                              </p>
                            </div>
                            <div className="contact-block">
                              <p className="box-heading mb-0">
                                <span>
                                  <LazyImage src="" alt="" />
                                </span>{" "}
                                {bookingDetails?.ContactInfo[0].Email}
                              </p>
                            </div>
                            <div className="contact-block">
                              <p className="box-heading mb-0">
                                <span>
                                  <LazyImage src="" alt="" />
                                </span>{" "}
                                {bookingDetails?.ContactInfo[0]
                                  .MobileCountryCode +
                                  " " +
                                  bookingDetails?.ContactInfo[0].Mobile}
                              </p>
                            </div>
                            {/* <div className="contact-block">
                              <button className="btn btn-login">
                                Resend Voucher
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AirplaneLoader />
          )}
        </>{" "}
        {getCurrentSelectedTab === "hotelFlight" && (
          <div className="container">
            <div className="d-flex justify-content-between">
              <div
                onClick={() => window.location.replace("/search-hotels")}
                style={{
                  cursor: "pointer",
                  border: "1px solid red",
                  fontSize: "larger",
                  position: "sticky",
                  bottom: "0",
                  width: "35%",
                  left: "0",
                  zIndex: "99",
                  backgroundColor: "white",
                  overflow: "auto",
                }}
              >
                <h5
                  style={{
                    padding: "6px 17px",
                    textAlign: "center",
                    fontSize: "large",
                    marginTop: "7px",
                    fontWeight: "600",
                  }}
                >
                  Click here to continue with Hotel Search{" "}
                </h5>
              </div>
            </div>
          </div>
        )}
        {getCurrentSelectedTab === "hotel" && (
          <div className="container">
            <div className="d-flex justify-content-between">
              <div
                onClick={() => {
                  localStorage.setItem("tabSearchData", "flight");
                  window.location.replace("/");
                }}
                style={{
                  cursor: "pointer",
                  border: "1px solid red",
                  fontSize: "larger",
                  position: "sticky",
                  bottom: "0",
                  width: "35%",
                  left: "0",
                  zIndex: "99",
                  backgroundColor: "white",
                  overflow: "auto",
                }}
              >
                <h5
                  style={{
                    padding: "6px 17px",
                    textAlign: "center",
                    fontSize: "large",
                    marginTop: "7px",
                    fontWeight: "600",
                  }}
                >
                  Click here to continue with Flight Search{" "}
                </h5>
              </div>
            </div>
          </div>
        )}
        {getCurrentSelectedTab === "flight" && (
          <div className="container">
            <div className="d-flex justify-content-between">
              <div
                onClick={() => {
                  localStorage.setItem("tabSearchData", "hotel");
                  window.location.replace("/");
                }}
                style={{
                  cursor: "pointer",
                  border: "1px solid red",
                  fontSize: "larger",
                  position: "sticky",
                  bottom: "0",
                  width: "35%",
                  left: "0",
                  zIndex: "99",
                  backgroundColor: "white",
                  overflow: "auto",
                }}
              >
                <h5
                  style={{
                    padding: "6px 17px",
                    textAlign: "center",
                    fontSize: "large",
                    marginTop: "7px",
                    fontWeight: "600",
                  }}
                >
                  Click here to continue with Hotel Search{" "}
                </h5>
              </div>
            </div>
          </div>
        )}
      </main>
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
